import { BaseTimelineChartModel } from './_base_timeline_chart_model';

export class AreaChartModel extends BaseTimelineChartModel {
  getAreaHeightPerPeriod () {
    if (typeof this.areaHeightPerPeriod === 'undefined') {
      const result = this.rawData.map(() => []);

      this.getBlockDomain().forEach(dataPerPeriod => {
        let sumPerPeriod = 0;

        dataPerPeriod.data.forEach((entry, areaIndex) => {
          sumPerPeriod += entry.value;
          result[areaIndex].push(sumPerPeriod);
        });
      });

      this.areaHeightPerPeriod = result.map(line => [line[0], ...line, line[line.length - 1]]);
    }

    return this.areaHeightPerPeriod;
  }

  _getMaxYValue () {
    return Math.max(...[].concat(...this.getAreaHeightPerPeriod()));
  }
}
