import { SspConfiguration } from '../types'

import DistrictMLogo from 'images/district_m_logo.png'
import DistrictMSmallLogo from 'images/district_m_small_logo.png'

const districtMConfig: SspConfiguration = {
  platform: 'district_m',
  platformName: 'District M',
  logo: DistrictMLogo,
  smallLogo: DistrictMSmallLogo,
}

export default districtMConfig
