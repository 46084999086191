import React from 'react'
import Select from 'react-select'

import selectStyles from './styles'

const CustomSelect: React.FC<any> = props => {
  return (
    <Select
      id={props.id}
      options={props.options}
      components={{
        IndicatorSeparator: () => null,
      }}
      defaultValue={props.defaultValue}
      value={props.value || undefined}
      styles={selectStyles}
      isSearchable={props.isSearchable}
      onChange={props.onChange}
      placeholder={props.placeholder}
    />
  )
}

CustomSelect.defaultProps = {
  isSearchable: false,
}

export default CustomSelect
