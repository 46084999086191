import '../../utlis/select2.customSelectionAdapter'

export class MultipleSelect {
  constructor(elementSelector, userConfig) {
    this.element = elementSelector
    this.defaultConfig = {
      dropdownParent: '',
      withCustomSelectionAdapter: false,
      theme: '',
      dropdownCssClass: 'url_dropdown',
      closeOnSelect: false,
    }
    this.config = this._prepareConfig(userConfig)
  }

  create() {
    const element = $(this.element)
    const endpoint = element.data('endpoint')
    const selectOptions = this._getBaseSelectOptions(element)

    if (endpoint) {
      selectOptions.ajax = this._getAjaxOptions(endpoint)
    } else {
      selectOptions.data = this._getOptionsWithExcludedSelectedIds(element)
    }

    element.select2(selectOptions)
    this._setInitialValues(element, endpoint)
  }

  _setInitialValues(element, endpoint) {
    const selectedIds = element.data('selected') || []

    if (selectedIds.length === 0) {
      return
    }

    if (endpoint && !element.data('skipInitialAjax')) {
      this._fetchOptionsAndAppendValues(selectedIds, endpoint)
    } else {
      this._appendValues(selectedIds, element.data('options'))
    }

    $(this.element).trigger('change')
  }

  _fetchOptionsAndAppendValues(selectedIds, endpoint) {
    const data = { source_ssp: this._isSourceSsp(this.element) }

    $.ajax({ type: 'GET', url: endpoint, data: data }).then((data) => this._appendValues(selectedIds, data.results))
  }

  _appendValues(selectedIds, options = undefined) {
    selectedIds.forEach((id) => {
      const text = options ? this._getTextValueForId(options, id) : id

      $(this.element).append(new Option(text, id, true, true))
    })
  }

  _getBaseSelectOptions(element) {
    const { dropdownParent, theme, dropdownCssClass, closeOnSelect } = this.config

    return {
      placeholder: element.data('placeholder') || '---',
      multiple: true,
      allowClear: true,
      minimumInputLength: element.data('minimumInputLength'),
      theme: 'default ' + theme, // https://stackoverflow.com/a/52861682/7268884
      dropdownCssClass,
      dropdownParent: dropdownParent ? $(dropdownParent) : $(document.body),
      dropdownPosition: 'below',
      selectionAdapter: this._getSelectionAdapter(),
      closeOnSelect,
    }
  }

  _getSelectionAdapter() {
    return this.config.withCustomSelectionAdapter
      ? $.fn.select2.amd.require('select2/selection/customSelectionAdapter')
      : $.fn.select2.amd.require('select2/selection/multiple')
  }

  _getAjaxOptions(endpoint) {
    const isSsp = this._isSourceSsp(this.element)
    return {
      url: endpoint,
      dataType: 'json',
      delay: 250,
      data: function (params) {
        return { q: params.term, source_ssp: isSsp }
      },
    }
  }

  _getOptionsWithExcludedSelectedIds(element) {
    return $.grep(element.data('options'), (e) => $.inArray(e.id, element.data('selected')) < 0)
  }

  _getTextValueForId(options, id) {
    return options.find((option) => option.id.toString() === id.toString()).text
  }

  _isSourceSsp(element) {
    return $(element).closest('.simple_form').find('#research_form_source_ssp').prop('checked')
  }

  _prepareConfig = (userConfig) => Object.assign({}, this.defaultConfig, userConfig)
}
