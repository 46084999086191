import { Styles } from 'react-select/src/styles'

import container from './container'
import control from './control'
import input from './input'
import loadingMessage from './loadingMessage'
import multiValue from './multiValue'
import multiValueLabel from './multiValueLabel'
import multiValueRemove from './multiValueRemove'
import noOptionsMessage from './noOptionsMessage'
import option from './option'
import placeholder from './placeholder'

const styles: Styles = {
  container,
  control,
  input,
  loadingMessage,
  multiValue,
  multiValueLabel,
  multiValueRemove,
  noOptionsMessage,
  option,
  placeholder,
}

export default styles
