import { ResearchBaseRemoteHandler } from './_research_base_remote_handler'
import { InputValuePresenceValidator } from '../input_value_presence_validator'
import { ToastNotifierHelper, NotificationType } from 'javascripts/utlis/toastNotifierHelper'

export class ResearchSaveReportModalHandler extends ResearchBaseRemoteHandler {
  constructor(containerSelector, savedReportMessage) {
    super(containerSelector)

    this.savedReportMessage = savedReportMessage
    this.saveReportModal = '#save-report-modal'
    this.reportName = 'input#report_name'
    this.reportNameError = '.report-name-error'
    this.researchHeaderName = '.research-header .name'
    this.saveCancel = '.research-save-cancel'
    this.saveAsNewReport = '.research-save-as-new-report'
    this.save = '.research-save'
  }

  create() {
    this._initializeInputValuePresenceValidator()
    this._setSaveReportModalShowEvent()
    this._setSaveCancelClickEvent()
    this._setModalSaveClicksEvent()
  }

  _initializeInputValuePresenceValidator() {
    this.inputValuePresenceValidator = new InputValuePresenceValidator(
      this.reportName,
      this.reportNameError
    )
  }

  _setSaveReportModalShowEvent() {
    $(this.saveReportModal).on('show.bs.modal', () => this._prepareSaveReportModal())
  }

  _setSaveCancelClickEvent() {
    $(this.saveCancel).on('click', () => $(document).trigger('reportSavingCancelled'))
  }

  _setModalSaveClicksEvent() {
    $(`${this.saveAsNewReport}, ${this.save}`).on('click', e => {
      e.preventDefault()

      const saveAsNewReport =
        e.target.className.match(this.saveAsNewReport.replace('.', '')) !== null
      if (this._isReportNameValid()) {
        this._saveReport(saveAsNewReport)
      }
    })
  }

  _prepareSaveReportModal() {
    this._setInputValueToCurrentReportName()
    this.inputValuePresenceValidator.clearErrorField()

    if (this._hasCurrentReportBeenSaved()) {
      this._showButtonsForAlternativeSave()
    }
  }

  _setInputValueToCurrentReportName() {
    $(this.reportName).val($(this.researchHeaderName).html())
  }

  _hasCurrentReportBeenSaved() {
    return this._getSavedReportId() !== undefined
  }

  _getSavedReportId() {
    return $(this.container).data('research-report-id')
  }

  _showButtonsForAlternativeSave() {
    $(this.saveAsNewReport).show()
    $(this.save).html($(this.save).data('overwrite-label'))
  }

  _isReportNameValid() {
    return this.inputValuePresenceValidator.validate()
  }

  _saveReport(saveAsNewReport) {
    if (saveAsNewReport) {
      $(this.container).data('research-report-id', null)
    }

    $(this.saveReportModal).modal('hide')
    this._performSaveReportRequest()
  }

  _performSaveReportRequest() {
    $.ajax(this._getAjaxOptions(this._getAjaxData())).done(data => {
      this._storeSavedReportData(data)
      this._createSavedReportMessage()
      this._setCurrentReportNameToSavedReportName()
      this._triggerReportSavedEvent()
    })
  }

  _getAjaxData() {
    return {
      report_name: $(this.reportName).val(),
      form_queries: this._getFormQueries(),
      research_report_id: this._getSavedReportId(),
    }
  }

  _storeSavedReportData(data) {
    history.pushState(null, null, data.url)
    $(this.container).data('research-report-id', data.research_report_id)
  }

  _createSavedReportMessage() {
    ToastNotifierHelper.inject(NotificationType.SUCCESS, this.savedReportMessage)
  }

  _setCurrentReportNameToSavedReportName() {
    $(this.researchHeaderName).html($(this.reportName).val())
  }

  _triggerReportSavedEvent() {
    $(document).trigger('reportSaved')
  }

  _getEndpoint() {
    return $(this.save).data('endpoint')
  }
}
