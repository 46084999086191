export class TopListChart {
  constructor (container) {
    this.container = container;
    this.topListItemLabel = '.top-list-item-label';
  }

  run () {
    this._setBeforeChangeListener();
    this._setAfterChangeListener();
    this._initializeTooltips();
  }

  _setBeforeChangeListener () {
    $(this.container).on('data:beforeChange', () => { this._hideTooltips(); });
  }

  _setAfterChangeListener () {
    $(this.container).on('data:changed', () => { this._initializeTooltips(); });
  }

  _initializeTooltips () {
    $(this._getTooltipSelector()).tooltip({ placement: 'top' });
  }

  _hideTooltips () {
    $(this._getTooltipSelector()).tooltip('hide');
  }

  _getTooltipSelector () {
    return [this.container, this.topListItemLabel].join(' ');
  }
}
