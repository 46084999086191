import React from 'react';

import { ConnectedSsp, SspTypes } from 'javascripts/components/Ssps';

const ConnectedSspsContainer: React.FC<SspTypes.ConnectedSspContainerProps> = ({ connectedSsps }) => {
  return (
    <div className="connected-ssps__container">
      { connectedSsps.map(ssp => <ConnectedSsp key={ssp.id} ssp={ssp} />) }
    </div>
  )
};

export default ConnectedSspsContainer;
