import React, { useState, useRef } from 'react';

import DropdownItem from './DropdownItem';
import DropdownToggle from './DropdownToggle';
import DropdownMenu from './DropdownMenu';
import { DropdownProps, DropdownComposition, ToggleEventType } from './types';

const Dropdown: React.FC<DropdownProps> & DropdownComposition = ({ title, children }) => {

  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOutsideClick = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !isOpen) {
      document.removeEventListener('click', handleOutsideClick, false);
      setIsOpen(false)
    }
  }

  const toggleDropdown = (event: ToggleEventType) => {
    event.preventDefault();
    if (!isOpen) {
      document.addEventListener('click', handleOutsideClick, false);
    }

    setIsOpen(!isOpen)
  }

  return (
    <div className="dropdown" ref={wrapperRef}>
      <DropdownToggle
        onClickHandler={toggleDropdown}
      >
        { title }
      </DropdownToggle>
      <DropdownMenu
        role="menu"
        isOpen={isOpen}
      >
        { children }
      </DropdownMenu>
    </div>
  )
}

Dropdown.Item = DropdownItem;

export default Dropdown;
