import React, { useContext } from 'react'
import CurrentProfileContext, { CurrentProfile } from 'javascripts/components/CurrentProfile'
import { useMultiSelect } from 'javascripts/components/forms/Select'
import Button, { ButtonTheme } from 'javascripts/components/Button'
import { TabViewProps } from 'javascripts/components/Publisher/types'
import InventoryForm from 'javascripts/components/Publisher/InventoryForm'
import * as PublisherService from 'javascripts/services/PublisherService'
import { useToastNotifier, NotificationType } from 'javascripts/components/ToastNotifier'
import { AxiosError } from 'axios'
import { useSimpleLoader } from 'javascripts/components/SimpleLoader'
import { convertItemsToSelectOption, convertSelectOptionToItems } from '../publisherUtils'

const LOADER_TIMEOUT = 500

const InventoryContainer: React.FC<TabViewProps> = ({ publisher, onGetPublisher }) => {
  const currentProfileCtx = useContext<Partial<CurrentProfile>>(CurrentProfileContext)
  const { isLoading, setIsLoading, SimpleLoader } = useSimpleLoader(false)

  const { Notifier, createNotifier } = useToastNotifier()
  const publisherEndspoint = `/panel/${currentProfileCtx.id}/publishers`

  const [adUnits, addAdUnit, removeAdUnit] = useMultiSelect(convertItemsToSelectOption(publisher.adUnits))
  const [websites, addWebsites, removeWebsites] = useMultiSelect(convertItemsToSelectOption(publisher.websites))

  const save = () => {
    setIsLoading(true)
    const newAdUnits = convertSelectOptionToItems(adUnits)
    const newWebsites = convertSelectOptionToItems(websites)
    PublisherService.updateInventory(newAdUnits, newWebsites)
      .then(() => onGetPublisher(publisher.id))
      .then(() => createNotifier('Publisher inventory updated!', NotificationType.SUCCESS))
      .catch(updateAdUnitsErrorHandler)
      .finally(() => setIsLoading(false, LOADER_TIMEOUT))
  }

  const updateAdUnitsErrorHandler = (error: AxiosError) =>
    createNotifier(createErrorMessage(error.response.data.errors), NotificationType.ERROR)

  const createErrorMessage = (errors: any) => {
    return Object.entries(errors)
      .map(([key, value]) => `${key} ${value}`)
      .join('\n')
  }

  return (
    <div className='tab-view'>
      <h3>Choose publisher's inventory</h3>
      {isLoading
        ? (
          <SimpleLoader />
        ) : (
          <>
            <InventoryForm
              id='ad_units'
              placeholder='Add Ad Unit'
              items={adUnits}
              endpoint={`/panel/${currentProfileCtx.id}/publisher_ad_units/search`}
              addItem={addAdUnit}
              removeItem={removeAdUnit}
            />

            <InventoryForm
              id='websites'
              placeholder='Add Websites'
              items={websites}
              endpoint={`/panel/${currentProfileCtx.id}/ssp_sites/search`}
              addItem={addWebsites}
              removeItem={removeWebsites}
            />

            <footer className='tab-view__footer'>
              <Button theme={ButtonTheme.GreenOutline} href={publisherEndspoint}>
                Back
              </Button>
              <Button theme={ButtonTheme.Green} onClickHandler={save} disabled={isLoading}>
                Save
              </Button>
            </footer>
          </>
        )}
      {Notifier}
    </div>
  )
}

export default InventoryContainer
