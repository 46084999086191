import { SspConfiguration } from '../types'

import AppNexusLogo from 'images/appnexus_logo.png'
import AppNexusSmallLogo from 'images/appnexus_small_logo.png'

const appnexusConfig: SspConfiguration = {
  platform: 'appnexus',
  platformName: 'Appnexus',
  logo: AppNexusLogo,
  smallLogo: AppNexusSmallLogo,
}

export default appnexusConfig
