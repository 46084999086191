export class InputValuePresenceValidator {
  constructor (inputSelector, errorFieldSelector) {
    this.input = inputSelector;
    this.errorField = errorFieldSelector;
  }

  validate () {
    if (this._isInputValuePresent()) {
      this.clearErrorField();
      return true;
    } else {
      this._setValidationError();
      return false;
    }
  }

  clearErrorField () {
    $(this.errorField).html('');
  }

  _isInputValuePresent () {
    return $(this.input).val().trim().length > 0;
  }

  _setValidationError () {
    $(this.errorField).html(InputValuePresenceValidator.ERROR_MESSAGE);
  }
}

InputValuePresenceValidator.ERROR_MESSAGE = "Can't be blank";
