import React, { useState, useEffect } from 'react'

import { UserListItemProps, User, UserRoles } from './types'
import Avatar from '../Avatar'
import Select from '../forms/Select'

const UserListItem: React.FC<UserListItemProps> = ({ user, isOwner }) => {
  const [isUserOwner, setIsUserOwner] = useState(false)

  const displayRole = (user: User) => {
    isOwner(user).then(_hasOwnerRole => setIsUserOwner(false))
  }

  useEffect(() => {
    displayRole(user)
  }, [user])

  return (
    <div className='user-item'>
      <Avatar />
      <p className='user-item__name'>{user.name}</p>
      <p className='user-item__email'>{user.email}</p>

      {isUserOwner && (
        <div>
          <Select
            id='user-role-select'
            options={UserRoles}
            value={{ value: 'owner', label: 'Owner' }}
            defaultValue={{ value: 'owner', label: 'Owner' }}
          />
        </div>
      )}
    </div>
  )
}

export default UserListItem
