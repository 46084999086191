import { ResearchScopedSelector } from './__research_scoped_selector'

export class ResearchFormStateUpdater extends ResearchScopedSelector {
  constructor(uuid, isPublisher, isAllPublishersUser, hasSsp) {
    super(uuid)

    this.isPublisher = isPublisher
    this.isAllPublishersUser = isAllPublishersUser
    this.hasSsp = hasSsp
    this.update()
  }

  initializeDynamicFilter(dynamicFilter) {
    this._switchDynamicFilterToAvailable(dynamicFilter)
    this._updateInitializedDynamicFilter(dynamicFilter)
  }

  update() {
    const demandChannels = 'ad_manager_demand_channels'
    const dimensions = 'dimension'

    this._updateSource()
    this._disabledAll(false)

    switch (this.source) {
      case ResearchFormStateUpdater.SOURCES.SSP: {
        this._updateWithSSP(demandChannels, dimensions)
        break
      }
      case ResearchFormStateUpdater.SOURCES.AD_MANAGER: {
        this._updateWithAdManager(demandChannels, dimensions)
        break
      }
      default:
        this._disabledAll(true)
    }

    this._updateDynamicFilters()
  }

  _updateWithSSP(demandChannelClass, dimensionClass) {
    this._updateDemandChannels(ResearchFormStateUpdater.DEMAND_CHANNELS, false)
    this._updateCheckboxesActiveClass(demandChannelClass)
    this._updateDemandAllButton(demandChannelClass)
    this._updateSelections()

    this._updateCheckboxesActiveClass(dimensionClass)
    if (this.isPublisher) {
      if (this.isAllPublishersUser) {
        this._activateDimensionsFor(ResearchFormStateUpdater.SSP_ALL_PUBLISHERS_USER_DIMENSIONS)
      } else {
        this._activateDimensionsFor(ResearchFormStateUpdater.SSP_PUBLISHER_DIMENSIONS)
      }
    } else {
      this._activateDimensionsFor(ResearchFormStateUpdater.SSP_NETWORK_DIMENSIONS)
    }
  }

  _updateWithAdManager(demandChannelClass, dimensionClass) {
    this._updateDemandChannels(ResearchFormStateUpdater.DEMAND_CHANNELS, true)
    this._updateCheckboxesActiveClass(demandChannelClass)
    this._updateDemandAllButton(demandChannelClass)
    this._updateSelections()

    this._updateDimensions()
    this._updateCheckboxesActiveClass(dimensionClass)

    if (!this.adx_enabled && !this.isSourceSsp) {
      this._disableTooltips(false)
    }
  }

  _switchDynamicFilterToAvailable(dynamicFilter) {
    if (this.source === ResearchFormStateUpdater.SOURCES.SSP) {
      dynamicFilter.val('website').trigger('change')
    } else if (this.areDemandChannelsExceptAdxSelected || !this.adx_enabled) {
      dynamicFilter.val('ad_unit_domain').trigger('change')
    }
  }

  _updateInitializedDynamicFilter(dynamicFilter) {
    this._toggleDynamicFilterState(
      dynamicFilter.closest('.dynamic-filter')[0],
      this._dynamicFiltersDimensionsToBeDisabled().join(',')
    )
  }

  _dynamicFiltersDimensionsToBeDisabled() {
    if (this.source === ResearchFormStateUpdater.SOURCES.SSP) {
      if (this.isPublisher) {
        return ResearchFormStateUpdater.DISABLED_FILTERS_FOR_PUBLISHER_SSP
      } else {
        return ResearchFormStateUpdater.DISABLED_FILTERS_FOR_NETWORK_SSP
      }
    }

    if (!this.adx_enabled) {
      return ResearchFormStateUpdater.DISABLED_FILTERS_FOR_AD_MANAGER_HISTORICAL
    } else if (!this.areDemandChannelsExceptEbdaSelected) {
      return ResearchFormStateUpdater.DISABLED_FILTERS_FOR_EBDA_ONLY
    } else if (this.areDemandChannelsExceptAdxSelected) {
      return ResearchFormStateUpdater.DISABLED_FILTERS_FOR_AD_MANAGER_HISTORICAL
    } else {
      return ResearchFormStateUpdater.DISABLED_FILTERS_FOR_ADX_ONLY
    }
  }

  _updateSelections() {
    this.areDemandChannelsExceptAdxSelected = this._isAnyAdditionalDemandChannel(
      ResearchFormStateUpdater.AD_EXCHANGE_DEMAND_CHANNEL
    )
    this.areDemandChannelsExceptEbdaSelected = this._isAnyAdditionalDemandChannel(
      ResearchFormStateUpdater.EBDA_DEMAND_CHANNEL
    )
    this.adx_enabled = $('#research_form_dimension_websites').attr('adx_enabled') == 'true'
  }

  _isAnyAdditionalDemandChannel(demandChannel) {
    const disablingDemandChannels = $(ResearchFormStateUpdater.DEMAND_CHANNELS).not(demandChannel).get()

    return (
      disablingDemandChannels.find((selector) => {
        return $(this._getScopedSelector(selector)).prop('checked')
      }) !== undefined
    )
  }

  _updateSource() {
    !this.hasSsp && !this.isPublisher && this._disableSspSource()
    this._updateCheckboxesActiveClass('source')

    ResearchFormStateUpdater.SOURCE_SELECTORS.forEach((selector) => {
      if ($(this._getScopedSelector(selector)).prop('checked') === true) {
        this.source = this._translateSelectValueToEnum($(this._getScopedSelector(selector)).val())
      }
    })

    if (this.source === undefined) {
      this.source = this._translateSelectValueToEnum(
        $(this._getScopedSelector('#research_form_source_ad_manager')).val()
      )
    }
  }

  _disableSspSource() {
    const element = $(this._getScopedSelector('#research_form_source_ssp'))

    element.prop('disabled', true)
    element.closest('label').toggleClass('disabled', true)
    element.closest('label').toggleClass('active', false)
    element.prop('checked', false)
  }

  _translateSelectValueToEnum(value) {
    switch (value) {
      case 'ad_manager':
        return ResearchFormStateUpdater.SOURCES.AD_MANAGER
      case 'ssp':
        return ResearchFormStateUpdater.SOURCES.SSP
      default:
        return ResearchFormStateUpdater.SOURCES.AD_MANAGER
    }
  }

  _disabledAll(disable) {
    this._disableCheckboxes(disable)
    this._disableQueryNameInput(disable)
    this._disableAllButtons(disable)
    this._disableTooltips(true)
  }

  _disableCheckboxes(disable) {
    const selectors = ['.ad_manager_demand_channels .checkbox', '.dimension .checkbox']

    selectors.forEach((selector) => {
      const checkboxes = $(this._getScopedSelector(selector))

      checkboxes.each((_, el) => {
        const label = $(el).children()
        label.toggleClass('disabled', disable)
      })
    })
  }

  _disableQueryNameInput(disable) {
    $(this._getScopedSelector('.research_form_report_name')).children('input').prop('disabled', disable)
  }

  _disableAllButtons(disable) {
    const selectors = ['#demand-all-button', '.research__add-filter-btn', '.research__run-btn']

    selectors.forEach((selector) => {
      $(this._getScopedSelector(selector)).toggleClass('disabled', disable)
    })
  }

  _disableTooltips(disable) {
    $(this._getScopedSelector('.checkbox i')).toggleClass('disabled', disable)
  }

  _updateDemandChannels(channelsIds, enable) {
    const enableFunction = enable ? 'addClass' : 'removeClass'

    $.each(channelsIds, (_, object) => {
      const targetObject = this._getScopedSelector(object)
      $(targetObject).parent()[enableFunction]('active')
      $(targetObject).prop('disabled', !enable)
      $(targetObject).closest('label').toggleClass('disabled', !enable)
    })
  }

  _updateDemandAllButton(className) {
    const checkboxes = $(this._getScopedSelector('.' + className + ' .checkbox'))
    const button = $(this._getScopedSelector('#demand-all-button'))
    const allLabelsDisabled = checkboxes.find('input:disabled').length === checkboxes.length

    if (allLabelsDisabled) {
      button.toggleClass('disabled', true)
      return
    }

    const allLabelsActive = checkboxes.find('input:checked').length === checkboxes.length
    button.toggleClass('disabled', false)
    button.toggleClass('active', allLabelsActive)
  }

  _updateCheckboxesActiveClass(className) {
    const checkboxes = $(this._getScopedSelector('.' + className + ' .checkbox'))
    const noChecked = checkboxes.find('input:checked').length === 0
    const disabled = checkboxes.find('input:disabled').length === checkboxes.length

    if (!disabled && noChecked) {
      checkboxes.find('input').first().prop('checked', true)
    }

    checkboxes.find('label').removeClass('active')
    checkboxes.find('input:checked').parent().addClass('active')
  }

  _updateDynamicFilters() {
    this._toggleAllDynamicFiltersState(this._dynamicFiltersDimensionsToBeDisabled())
  }

  _toggleAllDynamicFiltersState(disabledFilters) {
    const serializedDisableFilters = disabledFilters.join(',')

    $(this._getScopedSelector('.dynamic-filter')).each((_, filter) => {
      this._toggleDynamicFilterState(filter, serializedDisableFilters)
    })
  }

  _toggleDynamicFilterState(dynamicFilter, disabledFilters) {
    $(dynamicFilter).trigger('dynamicFilter:toggleDynamicFilters', disabledFilters)
  }

  _updateDimensions() {
    if (!this.adx_enabled) {
      this._activateDimensionsFor(ResearchFormStateUpdater.AD_MANAGER_HISTORICAL_DIMENSIONS)
    } else if (!this.areDemandChannelsExceptEbdaSelected) {
      this._activateDimensionsFor(ResearchFormStateUpdater.AD_MANAGER_EBDA_ONLY_DIMENSIONS)
    } else if (this.areDemandChannelsExceptAdxSelected) {
      this._activateDimensionsFor(ResearchFormStateUpdater.AD_MANAGER_HISTORICAL_DIMENSIONS)
    } else {
      this._activateDimensionsFor(ResearchFormStateUpdater.AD_MANAGER_ADX_ONLY_DIMENSIONS)
    }
  }

  _activateDimensionsFor(selectedDimensions) {
    const dimensionsToDisable = $(ResearchFormStateUpdater.ALL_DIMENSIONS).not(selectedDimensions).get()
    this._disableDimensions(dimensionsToDisable)
    this._enableDimensions(selectedDimensions)
  }

  _disableDimensions(dimensions) {
    this._toggleDimensions(dimensions, true)
  }

  _enableDimensions(dimensions) {
    this._toggleDimensions(dimensions, false)
  }

  _toggleDimensions(dimensions, shouldDisable) {
    $.each(dimensions, (_, dimensionId) => {
      this._updateDimension(dimensionId, shouldDisable)
    })
  }

  _updateDimension(elementId, shouldDisable) {
    const dimensionEl = $(this._getScopedSelector(elementId))

    if (shouldDisable && dimensionEl.prop('checked')) {
      const defaultDimension = $(this._getScopedSelector('#research_form_dimension_no_dimension'))
      defaultDimension.prop('checked', true)
      defaultDimension.closest('label').toggleClass('active', true)
    }

    dimensionEl.prop('disabled', shouldDisable)
    dimensionEl.closest('label').toggleClass('disabled', shouldDisable)
    if (shouldDisable) {
      dimensionEl.closest('label').toggleClass('active', false)
      dimensionEl.prop('checked', false)
    }
  }
}

ResearchFormStateUpdater.SOURCES = Object.freeze({
  AD_MANAGER: 'Google Ad Manager',
  SSP: "Directly from SSP's",
})

ResearchFormStateUpdater.SOURCE_SELECTORS = ['#research_form_source_ad_manager', '#research_form_source_ssp']

ResearchFormStateUpdater.DEMAND_CHANNELS = [
  '#research_form_demand_channels_adx',
  '#research_form_demand_channels_hb',
  '#research_form_demand_channels_direct',
  '#research_form_demand_channels_adsense',
  '#research_form_demand_channels_other',
  '#research_form_demand_channels_ebda',
]

ResearchFormStateUpdater.AD_EXCHANGE_DEMAND_CHANNEL = ['#research_form_demand_channels_adx']

ResearchFormStateUpdater.EBDA_DEMAND_CHANNEL = ['#research_form_demand_channels_ebda']

ResearchFormStateUpdater.ALL_DIMENSIONS = [
  '#research_form_dimension_demand_channel',
  '#research_form_dimension_programmatic_channel',
  '#research_form_dimension_devices',
  '#research_form_dimension_ad_units',
  '#research_form_dimension_geo',
  '#research_form_dimension_products',
  '#research_form_dimension_websites',
  '#research_form_dimension_platform',
  '#research_form_dimension_yield_partner',
]

ResearchFormStateUpdater.AD_MANAGER_HISTORICAL_DIMENSIONS = [
  '#research_form_dimension_demand_channel',
  '#research_form_dimension_devices',
  '#research_form_dimension_ad_units',
  '#research_form_dimension_geo',
  '#research_form_dimension_programmatic_channel',
  '#research_form_dimension_websites',
]

ResearchFormStateUpdater.AD_MANAGER_ADX_ONLY_DIMENSIONS = [
  '#research_form_dimension_demand_channel',
  '#research_form_dimension_devices',
  '#research_form_dimension_ad_units',
  '#research_form_dimension_geo',
  '#research_form_dimension_products',
  '#research_form_dimension_websites',
]

ResearchFormStateUpdater.AD_MANAGER_EBDA_ONLY_DIMENSIONS = [
  '#research_form_dimension_demand_channel',
  '#research_form_dimension_devices',
  '#research_form_dimension_ad_units',
  '#research_form_dimension_geo',
  '#research_form_dimension_yield_partner',
  '#research_form_dimension_websites',
]

ResearchFormStateUpdater.SSP_NETWORK_DIMENSIONS = [
  '#research_form_dimension_demand_channel',
  '#research_form_dimension_ad_units',
  '#research_form_dimension_websites',
  '#research_form_dimension_platform',
]

ResearchFormStateUpdater.SSP_PUBLISHER_DIMENSIONS = ['#research_form_dimension_websites']

ResearchFormStateUpdater.SSP_ALL_PUBLISHERS_USER_DIMENSIONS = [
  '#research_form_dimension_ad_units',
  '#research_form_dimension_websites',
]

ResearchFormStateUpdater.DISABLED_FILTERS_FOR_NETWORK_SSP = [
  'geo',
  'device',
  'product',
  'yield_partner',
  'programmatic_channel',
  'ad_unit_domain',
]

ResearchFormStateUpdater.DISABLED_FILTERS_FOR_PUBLISHER_SSP = [
  'geo',
  'device',
  'product',
  'yield_partner',
  'programmatic_channel',
  'ad_unit',
  'ad_unit_domain',
]

ResearchFormStateUpdater.DISABLED_FILTERS_FOR_AD_MANAGER_HISTORICAL = [
  'product',
  'yield_partner',
  'platform',
  'website'
]

ResearchFormStateUpdater.DISABLED_FILTERS_FOR_EBDA_ONLY = ['programmatic_channel', 'product', 'platform', 'website']

ResearchFormStateUpdater.DISABLED_FILTERS_FOR_ADX_ONLY = ['programmatic_channel', 'yield_partner', 'platform', 'ad_unit_domain']
