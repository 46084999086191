import { OptionProps } from 'react-select/src/types'

const option = (styles: object, props: OptionProps) => {
  return {
    ...styles,
    padding: '6px 16px',
    fontSize: '14px',
    backgroundColor: props.isFocused ? '#94fce9' : 'inherit',
    color: props.isFocused ? '#00c9b2' : 'inherit',
    cursor: 'pointer',

    ':active': {
      backgroundColor: '#94fce9',
      color: '#00c9b2',
    },

    ':hover': {
      backgroundColor: '#94fce9',
      color: '#00c9b2',
    },
  }
}

export default option
