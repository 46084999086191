import React, { useState } from 'react'
import ToastNotifier, { NotificationType } from 'javascripts/components/ToastNotifier'

export const useToastNotifier = () => {
  const [Notifier, setNotifier] = useState(null)

  const createNotifier = (message: string, type: NotificationType): void => {
    setNotifier(
      <ToastNotifier
        config={{
          type,
          message,
        }}
        onRenderFinished={(finished: boolean): void => {
          if (finished) setNotifier(null)
        }}
      />
    )
  }

  return { Notifier, createNotifier }
}
