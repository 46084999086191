import React from 'react'

import DefaultAvatar from 'images/user.svg'

interface Props {
  src?: string
  className?: string
}

const Avatar: React.FC<Props> = ({ src, className }) => {
  const getClassName = () => className ?? ''

  return <img src={src} alt='user avatar' className={getClassName()} />
}

Avatar.defaultProps = {
  src: DefaultAvatar,
}

export default React.memo(Avatar)
