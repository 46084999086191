import React from 'react'

import Box from 'javascripts/components/Box'
import ConnectSspTileContainer from 'javascripts/containers/Ssps/ConnectSspTileContainer'
import CurrentProfileContext from 'javascripts/components/CurrentProfile'
import { SspTypes } from 'javascripts/components/Ssps'

const ConnectSspTileView: React.FC<SspTypes.ConnectSspTileViewProps> = ({
  ssps,
  currentProfile,
}) => {
  return (
    <CurrentProfileContext.Provider value={currentProfile}>
      <div className='main-wrapper__item'>
        <Box className='connected-ssps__header'>Add new SSP connection</Box>
        {ssps.length > 0 && <ConnectSspTileContainer ssps={ssps} />}
      </div>
    </CurrentProfileContext.Provider>
  )
}

export default ConnectSspTileView
