export class CheckboxesHandler {
  constructor(container, clickCallback) {
    this.container = container;
    this.clickCallback = clickCallback;
  }

  create = () => {
    this._demandAllButtonHandler();
    this._sourceCheckboxHandler();
    this._demandChannelCheckboxHandler();
    this._dimensionCheckboxHandler();
  };

  _demandAllButtonHandler = () => {
    const demandContainer = this._findWithinContainer(CheckboxesHandler.DEMAND_CONTAINER);
    const allButton = demandContainer.find(CheckboxesHandler.ALL_BUTTON);

    allButton.on('click', e => {
      e.preventDefault();

      allButton.toggleClass('active');
      demandContainer.find('label').each((_, label) => {
        $(label)
          .children('input')
          .prop('checked', allButton.hasClass('active'));
      });
      this._setFirstWhenAllUnchecked(demandContainer);

      this.clickCallback();
    });
  };

  _sourceCheckboxHandler = () => {
    this._findWithinContainer('.source .checkbox')
      .children()
      .on('click', e => this._checkboxHandler(e, 'research_form_source'));
  }

  _demandChannelCheckboxHandler = () => {
    this._findWithinContainer('.ad_manager_demand_channels .checkbox')
      .children()
      .on('click', e => this._checkboxHandler(e, undefined));
  };

  _dimensionCheckboxHandler = () => {
    this._findWithinContainer('.dimension .checkbox')
      .children()
      .on('click', e => this._checkboxHandler(e, 'research_form_dimension'));
  };

  _checkboxHandler = (event, checkboxContainer) => {
    event.preventDefault();

    const label = $(event.target);
    if (label.hasClass('disabled')) {
      return;
    }

    const target = this._findWithinContainer(`#${label.attr('for')}`);
    const newState = !target.prop('checked');
    checkboxContainer && this._resetAllCheckboxes(checkboxContainer, target);
    target.prop('checked', newState);
    this._setFirstWhenAllUnchecked(target);

    this.clickCallback();
  }

  _findWithinContainer = selector => $(this.container).find(selector);

  _resetAllCheckboxes = (containerClassName, target) => {
    const checkboxesContainer = target.closest('div');
    if (checkboxesContainer.hasClass(containerClassName)) {
      checkboxesContainer.find('input').each((_, input) => {
        $(input).prop('checked', false);
        $(input)
          .closest('label')
          .toggleClass('active', false);
      });
    }
  };

  _setFirstWhenAllUnchecked (target) {
    const checkboxesContainer = target.closest('div');
    const noChecked = checkboxesContainer.find('input:checked').length === 0;

    if (noChecked) {
      checkboxesContainer
        .find('input')
        .first()
        .prop('checked', true);
    }
  }
}

CheckboxesHandler.DEMAND_CONTAINER = '.form__row-section--demand';
CheckboxesHandler.ALL_BUTTON = '#demand-all-button';
