import { ProgressBar } from '../containers/dashboard/ProgressBar'
import { Registration } from '../views/Registration'
import { ConfirmationInfo } from '../views/ConfirmationInfo'
import { NetworksConnection } from '../views/NetworksConnection'
import { SessionsNew } from '../views/SessionsNew'
import { AdblockDetector } from './AdblockDetector'
import { PasswordNew } from '../views/PasswordNew'
import { PasswordEdit } from '../views/PasswordEdit'
import SavedQueries from '../views/SavedQueries'
import ConnectedSspView from '../views/Ssps/ConnectedSspView'
import ConnectSspTileView from '../views/Ssps/ConnectSspTileView'
import Profile from '../views/Profile'
import InvitationsView from '../views/InvitationsView'
import UserSettingsView from '../views/UserSettingsView'
import UserListContainer from '../containers/UserSettings/UserListContainer'
import NewPublisherView from '../views/Publisher/NewPublisherView'
import PublisherView from '../views/Publisher/PublisherView'
import EditPublisherView from '../views/Publisher/EditPublisherView'
import WebsitePerformanceContainer from '../containers/dashboard/WebsitePerformance/WebsitePerformanceContainer'

import SspLogo from './Ssps/SspLogo'

export default {
  AdblockDetector,
  ConfirmationInfo,
  NetworksConnection,
  ProgressBar,
  Registration,
  SessionsNew,
  PasswordNew,
  PasswordEdit,
  SavedQueries,
  ConnectedSspView,
  ConnectSspTileView,
  Profile,
  InvitationsView,
  UserSettingsView,
  UserListContainer,
  NewPublisherView,
  PublisherView,
  EditPublisherView,
  SspLogo,
  WebsitePerformanceContainer
}
