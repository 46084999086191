import { ResearchReportsReloader } from './research_reports_reloader';
import { ResearchDisableableElementsToggler } from './research_disableable_elements_toggler';

export class ResearchReportBaseLoader {
  constructor () {
    this.actionsHeader = '.research-header .research-header__actions';
  }

  load () {
    this._disableStaticElements();
    this._setDocumentReadyEvent();
    this._showActionsHeader();
  }

  _showActionsHeader () {
    $(this.actionsHeader).fadeIn(1000);
  }

  _disableStaticElements () {
    ResearchDisableableElementsToggler.disable();
  }

  _setDocumentReadyEvent () {
    $(() => {
      if (this._isAjaxActive()) {
        this._setOneTimeAjaxStopEvent();
      } else {
        this._loadReportContainers();
      }
    });
  }

  _isAjaxActive () {
    return $.active !== 0;
  }

  _setOneTimeAjaxStopEvent () {
    $(document).one('ajaxStop', () => this._loadReportContainers());
  }

  _loadReportContainers () {
    setTimeout(() => { new ResearchReportsReloader().reload(); }, 50);
  }
}
