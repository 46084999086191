import React from 'react'
import { MultiSelect } from 'javascripts/components/forms/Select'
import { SelectOption } from '../forms/Select/types'

interface Props {
  items: SelectOption[]
  endpoint: string
  addItem: (values: SelectOption[]) => void
  removeItem: (value: string) => void
  id: string
  placeholder: string
}

const ITEMS_REQUEST_LIMIT = 250

const InventoryForm: React.FC<Props> = ({ items, endpoint, addItem, removeItem, id, placeholder }) => {
  return (
    <>
      <MultiSelect
        id={id}
        endpoint={endpoint}
        placeholderMessage={placeholder}
        values={items}
        onAddSelectedValues={addItem}
        onRemoveSelectedValue={removeItem}
        params={{
          limit: ITEMS_REQUEST_LIMIT,
        }}
      />
    </>
  )
}

export default InventoryForm
