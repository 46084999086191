import * as moment from 'moment'

const initialDatePickerConfig = (): daterangepicker.Options => ({
  minDate: moment().subtract(1, 'years'),
  maxDate: moment().subtract(1, 'days'),
  maxSpan: { days: 93 },
  locale: { format: 'DD MMM', applyLabel: 'Run' },
  cancelButtonClasses: 'btn btn-sm btn-pure',
  parentEl: '.advanced-time-select',
})

export class DatePicker {
  private containerId: string
  private datePickerConfig: daterangepicker.Options

  constructor(containerId: string, datePickerConfig = initialDatePickerConfig) {
    this.containerId = containerId
    this.datePickerConfig = datePickerConfig()
  }

  create() {
    this.displayCalendar()
    this.setApplyClickEvent()
  }

  private displayCalendar() {
    $(this.containerId).daterangepicker(this.datePickerConfig)
  }

  private setApplyClickEvent() {
    $(this.containerId).on('apply.daterangepicker', (_event, picker) => {
      this.datePickerOnChangeCallback(picker.startDate, picker.endDate)
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  datePickerOnChangeCallback(_startDate: moment.Moment, _endDate: moment.Moment) {}
}
