import React from 'react';
import Input from '../../components/forms/Input';
import { ErrorFieldBox, errorClass } from '../../components/forms/ErrorFieldBox';
import { StepBar } from '../../components/registration/StepBar';
import * as inputValidators from "../../utlis/inputValidators";

interface Props {
  onNextStep: () => void
  handleInputChange: (name: InputNames, value: string) => void
  values: InputsValues
}

interface InputsValues {
  email: string
  userName: string
  password: string
  passwordConfirmation: string
}

interface State {
  isValid: boolean
  errors: {
    name: string[]
    password: string[]
    passwordConfirmation: string[]
  }
}

type InputNames = 'userName' | 'password' | 'passwordConfirmation';

export default class FormUserDetails extends React.Component<Props, State> {
  private getInitialState = () => {
    const initialState: State = {
      isValid: true,
      errors: {
        name: [],
        password: [],
        passwordConfirmation: []
      }
    };
    return initialState;
  };
  state = this.getInitialState();

  private continue = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    if (this.validateForm()) {
      this.props.onNextStep();
    }
  };

  private validateForm = () => {
    const { values } = this.props;
    const newState = this.getInitialState();

    inputValidators.presenceValidation('userName', values.userName, newState.errors.name);
    inputValidators.presenceValidation('password', values.password, newState.errors.password);
    inputValidators.lengthValidation('password', values.password, newState.errors.password);
    inputValidators.passwordFormatValidation(values.password, newState.errors.password);
    inputValidators.passwordEqualityValidation(values.password, values.passwordConfirmation, newState.errors.passwordConfirmation);
    inputValidators.setStateValidation(newState);

    this.setState(newState)
    return newState.isValid;
  }

  render () {
    const { values } = this.props
    const { errors } = this.state

    return (
      <React.Fragment>
        <div className="registration__header">
          <h2 className="registration__title">Create Account</h2>
          <small className="registration__description">
            Let's get your account set up.<br></br>It will take about a minute.
          </small>
        </div>

        <div className="form__body">
          <div className={errorClass(errors.name)}>
            <Input
              id="user_name"
              type="text"
              label="Full name"
              value={values.userName}
              required={true}
              onChange={value => {
                this.props.handleInputChange('userName', value);
              }}
            />
            <ErrorFieldBox errors={errors.name} />
          </div>

          <Input
            id="user_email"
            type="email"
            label="Your email"
            value={values.email}
            required={true}
            disabled={true}
            onChange={value => {
              this.props.handleInputChange('userName', value);
            }}
          />

          <div className={errorClass(errors.password)}>
            <Input
              id="user_password"
              type="password"
              label="Password"
              value={values.password}
              required={true}
              onChange={value => {
                this.props.handleInputChange('password', value);
              }}
            />
            <ErrorFieldBox errors={errors.password} />
          </div>

          <div className={errorClass(errors.passwordConfirmation)}>
            <Input
              id="user_password_confirmation"
              type="password"
              label="Confirm password"
              value={values.passwordConfirmation}
              required={true}
              onChange={value => {
                this.props.handleInputChange('passwordConfirmation', value);
              }}
            />
            <ErrorFieldBox errors={errors.passwordConfirmation} />
          </div>

          <p className="form__agreement">
            By clicking “Next” you create an account and agree to<br></br>our
            <a className="link" target="_blank" href="/documents/terms_and_conditions.pdf">
              {' '}
              Terms and conditions
            </a>{' '}
            &
            <a className="link" target="_blank" href="/documents/privacy_policy.pdf">
              {' '}
              Privacy Policy
            </a>
          </p>
        </div>
        <div className="multi-steps multi-steps--1">
          <StepBar step={1} />
          <div className="form__buttons form__buttons--right">
            <button className="button" onClick={this.continue}>
              Next
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
