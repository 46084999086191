import { MultilineChartModel } from './multiline_chart_model';

export class ResearchChartModel extends MultilineChartModel {
  constructor (data, shouldCompare) {
    super(data);
    this.shouldCompare = shouldCompare;
  }
  setIsOthersLabelInactive (isOthersLabelInactive) {
    this.isOthersLabelInactive = isOthersLabelInactive;
  }

  getXAxisDomain () {
    return [...Array(this._getBlockLength()).keys()];
  }

  getBlockDomain () {
    if (typeof this.blockDomainData === 'undefined') {
      const labels = this._getBlockDomainLabels();

      this.blockDomainData = this._getPairsFrom(Object.keys(labels)).map(([previousLabel, currentLabel], index) => {
        const previous = labels[previousLabel].map(elem => Object.assign({}, {...elem}, { previous: true }));

        return { label: currentLabel, data: labels[currentLabel].concat(previous), id: index, previousLabel: previousLabel };
      });
    }

    return this.blockDomainData;
  }

  getLineDomain () {
    if (typeof this.lineDomainData === 'undefined') {
      this.lineDomainData = this._getSlicedLineData(this._getFloorBlockLength(), this._getTotalLength());
    }

    return this.lineDomainData;
  }

  getPreviousLineDomain () {
    if (typeof this.previousLineDomainData === 'undefined') {
      this.previousLineDomainData = this._getSlicedLineData(0, this._getBlockLength());
    }

    return this.previousLineDomainData;
  }

  _getFloorBlockLength () {
    return Math.floor(this._getTotalLength() / 2);
  }

  _getBlockLength () {
    return Math.ceil(this._getTotalLength() / 2);
  }

  _getTotalLength () {
    return this.rawData[0].data.length;
  }

  _getSlicedLineData (start, stop) {
    return this.rawData.map(elem => this._sliceData(elem, start, stop)).map(d => this._assignDataIds(d));
  }

  _sliceData (elem, start, stop) {
    return Object.assign({}, elem, { data: [...elem.data].slice(start, stop) })
  }

  _assignDataIds (d) {
    const data = d.data.map((elem, i) => ({ ...elem, id: i }));

    return {...d, data}
  }

  _assignIds () {
    this.rawData.forEach((d, i) => { d.id = i; });
  }

  _getPairsFrom (dates) {
    const length = this._getBlockLength();
    dates.sort(this._sortAsDate);
    return [...Array(length)].map((_, index) =>
      [dates[index], dates[index + this._getFloorBlockLength()]]
    );
  }

  _sortAsDate (a, b) {
    return new Date(a) - new Date(b);
  }

  _getMaxYValue () {
    if (this.shouldCompare === false) {
      return Math.max(...this._getOnlyCurrentValues());
    } else {
      return Math.max(...this._getCurrentAndPreviousValues());
    }
  }

  _getOnlyCurrentValues () {
    return this._getAvailableTypes().map(elem => Math.max(...elem.data.slice(elem.data.length / 2, elem.data.length).map(d => d.value)));
  }

  _getCurrentAndPreviousValues () {
    return this._getAvailableTypes().map(elem => Math.max(...elem.data.map(d => d.value)));
  }

  _getAvailableTypes () {
    return this.rawData.filter(e => e.type !== 'Others' || !this.isOthersLabelInactive);
  }
}
