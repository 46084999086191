import { AjaxErrorsHandler } from '../ajax_errors_handler';

export class ResearchAjaxErrorsHandler extends AjaxErrorsHandler {
  constructor (container, caller) {
    super(container, caller);

    this.ajaxError = '.ajax-error';
    this.body = '.body';
  }

  _showErrorInfo () {
    if (this._noErrorInfoPresent()) {
      $(this.container).find(this.body).hide();
      $(this.container).append($(this.ajaxErrorTemplate).html());
    }
  }

  _noErrorInfoPresent () {
    return $(this.container).find(this.ajaxError).length === 0;
  }

  _retryFailedRequest () {
    $(this.container).find(this.caller).trigger('click');
  }
}
