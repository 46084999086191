import React from 'react'

import InvitationsContainer from 'javascripts/containers/Invitations/InvitationsContainer'
import CurrentProfileContext, { CurrentProfile } from 'javascripts/components/CurrentProfile'

interface Props {
  currentProfile: CurrentProfile
}

const InvitationsView: React.FC<Props> = ({ currentProfile }) => {
  return (
    <CurrentProfileContext.Provider value={currentProfile}>
      <div className='main-wrapper__item'>
        <InvitationsContainer />
      </div>
    </CurrentProfileContext.Provider>
  )
}

export default InvitationsView
