export class ResearchChartLegend {
  constructor (chart, labels) {
    this.chart = chart;
    this.labels = labels;
  }

  build () {
    $(this.chart.el).before(this._getContainer());
    this._setClickEvent();
  }

  toggle (label) {
    $(this.chart.el).siblings().find(['.label', this.chart.cssClassProvider.getClassWithIndex(label)].join('.')).click();
  }

  _getContainer () {
    return this._getTemplate().replace(this._getListItemTemplate(), this._getList());
  }

  _setClickEvent () {
    $(this.chart.el).siblings().find('.label').on('click', this._clickHandler.bind(this));
  }

  _getTemplate () {
    return $(ResearchChartLegend.TEMPLATE_SELECTOR).html();
  }

  _getListItemTemplate () {
    return this._getTemplate().match(ResearchChartLegend.LIST_ITEM_REGEX)[0];
  }

  _getList () {
    return this.labels.map(this._getListItem.bind(this)).join('');
  }

  _clickHandler (event) {
    event.target.classList.toggle('inactive');
    this.chart.toggleWithLabels(event.target.innerText.trim());
  }

  _getListItem (label) {
    return this
      ._getListItemTemplate()
      .replace(ResearchChartLegend.CLASS_NAME_TAG, this._getClassNameFor(label))
      .replace(ResearchChartLegend.LABEL_NAME_TAG, label);
  }

  _getClassNameFor (label) {
    return this.chart.cssClassProvider.getClassFor('label', label);
  }
}

ResearchChartLegend.TEMPLATE_SELECTOR = "script#research-chart-legend[type='text/template']";
ResearchChartLegend.CLASS_NAME_TAG = '{class_name}';
ResearchChartLegend.LABEL_NAME_TAG = '{label_name}';
ResearchChartLegend.LIST_ITEM_REGEX = /<li(.|[\r\n])*<\/li>/;
