const EMAIL_REGEX = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/

const transformInput = {
  userName: 'Name',
  email: 'Email',
  companyName: 'Company name',
  password: 'Password',
  name: 'Name',
  currency: 'Currency',
  language: 'Language',
  margin: 'Margin',
}

type InputValues =
  | 'userName'
  | 'email'
  | 'companyName'
  | 'password'
  | 'name'
  | 'currency'
  | 'language'
  | 'margin'
  | 'domainName'
  | 'adminCost'

export const requiredValidator = (value: string, message: string) => (value.length === 0 ? message : null)

export const presenceValidation = (input: InputValues, value: string | number, state: string[]) => {
  if (value === undefined || value === null || value === '') {
    state.push(`${transformInput[input]} can't be blank`)
  }
}

export const lengthValidation = (input: InputValues, value: string, state: string[]) => {
  if (value.length < 8) {
    state.push(`${transformInput[input]} should be at least 8 characters long`)
  }
}

export const emailFormatValidation = (email: string, state: string[]) => {
  if (!email.match(EMAIL_REGEX)) {
    state.push('This email is not valid')
  }
}

export const passwordFormatValidation = (password: string, state: string[]) => {
  if (!password.match(PASSWORD_REGEX)) {
    state.push('Password must include at least one lowercase letter, one uppercase letter, and one digit')
  }
}

export const passwordEqualityValidation = (password: string, passwordConfirmation: string, state: string[]) => {
  if (password !== passwordConfirmation) {
    state.push('Password and password confirmation must be the same')
  }
}

interface State {
  isValid: boolean
  errors: { [key: string]: string[] }
}

export const setStateValidation = (newState: State) => {
  newState.isValid = !Object.values(newState.errors).some((errors) => errors.length > 0)
}
