import { ResearchBaseRemoteHandler } from './_research_base_remote_handler';

export class ResearchRunHandler extends ResearchBaseRemoteHandler {
  constructor(containerSelector, runClickCallback = undefined) {
    super(containerSelector, ResearchRunHandler.RUN);

    this.runClickCallback = runClickCallback;
    this.handleAjaxErrors = true;
  }

  create () {
    this._getElement().on('click', e => {
      const isDisabled = $(e.target).hasClass('disabled');

      if (!isDisabled) {
        e.preventDefault();
        $(document).trigger('runButtonClicked');
        this.replaceReportBody(false);

        if (this.runClickCallback) {
          this.runClickCallback();
        }
      }
    });
  }

  replaceReportBody (lockReport, formParams) {
    $.ajax(this._getAjaxOptions(lockReport, formParams)).done(data => {
      return $(this.container).replaceWith(data.body);
    });
  }

  _getAjaxOptions (lockReport, formParams) {
    const options = super._getAjaxOptions(formParams);
    options.data.push({ name: 'lock_report', value: lockReport });

    return options;
  }

  _getEndpoint () {
    return $(this.container).data('run-endpoint');
  }
}

ResearchRunHandler.RUN = '.research__run-btn';
