import { AdvancedTimeSelect } from '../advancedTimeSelect'
import { ResearchSaveReportModalHandler } from './research_save_report_modal_handler'
import { ResearchReportsReloader } from './research_reports_reloader'
import { ResearchDownloadHandler } from './research_download_handler'

export class Research {
  constructor(savedReportMessage, isPublisher) {
    this.researchBody = '.research'
    this.download = '.research-download'
    this.savedReportMessage = savedReportMessage
    this.run = '.research__run-btn'
    this.reportName = '#research_form_report_name'
    this.researchHeader = '.research-header'
    this.headerReportName = '.name'
    this.isPublisher = isPublisher
  }

  create() {
    this._initializeResearchDownloadHandler()
    this._initializeResearchSaveReportModalHandler()
    this._initializeResearchReportsReloader()
    this._initializeAdvancedTimeSelect()
    this._setOneTimeRunClickEvent()
    this._setTimeChangeEvent()
  }

  _initializeResearchDownloadHandler() {
    new ResearchDownloadHandler(this.researchBody, this.download).create()
  }

  _initializeResearchSaveReportModalHandler() {
    new ResearchSaveReportModalHandler(this.researchBody, this.savedReportMessage).create()
  }

  _initializeResearchReportsReloader() {
    this.researchReportsReloader = new ResearchReportsReloader()
  }

  _initializeAdvancedTimeSelect() {
    this.AdvancedTimeSelect = new AdvancedTimeSelect(this.isPublisher)
    this.AdvancedTimeSelect.create()
  }

  _setOneTimeRunClickEvent() {
    $(`${this.run}:visible`).one('click', (e) => {
      e.preventDefault()
      this._setOneTimeAjaxCompleteEvent($(this.reportName).val())
    })
  }

  _setOneTimeAjaxCompleteEvent(name) {
    $(document).one('ajaxComplete', () => this._displayHeader(name))
  }

  _displayHeader(name) {
    $(this.headerReportName).html(name)
    $(this.researchHeader).show()
  }

  _setTimeChangeEvent() {
    this.AdvancedTimeSelect.onChange(this._reloadReports.bind(this))
  }

  _reloadReports() {
    this.researchReportsReloader.reload()
  }
}
