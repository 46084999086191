export class ChartCssClassProvider {
  constructor () {
    this.nextIndex = 0;
    this.indexes = {};
  }

  getClassFor (mainClassName, elementName) {
    const defaultClass = [mainClassName, this._getClassWithName(elementName)].join(' ');
    return elementName === 'background' ? defaultClass : [defaultClass, this.getClassWithIndex(elementName)].join(' ');
  }

  getClassWithIndex (name) {
    return `chart-component-${this._getIndexFor(name)}`;
  }

  _getClassWithName (name) {
    return `${name.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase()}-chart-color`;
  }

  _getIndexFor (name) {
    if (name === 'Others') {
      return 'others';
    }
    if (this.indexes[name] === undefined) {
      this.indexes[name] = this.nextIndex++;
    }
    return this.indexes[name];
  }
}
