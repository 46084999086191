export class ScrollingAnimator {
  constructor (referenceElement, duration = ScrollingAnimator.DEFAULT_DURATION_IN_MS) {
    this.element = referenceElement;
    this.duration = duration;
  }

  animate () {
    $('html, body').animate(this._getAnimationOptions(), this.duration);
  }

  _getAnimationOptions () {
    return { scrollTop: parseInt($(this.element).offset().top) };
  }
}

ScrollingAnimator.DEFAULT_DURATION_IN_MS = 500;
