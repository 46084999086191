import { SspConfiguration } from '../types'

import AmazonLogo from 'images/amazon_logo.png'
import AmazonSmallLogo from 'images/amazon_small_logo.png'

const amazonConfig: SspConfiguration = {
  platform: 'amazon',
  platformName: 'Amazon',
  logo: AmazonLogo,
  smallLogo: AmazonSmallLogo,
}

export default amazonConfig
