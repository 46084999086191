import { Spinner } from 'javascripts/spinner';
import { AdvancedTimeSelect } from '../advancedTimeSelect';
import { ResearchAjaxErrorsHandler } from './research_ajax_errors_handler';

export class ResearchBaseRemoteHandler {
  constructor(containerSelector, elementSelector) {
    this.container = containerSelector;
    this.element = elementSelector;
    this.spinner = new Spinner(containerSelector);
    this.form = '#new_research_form';
    this.researchReport = '.research-report';
    this.handleAjaxErrors = false;
  }

  _getElement () {
    return $(this.container).find(this.element);
  }

  _getAjaxOptions (data = this._getFormParams()) {
    return {
      type: 'POST',
      url: this._getEndpoint(),
      data: data,
      dataType: 'json',
      beforeSend: () => this.spinner.show(),
      complete: () => this.spinner.hide(),
      error: (xhr, status, error) => this._ajaxErrorCallback(xhr, status, error)
    };
  }

  _getFormParams (container = this.container) {
    const formParams = $(container)
      .find(`form${this.form}`)
      .serializeArray();

    const sourceParam = formParams.find(x => x.name === 'research_form[source][]');
    if (sourceParam) {
      sourceParam.name = 'research_form[source]';
    }

    const dimensionParam = formParams.find(x => x.name === 'research_form[dimension][]');
    if (dimensionParam) {
      dimensionParam.name = 'research_form[dimension]';
    }

    formParams.push({
      name: 'research_form[time]',
      value: $(AdvancedTimeSelect.SELECTED_OPTION).val()
    });

    return formParams;
  }

  _getEndpoint () {
    throw new Error('Not implemented');
  }

  _ajaxErrorCallback (xhr, _status, _error) {
    if (this.handleAjaxErrors) {
      if (xhr.status === 401) {
        window.location.replace('/users/sign_in');
      } else {
        new ResearchAjaxErrorsHandler(this.container, this.element).run();
      }
    }
  }

  _getFormQueries () {
    return $(this.researchReport)
      .map((_, container) => $.param(this._getFormParams(container)))
      .toArray();
  }
}
