import { MultilineChart } from './multiline_chart';
import { ResearchChartModel } from './models/research_chart_model';
import { ResearchChartTooltip } from './tooltips/research_chart_tooltip';

export class ResearchChart extends MultilineChart {
  constructor (element, data, customAttributes, time) {
    super(element, data, customAttributes);
    this.time = time;
    this.inactiveTypes = [];
    this.shouldCompare = false;
  }

  _defaultAttributes () {
    return Object.assign({}, super._defaultAttributes(), {
      dashSize: 8,
      xAxisInconsistentWeekDayLabelsMinWidth: 36,
      compareLineStrokeWidth: '1px'
    });
  }

  getShouldCompare () {
    return this.shouldCompare;
  }

  createChart () {
    super.createChart();

    this._drawShapes(this.dataModel.getPreviousLineDomain(), this.compareLineStrokeWidth, this.dashSize);
    this._updateInactiveElements();

    return this;
  }

  updateChart (data, customAttributes) {
    if (data) {
      this._setDataAndAttributes(data, customAttributes);
    }

    this.dataModel.setIsOthersLabelInactive(this.inactiveTypes.includes('Others'));
    this.redrawChart();
  }

  toggleWithLabels (type) {
    const index = this.inactiveTypes.indexOf(type);
    index < 0 ? this.inactiveTypes.push(type) : this.inactiveTypes.splice(index, 1);
    this._togglePath(type);
    if (type === 'Others') {
      this.updateChart();
    }
  }

  setShouldCompare (shouldCompare) {
    this.shouldCompare = shouldCompare;
    this.dataModel.shouldCompare = shouldCompare;
    this._updateComparableElements();
    this.redrawChart();
  }

  isActive (type) {
    return this.inactiveTypes.indexOf(type) < 0;
  }

  dataFilterCallback (data) {
    return this.isActive(data.type) && (this.shouldCompare || !data.previous);
  }

  _setData (data) {
    this.dataModel = new ResearchChartModel(data, this.shouldCompare);
    this.tooltip = new ResearchChartTooltip(this);
  }

  _updateInactiveElements () {
    this.inactiveTypes.forEach(this._togglePath.bind(this));
    this._updateComparableElements();
  }

  _togglePath (type) {
    const selector = `#${this.el.id} ${['path', this.shape, this.cssClassProvider.getClassWithIndex(type)].join('.')}`;
    $(selector).toggleClass('inactive');
  }

  _updateComparableElements () {
    const element = $(`#${this.el.id} path[style*='stroke-dasharray: ${this.dashSize}']`);
    const className = 'comparable-off';
    this.shouldCompare ? element.removeClass(className) : element.addClass(className);
  }

  _formatXAxisLabel (_, index) {
    if (!this._isLabelVisible(index)) return;

    const label = this.dataModel.getBlockDomain()[index].label;
    if (this._useMonthXAxisLabels()) return this._getNameMonth(label);

    if (this._areWeekdaysInconsistent() && this.helperScale.bandwidth() >= this.xAxisInconsistentWeekDayLabelsMinWidth) {
      return this._formatDateFrom(label);
    }

    return this._getWeekDay(label);
  }

  _areWeekdaysInconsistent () {
    return this.shouldCompare && this._isTimeRangeExcludingDaysNames();
  }
  _isTimeRangeExcludingDaysNames () {
    return ['seven_days'].indexOf(this.time) <= -1;
  }
}
