import React from "react";
import logoDark from "images/logo-dark.svg";
import iconFailure from "images/icon-failure.png"

export const NoNewNetworks = () => (
  <div className="dfp-failure">
    <div className="dfp-failure__box">
      <a className="close" href="/panel">×</a>
      <img alt="InSight Yieldbird" className="dfp-failure__logo" src={ logoDark } />
      <img alt="Failure icon, Something went wrong" className="dfp-failure__icon" src={ iconFailure } />
      <div className="dfp-failure__info"><strong>Nothing to connect</strong></div>
      <div className="dfp-failure__text">You already connected all Ad Managers related to this account</div>
      <a className="btn-primary btn-block btn--big" rel="nofollow" data-method="post" href="/panel/dfp">Try again</a>
    </div>
  </div>
)
