import React from 'react';

import ConnectedSspsContainer from 'javascripts/containers/Ssps/ConnectedSspsContainer';
import { ReportFileUpload, NewConnectSspLink, SspTypes } from 'javascripts/components/Ssps';
import CurrentProfileContext from 'javascripts/components/CurrentProfile';
import Box from 'javascripts/components/Box';

const ConnectedSspView: React.FC<SspTypes.ConnectedSspViewProps> = ({ connectedSsps, currentProfile, newSspEndpoint, fileUploadEndpoint }) => {
  return (
    <CurrentProfileContext.Provider value={currentProfile}>
      <div className="main-wrapper__item">
        <ReportFileUpload endpoint={fileUploadEndpoint} />
        <Box className="connected-ssps__header">List of connect SSP</Box>
        {connectedSsps.length > 0 && <ConnectedSspsContainer connectedSsps={connectedSsps} />}
        <NewConnectSspLink endpoint={newSspEndpoint} />
      </div>
    </CurrentProfileContext.Provider>
  )
};

export default ConnectedSspView;
