import React from 'react'

import { WebsitePerformanceRowProps } from './types'

const WebsitePerformanceRow: React.FC<WebsitePerformanceRowProps> = ({ item, currency }) => {
  return (
    <div className='website-performance__row'>
      <div className='website-performance__cell'>{item.site_name}</div>
      <div className='website-performance__cell'>{`${item.revenue} ${currency}`}</div>
      <div className='website-performance__cell'>{item.ad_impressions}</div>
      <div className='website-performance__cell'>{`${item.ecpm.toFixed(2)} ${currency}`}</div>
    </div>
  )
}

export default WebsitePerformanceRow
