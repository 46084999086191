/* eslint-disable react/display-name */
import React, { useState } from 'react'
import AsyncSelect from 'react-select/async'
import axios from 'axios'

import { SelectOption, MultiSelectQueryResult } from '../types'
import multiSelectStyles from './styles'
import SelectedValue from './SelectedValue'

interface QueryParams {
  limit?: number
}

interface Props {
  endpoint: string
  id?: string
  placeholderMessage: string
  emptyResultMessage?: string
  noOptionsMessage?: string
  loadingMessage?: string
  values?: SelectOption[]
  onAddSelectedValues: (values: SelectOption[]) => void
  onRemoveSelectedValue: (value: string) => void
  params?: QueryParams
}

const MultiSelect: React.FC<Props> = (props) => {
  const [isEmptyResults, setEmptyResults] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')

  const onInputChange = (query: string, { action }) => {
    if (action !== 'set-value') {
      setInputValue(query)
      return query
    }
    return inputValue
  }

  const transformResultToOption = (result: MultiSelectQueryResult): SelectOption => ({
    value: result.id,
    label: result.text,
  })

  const onLoadOptionsSuccessHandler = (results: MultiSelectQueryResult[], inputValue: string) => {
    results.length === 0 && inputValue.trim() !== '' ? setEmptyResults(true) : setEmptyResults(false)

    return results.map(transformResultToOption)
  }

  const onLoadOptions = (inputValue: string) => {
    const params = {
      q: inputValue,
      ...props.params,
    }
    return axios
      .get(props.endpoint, { params: params })
      .then((response) => response.data.results)
      .then((results) => onLoadOptionsSuccessHandler(results, inputValue))
  }

  const onChange = (values: SelectOption[]) => {
    props.onAddSelectedValues(values)
  }

  const getNoOptionMessage = () => (isEmptyResults ? props.emptyResultMessage || '' : props.noOptionsMessage || '')

  const getLoadingMessage = () => props.loadingMessage || ''

  const removeValue = (value: string) => value && props.onRemoveSelectedValue(value)

  return (
    <>
      <AsyncSelect
        id={props.id}
        isMulti
        cacheOptions
        loadOptions={onLoadOptions}
        components={{
          IndicatorSeparator: () => null,
          IndicatorsContainer: () => null,
          MultiValueContainer: (props) => <SelectedValue value={props.data} onRemove={removeValue} />,
        }}
        styles={multiSelectStyles}
        placeholder={props.placeholderMessage}
        noOptionsMessage={getNoOptionMessage}
        onChange={onChange}
        loadingMessage={getLoadingMessage}
        value={props.values}
        inputValue={inputValue}
        blurInputOnSelect={false}
        closeMenuOnSelect={false}
        onInputChange={onInputChange}
      />
    </>
  )
}

MultiSelect.defaultProps = {
  loadingMessage: 'Searching...',
  emptyResultMessage: 'No results found',
  noOptionsMessage: 'Please enter 1 or more characters',
}

export default MultiSelect
