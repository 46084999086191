import { ResearchScopedSelector } from './__research_scoped_selector'
import { ResearchRunHandler } from './research_run_handler'
import { ResearchFormStateUpdater } from './research_form_state_updater'
import { ResearchDisableableElementsToggler } from './research_disableable_elements_toggler'
import { ResearchCancelEditHandler } from './research_cancel_edit_handler'
import { CheckboxesHandler } from '../checkboxes_click_handler'

export class ResearchForm extends ResearchScopedSelector {
  constructor(uuid, isPublisher, isAllPublishersUser, hasSsp) {
    super(uuid)

    this.isPublisher = isPublisher
    this.isAllPublishersUser = isAllPublishersUser
    this.hasSsp = hasSsp
  }

  create() {
    this._initializeResearchFormStateUpdater()
    this._initializeTooltips()
    this._initializeRunHandler()
    this._initializeCheckboxesHandler()
    this._initializeResearchCancelEditHandler()
    this._setAddFilterClickEvent()

    return this
  }

  updateFormState() {
    this.researchFormStateUpdater.update()
  }

  _initializeResearchFormStateUpdater() {
    this.researchFormStateUpdater = new ResearchFormStateUpdater(this.uuid, this.isPublisher, this.isAllPublishersUser, this.hasSsp)
  }

  _initializeTooltips() {
    $(this._getScopedSelector(ResearchForm.QUESTION_MARK_TOOLTIP)).tooltip()
  }

  _initializeRunHandler() {
    new ResearchRunHandler(this._getReportContainerSelector(), this._setOneTimeAjaxStopEvent).create()
  }

  _initializeCheckboxesHandler() {
    new CheckboxesHandler(this._getReportContainerSelector(), this.updateFormState.bind(this)).create()
  }

  _initializeResearchCancelEditHandler() {
    new ResearchCancelEditHandler(this.uuid, this).create()
  }

  _setAddFilterClickEvent() {
    $(this._getScopedSelector(ResearchForm.ADD_FILTER_SELECTOR)).on('click', (e) => {
      e.preventDefault()
      this._createDynamicFilter()
    })
  }

  _createDynamicFilter() {
    $(this._getScopedSelector('.dynamic-filters__container')).append(
      $(this._getScopedSelector(ResearchForm.ADD_FILTER_SELECTOR)).data('template')
    )

    const newDynamicFilter = $(this._getScopedSelector('.dynamic-filter:last select'))

    this.researchFormStateUpdater.initializeDynamicFilter(newDynamicFilter)
  }

  _setOneTimeAjaxStopEvent() {
    $(document).one('ajaxStop', () => ResearchDisableableElementsToggler.tryToEnable())
  }
}

ResearchForm.ADD_FILTER_SELECTOR = 'a.research__add-filter-btn'
ResearchForm.QUESTION_MARK_TOOLTIP = '.checkbox i'
