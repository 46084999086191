import React from 'react'
import { errorClass, ErrorFieldBox } from 'javascripts/components/forms/ErrorFieldBox'
import Input from 'javascripts/components/forms/Input'
import Select, { CurrencySelect } from 'javascripts/components/forms/Select'
import { Publisher, PublisherErrors } from './types'

interface Props {
  errors: PublisherErrors
  publisher: Publisher
  updatePublisherProperties: Function
}

const PublisherForm: React.FC<Props> = ({ errors, publisher, updatePublisherProperties }) => {
  return (
    <div className='publisher__form--main'>
      <div className={`${errorClass(errors.name)}`}>
        <Input
          type='text'
          id='name'
          name='name'
          value={publisher.name}
          label='Publisher name'
          onChange={(value) => updatePublisherProperties('name', value)}
        />
        <ErrorFieldBox errors={errors.name} />
      </div>

      <div className={`${errorClass(errors.currency)}`}>
        <CurrencySelect
          id='currency'
          name='currency'
          value={publisher.currency}
          onChange={(selectedValue) => updatePublisherProperties('currency', selectedValue)}
          hasError={errors.currency.length > 0}
        />
        <ErrorFieldBox errors={errors.currency} />
      </div>

      <div className={`${errorClass(errors.language)}`}>
        <Select
          id='language'
          name='language'
          value={publisher.language}
          options={[{ value: 'en', label: 'English' }]}
          placeholder='Language'
          onChange={(selectedValue) => updatePublisherProperties('language', selectedValue)}
        />
        <ErrorFieldBox errors={errors.language} />
      </div>

      <div className={`${errorClass(errors.email)}`}>
        <Input
          type='email'
          id='email'
          name='email'
          value={publisher.email}
          label='Publisher manager email'
          onChange={(value) => updatePublisherProperties('email', value)}
        />
        <ErrorFieldBox errors={errors.email} />
      </div>

      <div className={`${errorClass(errors.crmId)}`}>
        <Input
          type='text'
          id='crm_id'
          name='crmId'
          value={String(publisher.crmId ?? '')}
          label='CRM ID'
          onChange={(value) => {
            if (/^\d*$/.test(value) && parseInt(value || '0') < 2**53) {
              updatePublisherProperties('crmId', value ? parseInt(value) : null)
            }
          }}
        />
        <ErrorFieldBox errors={errors.crmId} />
      </div>
    </div>
  )
}

export default PublisherForm
