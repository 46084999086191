import React, { useContext, useState, useEffect } from 'react'
import axios, { AxiosError } from 'axios'
import _ from 'lodash'

import { TabViewProps, DomainErrors, PublisherTabIndex, PublisherDomain, PublisherErrors } from 'javascripts/components/Publisher/types'
import CurrentProfileContext, { CurrentProfile } from 'javascripts/components/CurrentProfile'
import { useSimpleLoader } from 'javascripts/components/SimpleLoader'
import { useToastNotifier, NotificationType } from 'javascripts/components/ToastNotifier'
import * as PublisherService from 'javascripts/services/PublisherService'

const publisherDefaultErrors: PublisherErrors = {
  name: [],
  currency: [],
  language: [],
  email: [],
  crmId: [],
}

const LOADER_TIMEOUT = 500

const PublisherDataContainer: React.FC<TabViewProps> = ({
  publisher,
  publisherId,
  publisherCrmId,
  updatePublisherProperties,
  onGetPublisher,
  updateDomains,
  onSetSelectedTab,
  children,
}) => {
  const currentProfileCtx = useContext<Partial<CurrentProfile>>(CurrentProfileContext)
  const [errors, setErrors] = useState(publisherDefaultErrors)
  const [domainErrors, setDomainErrors] = useState<Partial<DomainErrors>>()

  const { isLoading, setIsLoading, SimpleLoader } = useSimpleLoader(false)
  const { Notifier, createNotifier } = useToastNotifier()
  const publisherEndpoint = `/panel/${currentProfileCtx.id}/publishers`

  useEffect(() => {
    if (publisherId) {
      setIsLoading(true)
      onGetPublisher(publisherId).finally(() => setIsLoading(false, LOADER_TIMEOUT))
    }
  }, [publisherId])

  const goToInventoryTab = async () => {
    const isFormValid = await validForm()
    if (isFormValid) onSetSelectedTab(PublisherTabIndex.INVENTORY_TAB)
  }

  const validForm = async (): Promise<boolean> => {
    const pub = {...publisher}
    if (publisherCrmId === pub.crmId) pub.crmId = null

    const err: PublisherErrors = await PublisherService.validatePublisher(currentProfileCtx.id, pub)
      .then(() => [])
      .catch((error) => error.response.data.errors?.publisher ?? [])
      .then((publisherErrors) => Object.assign({}, ...publisherErrors))
      .then(PublisherService.convertToPublisherErrors)

    setErrors(err)

    const domainErrors = validDomains(publisher.domains)
    setDomainErrors(domainErrors)

    return _(err).every(_.isEmpty) && isEmpty(domainErrors)
  }

  const validDomains = (domains: Array<PublisherDomain>): DomainErrors => {
    return domains
      .filter((domain) => domain.domainName === undefined || domain.adminCost === undefined)
      .reduce((errors, domain) => {
        const error = {
          [domain.uuid]: {
            domainName: validDomainField("can't be blank", domain.domainName),
            adminCost: validDomainField('incorrect admin cost', domain.adminCost),
          },
        }
        return Object.assign({}, errors, error)
      }, {})
  }

  const isEmpty = (obj) => [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length

  const validDomainField = (error: string, field?: string | number) => (field === undefined ? [error] : undefined)

  const save = async () => {
    if (await validForm()) {
      setIsLoading(true)
      axios
        .all([PublisherService.updatePublisherData(publisher), PublisherService.updateDomains(publisher.domains)])
        .then(() => onGetPublisher(publisher.id))
        .then(() => createNotifier('Publisher data updated!', NotificationType.SUCCESS))
        .catch(updatePublisherDataErrorHandler)
        .finally(() => setIsLoading(false, LOADER_TIMEOUT))
    }
  }

  const updatePublisherDataErrorHandler = (error: AxiosError) =>
    createNotifier(createErrorMessage(error.response.data.errors), NotificationType.ERROR)

  const createErrorMessage = (errors: { [key: string]: Array<{ [key: string]: string[] }> }) =>
    Object.entries(errors).map(([key, value]) => `${key}: ${value}`).join('\n')

  return (
    <>
      {React.cloneElement(children as React.ReactElement, {
        save,
        goToInventoryTab,
        publisher,
        publisherId,
        publisherEndpoint,
        updatePublisherProperties,
        updateDomains,
        errors,
        domainErrors,
        isLoading,
        SimpleLoader,
      })}

      {Notifier}
    </>
  )
}

export default PublisherDataContainer
