export class Switcher {
  constructor (containerSelector) {
    this.containerSelector = containerSelector;
    this.isActive = this._isSwitcherElementActive();
  }

  run () {
    $(this.containerSelector).find('.switcher').on('click', this._toggleSwitcherValue.bind(this));
    this._updateSwitcherElementState();
  }

  onSwitch (callback) {
    $(this.containerSelector).find('input').on('change', event => {
      callback(event.target.value === 'active');
    });

    return this;
  }

  _isSwitcherElementActive () {
    return $(this.containerSelector).find('input').prop('value') === 'active';
  }

  _toggleSwitcherValue () {
    this.isActive = !this.isActive;
    this._updateSwitcherElementState();
  }

  _updateSwitcherElementState () {
    $(this.containerSelector).find('.switcher__switch').toggleClass('switcher__switch--active', this.isActive);
    this._setInput(this.isActive ? 'active' : 'inactive');
  }

  _setInput (value) {
    $(this.containerSelector).find('input').prop('value', value).trigger('change');
  }
}
