import React from 'react';
import { ErrorFieldBox, errorClass } from '../../components/forms/ErrorFieldBox';
import { StepBar } from '../../components/registration/StepBar';
import Input from '../../components/forms/Input';
import * as inputValidators from '../../utlis/inputValidators';

interface Props {
  onPrevStep: () => void
  handleInputChange: (name: InputNames, value: string) => void
  sendFormData: () => void
  values: InputsValues
}

interface InputsValues {
  companyName: string
  topDomain: string
}

interface State {
  isValid: boolean
  errors: {
    companyName: string[]
  }
}

type InputNames = 'companyName' | 'topDomain';

export default class FormAccountDetails extends React.Component<Props, State> {
  private getInitialState = () => {
    const initialState: State = {
      isValid: true,
      errors: {
        companyName: []
      }
    };
    return initialState;
  };
  state = this.getInitialState();

  private previous = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    this.props.onPrevStep();
  };

  private continue = (event: React.MouseEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (this.validateForm()) {
      this.props.sendFormData();
    }
  };

  private validateForm = () => {
    const { values } = this.props;
    const newState = this.getInitialState();

    inputValidators.presenceValidation('companyName', values.companyName, newState.errors.companyName);
    inputValidators.setStateValidation(newState);

    this.setState(newState)
    return newState.isValid;
  }

  render () {
    const { values } = this.props
    const { errors } = this.state

    return (
      <React.Fragment>
        <div className="registration__header">
          <h2 className="registration__title">Account details</h2>
          <small className="registration__description">
            Please tell us more about yourself.<br></br>It will help us to tailor your user
            experience.
          </small>
        </div>
        <div className="form__body form__body--full-width flex-grow-1 mt-5">
          <div className={errorClass(errors.companyName)}>
            <Input
              id="user_company_name"
              type="text"
              label="Name of your company"
              value={values.companyName}
              onChange={value => {
                this.props.handleInputChange('companyName', value);
              }}
            />
            <ErrorFieldBox errors={errors.companyName} />
          </div>
          <Input
            id="user_top_domain"
            type="text"
            label="Your Top domain"
            value={values.topDomain}
            onChange={value => {
              this.props.handleInputChange('topDomain', value);
            }}
          />
        </div>
        <div className="multi-steps multi-steps--2">
          <StepBar step={2} />
          <div className="form__buttons form__buttons--space-beetwen">
            <button className="button" onClick={this.previous}>
              Back
            </button>
            <input className="button" type="submit" value="Finish" onClick={this.continue} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
