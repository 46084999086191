import React from 'react'
import ReactDOM from 'react-dom'

export default class DomHelper {
  static appendClass = (el: HTMLElement, className: string) => {
    if (!el.classList.contains(className)) {
      el.classList.add(className)
    }
  }

  static removeClass = (el: HTMLElement, className: string) => {
    if (el.classList.contains(className)) {
      el.classList.remove(className)
    }
  }

  static toggleClass = (el: HTMLElement, className: string) => {
    el.classList.toggle(className)
  }

  static injectReactComponent = (
    component: React.FunctionComponent,
    props: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    container?: HTMLElement
  ) => {
    ReactDOM.render(
      React.createElement(component, props),
      container ?? document.body.appendChild(document.createElement('div'))
    )
  }
}
