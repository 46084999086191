import { SspConfiguration } from '../types'

import AdFormLogo from 'images/adform_logo.png'
import AdFormSmallLogo from 'images/adform_small_logo.png'

const adformConfig: SspConfiguration = {
  platform: 'adform',
  platformName: 'Adform',
  logo: AdFormLogo,
  smallLogo: AdFormSmallLogo,
}

export default adformConfig
