import { ChartStandardizer } from '../helpers/chart_standardizer'

export class BaseChartTooltip {
  constructor(chart) {
    this.chart = chart
    this.cssClassName = 'interactive-chart-tooltip'
  }

  create() {
    $(`#${this.chart.el.id} .interactive-chart-tooltip`).remove()
    this.el = d3.select(this.chart.el).append('div').attr('class', this.cssClassName).style('display', 'none')
  }

  show(d) {
    this.el
      .html(this._getMessage(d))
      .style('display', 'block')
      .style('text-align', 'center')
      .style('left', this._getLeftValue(d))
      .style('top', this.chart.y(this._getAnchor(d)) + this.chart.margin.top - 20 - this.el.node().offsetHeight + 'px')
  }

  hide() {
    this.el.style('display', 'none')
  }

  _getMessage(_) {
    throw new Error('Not implemented')
  }

  _getLeftValue(data) {
    const leftMargin = this.chart.margin.left + (this.chart.helperScale.step() - this.el.node().clientWidth) / 2
    return Math.floor(this.chart.helperScale(data.id) + leftMargin - 2) + 'px'
  }

  _getAnchor(_) {
    throw new Error('Not implemented')
  }

  _getUnit() {
    return ChartStandardizer.getUnit(this.chart.unit)
  }

  _getPrettyValueWithUnit(value) {
    const unit = this._getUnit()

    if (unit !== '%' && unit !== '' && typeof (value) === 'number') { value = value.toFixed(2) }
    return [ChartStandardizer.getPrettyValue(value), unit].join(unit === '%' ? '' : ' ')
  }

  _getDateHeaderWith(string) {
    return this._createTag('p', this._formatDateFrom(string), 'date font-bold')
  }

  _getPrettyScaledValue(value, round = false) {
    return ChartStandardizer.getPrettyValue(ChartStandardizer.getScaledValue(value, round))
  }

  _formatDateFrom(string) {
    return [this._getDayFrom(string), this._getShortNameMonth(string)].join(' ')
  }

  _getDayFrom(string) {
    return new Date(string).getDate().toString().padStart(2, '0')
  }

  _getShortNameMonth(string) {
    return BaseChartTooltip.MONTH_SHORT_NAME[new Date(string).getMonth()]
  }

  _getPercentageValue(value, sum) {
    return sum === 0 ? 0 : Math.round((value / sum) * 100)
  }

  _createTag(name, content, className = '') {
    const tagClass = className.length > 0 ? [' class="', className, '"'].join('') : ''
    return ['<', name, tagClass, '>', content, '</', name, '>'].join('')
  }

  getTooltipRowsWithColors(records) {
    return records
      .map((record) => ({ ...record, value: this._getPrettyValueWithUnit(record.value) }))
      .reduce((rows, record) => rows + this._recordToTooltipRow(record, this._getClassNameFor(record.type)), '')
  }

  getTooltipRows(records) {
    return records.reduce((rows, record) => {
      const className = record.type === 'Sum' ? 'summary-row' : ''
      return rows + this._recordToTooltipRow(record, className)
    }, '')
  }

  _getRecordsWithUnit(records) {
    return records.map((record) => {
      return { ...record, value: this._getPrettyValueWithUnit(record.value) }
    })
  }

  _recordToTooltipRow(record, cssClass) {
    const valueElement = this._createTag('span', record.value, 'value-right')
    return this._createTag('p', record.type + valueElement, cssClass)
  }
}

BaseChartTooltip.MONTH_SHORT_NAME = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
