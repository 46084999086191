import React from 'react'
import Select from '../Select'
import CurrencyCodes from './CurrencyCodes'

const valueUSD = {
  value: 'USD',
  label: 'USD',
}

const CurrencySelect: React.FC<any> = props => {
  const defaultValue = props.defaultValue ? props.defaultValue : valueUSD

  return <Select {...props} options={CurrencyCodes} defaultValue={defaultValue} />
}

export default CurrencySelect
