import React, { useState } from 'react'

import { Publisher } from 'javascripts/components/Publisher/types'
import Input from 'javascripts/components/forms/Input'

import { errorClass, ErrorFieldBox } from 'javascripts/components/forms/ErrorFieldBox'
import SearchInput from 'javascripts/components/forms/SearchInput'
import Button, { ButtonTheme } from 'javascripts/components/Button'

interface Props {
  errors: string[]
  publisher: Publisher
  updatePublisherProperties: Function
  bulkUpdateAdUnits: Function
  searchQuery: string
  setSearchQuery: Function
}

const PublisherFinancesSearch: React.FC<Props> = ({ errors, publisher, updatePublisherProperties, bulkUpdateAdUnits, searchQuery, setSearchQuery }) => {
  const [adminCost, setAdminCost] = useState(0)

  const changeHandler = () => {
    bulkUpdateAdUnits(adminCost)
    setAdminCost(0)
  }

  return (
    <div className='publisher__finances'>
      <div className='publisher-margin'>
        <div className={`${errorClass(errors)}`}>
          <h3>Margin</h3>

          <div className='publisher-margin__input-container'>
            <Input
              type='number'
              id='margin'
              name='margin'
              value={publisher.margin}
              onChange={value => updatePublisherProperties('margin', value)}
              inputClassName='publisher-margin__input'
              selectOnFocus={true}
              disableScroll={true}
              disableAutoComplete={true}
            />
            <span className='publisher-margin__input-suffix'>%</span>
          </div>

          <ErrorFieldBox errors={errors} />
        </div>
      </div>

      <div className='publisher__finances-search__container'>
        <div className='search-container'>
          <h3>Search adunits</h3>

          <SearchInput
            id='ad-unit-filter-input'
            name='ad-unit-filter-input'
            value={searchQuery}
            onChange={value => setSearchQuery(value)}
            placeholder='Search'
            className='search-input-container'
          />
        </div>

        <div className='ad-unit-container'>
          <h3>Admin cost</h3>

          <div className='publisher-margin__input-container'>
            <Input
              type='number'
              id='margin'
              name='margin'
              value={adminCost}
              onChange={value => setAdminCost(parseInt(value))}
              inputClassName='publisher-margin__input'
              selectOnFocus={true}
              disableScroll={true}
              disableAutoComplete={true}
            />
            <span className='publisher-margin__input-suffix'>%</span>
          </div>
        </div>

        <Button theme={ButtonTheme.Green} className='change-btn' onClickHandler={changeHandler}>Change</Button>
      </div>
    </div>
  )
}

export default PublisherFinancesSearch
