import { BaseChartTooltip } from './_base_chart_tooltip'

export class AreaChartTooltip extends BaseChartTooltip {
  _getMessage({ data, label }) {
    if (this.chart.revenueChartTooltip) {
      return [
        this._getDateHeaderWith(label),
        this.getTooltipRows([this._getDataWithTotalRevenueValue(data), this._getDataWithUpliftValue(data)]),
      ].join('')
    }

    let records
    if (this.chart.sumTitle) {
      records = this._getRecordsWithSum(data)
    } else {
      records = this._getRecords(data)
    }

    return [this._getDateHeaderWith(label), this._createTag('p', this.getTooltipRows(records.reverse()))].join('')
  }

  _getAnchor({ data }) {
    return data.reduce((sum, record) => record.value + sum, 0)
  }

  _getRecords(data) {
    if (this.chart.tooltipValuesWithPercent) {
      return this._getRecordsWithPercentage(data)
    } else if (this.chart.revenueChartTooltip) {
      return this._getRecordWithUpliftValue()
    } else {
      return this._getRecordsWithUnit(data)
    }
  }

  _sumValuesByProperty(records, propertyName) {
    return records.reduce((sum, record) => sum + record[propertyName], 0)
  }

  _sumRecordValues(records) {
    return this._sumValuesByProperty(records, 'value')
  }

  _sumRecordPercentValues(records) {
    const value = this._sumValuesByProperty(records, 'percentValue')
    return `(${value}%)`
  }

  _getRecordsWithSum(records) {
    return [this._getSumTitle(records), ...this._getRecordsWithPercentage(records)]
  }

  _getSumTitle(records) {
    const recordsData = this._calculateRecordsValues(records)
    const value = this._getPrettyValueWithUnit(this._sumRecordValues(recordsData))
    const percentSum = this._sumRecordPercentValues(recordsData)

    return {
      type: this.chart.sumTitle,
      value: `${value} ${percentSum}`,
    }
  }

  _getRecordsWithPercentage(records) {
    return this._calculateRecordsValues(records).map((record) => ({
      ...record,
      value: `${this._getPrettyValueWithUnit(record.value)} (${record.percentValue}%)`,
    }))
  }

  _calculateRecordsValues(records) {
    const valuesSum = this._sumRecordValues(records)
    let percentSum = 0

    return records.map((record, index) => {
      let percentValue
      if (valuesSum === 0) {
        percentValue = 0
      } else if (index === records.length - 1) {
        percentValue = 100 - percentSum
      } else {
        percentValue = this._getPercentageValue(record.value, valuesSum)
      }
      percentSum += percentValue
      return {
        ...record,
        percentValue,
      }
    })
  }

  _getDataWithPercentage(data, sum) {
    let percentSum = 0

    return data.reduceRight((message, record, index) => {
      const percentValue = index === 0 ? 100 - percentSum : this._getPercentageValue(record.value, sum)
      percentSum += percentValue
      return (
        message +
        `${record.type} <span class="value-right">${this._getPrettyValueWithUnit(
          record.value
        )} (${percentValue}%)</span><br />`
      )
    }, '')
  }

  _getDataWithTotalRevenueValue(data) {
    const totalRevenueValue = data[1].value + data[0].value
    return { type: 'Revenue', value: this._getPrettyValueWithUnit(totalRevenueValue) }
  }

  _getDataWithUpliftValue(data) {
    const revenueUpliftValue = data[1].value
    const percentageChange = this._getPercentageValue(data[1].value, data[0].value)
    const colorClass = percentageChange >= 0 ? 'value-change-positive' : 'value-change-negative'

    return {
      type: 'Uplift',
      value: `<span class="${colorClass}">+${percentageChange}%</span> (${this._getPrettyValueWithUnit(
        revenueUpliftValue
      )})`,
    }
  }
}
