import React from 'react'
import { SelectOption } from '../types'

interface Props {
  key?: string
  value: SelectOption
  onRemove: (value: string) => void
}

const SelectedValue: React.FC<Props> = ({ value, onRemove }) => {
  return (
    <div id={value.value} className='selected-value'>
      {value.label}
      <span className='selected-value--remove' onClick={() => onRemove(value.value)}>
        x
      </span>
    </div>
  )
}

export default SelectedValue
