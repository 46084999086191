import axios from 'axios'

export const getWebsitePerformance = (profileId: number, date: string, applicableData: string) => {
  const url = `${profileId}/domains_performance`
  const params = {
    'overview_performance_form[date]': date,
    'overview_performance_form[applicable_data]': applicableData,
  }

  return axios({
    method: 'get', url, params
  })
}
