import { ResearchEditHandler } from './_research_edit_handler';
import { ResearchForm } from './research_form';

export class ResearchCancelEditHandler extends ResearchEditHandler {
  constructor (uuid, researchForm) {
    super(uuid, researchForm);

    this.cancelEdit = '.research__cancel-edit-btn';
    this.researchData = '.research-data';
  }

  create () {
    $(this._getScopedSelector(this.cancelEdit)).on('click', e => {
      e.preventDefault();

      this._toggleComponentsAvailability(false);
      this._removeContainerOrRejectFormChanges();
    });
  }

  _removeContainerOrRejectFormChanges () {
    if (this._noResearchDataPresent()) {
      const currentContainer = $(this._getReportContainerSelector());
      const referenceContainer = currentContainer.prev(this.container);

      currentContainer.remove();
      this._scrollToContainerTop(referenceContainer);
    } else {
      this._retrievePreviousFormState();
      this._scrollToContainerTop();
    }
  }

  _noResearchDataPresent () {
    return $(this._getScopedSelector(this.researchData)).get(0) === undefined;
  }

  _retrievePreviousFormState () {
    $(this._getScopedSelector(this.formSelector)).replaceWith(this._getPreviousFormBody());
    new ResearchForm(this.uuid).create();
  }

  _getPreviousFormBody () {
    return $(this._getReportContainerSelector()).data('current-form');
  }
}
