import React from 'react'
import Box from 'javascripts/components/Box'
import PublisherListItem from 'javascripts/components/Publisher/PublisherListItem'
import { PublisherData } from 'javascripts/components/Publisher/types'

interface Props {
  publishers: Array<PublisherData>
}

const PublisherListContainer: React.FC<Props> = ({ publishers }) => {

  const sortByIdAsc = (pub1: PublisherData, pub2: PublisherData) => pub2.publisher_id - pub1.publisher_id
  const getPublishers = (publishers: Array<PublisherData>) => [...publishers].sort(sortByIdAsc)

  return (
    <Box>
      <header className='box__header'>
        <h3 className='box__title'>List of Publishers</h3>
      </header>
      <hr className='line-separator' />
      <div className='publisher-list__header'>
        <div className='publisher-list__cell'>ID</div>
        <div className='publisher-list__cell'>CRM ID</div>
        <div className='publisher-list__cell'>Client</div>
        <div className='publisher-list__cell'>Revenue</div>
        <div className='publisher-list__cell'>Ad Impressions</div>
        <div className='publisher-list__cell'>Tech<br />Cost</div>
        <div className='publisher-list__cell'>Net<br />Revenue</div>
        <div className='publisher-list__cell'>Admin<br />Cost</div>
        <div className='publisher-list__cell'>Margin</div>
        <div className='publisher-list__cell'>Earnings</div>
        <div className='publisher-list__cell'>Currency</div>
        <div className='publisher-list__cell'>{/* Empty cell for EditButton in rows */}</div>
      </div>
      <div>
        {getPublishers(publishers).map((publisher, index) => (
          <PublisherListItem key={index} index={index} publisher={publisher} />
        ))}
      </div>
    </Box>
  )
}

export default PublisherListContainer
