import React, { ReactType, useMemo } from 'react'

import { Publisher, DomainErrors } from 'javascripts/components/Publisher/types'
import Button, { ButtonTheme } from 'javascripts/components/Button'
import PublisherForm from 'javascripts/components/Publisher/PublisherForm'
import PublisherDomainsForm from 'javascripts/components/Publisher/PublisherDomainsForm'

interface Props {
  save: Function
  goToInventoryTab: Function
  publisher: Publisher
  publisherId: number
  publisherEndpoint: string
  updatePublisherProperties: Function
  updateDomains: Function
  errors: any
  domainErrors: Partial<DomainErrors>
  isLoading: boolean
  SimpleLoader: ReactType
}

const PublisherWrapperForm: React.FC<Partial<Props>> = ({
  save,
  goToInventoryTab,
  publisher,
  publisherId,
  publisherEndpoint,
  updatePublisherProperties,
  updateDomains,
  errors,
  domainErrors,
  isLoading,
  SimpleLoader,
}) => {
  const saveHandler = () => (publisherId ? save : goToInventoryTab)
  const saveLabel = useMemo(() => (publisherId ? 'Save' : 'Next'), [])

  return (
    <div className='tab-view'>
      <div className='publisher__header'>
        <h3 className='publisher__title publisher__title--main'>Create publisher's profile</h3>
        <div className='publisher__title-container'>
          <h3 className='publisher__title'>Publisher's domains</h3>
          <span className='publisher__subtitle'>(for automated matching ad units)</span>
        </div>
      </div>

      {isLoading ? (
        <SimpleLoader />
      ) : (
        <>
          <div className='publisher__form'>
            <PublisherForm
              errors={errors}
              publisher={publisher}
              updatePublisherProperties={updatePublisherProperties}
            />

            <PublisherDomainsForm domainErrors={domainErrors} publisher={publisher} updateDomains={updateDomains} />
          </div>

          <footer className='tab-view__footer'>
            <Button theme={ButtonTheme.GreenOutline} href={publisherEndpoint}>
              Back
            </Button>
            <Button theme={ButtonTheme.Green} onClickHandler={saveHandler()}>
              {saveLabel}
            </Button>
          </footer>
        </>
      )}
    </div>
  )
}

export default PublisherWrapperForm
