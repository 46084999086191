import React from 'react'

import { sspConfiguration } from './config/sspConfiguration'
import { SspLogoProps } from './types'

const SspLogo: React.FC<SspLogoProps> = ({ platformName, small, className }) => {
  const platform = sspConfiguration[platformName]
  const getLogo = () => (small ? platform.smallLogo : platform.logo)

  return <img className={`ssp-logo ${className}`} src={getLogo()} alt={`${platformName} logo`} />
}

SspLogo.displayName = 'SspLogo'

export default React.memo(SspLogo)
