import { SspConfiguration } from '../types'

import ConnectAdLogo from 'images/connect_ad_logo.png'
import ConnectAdSmallLogo from 'images/connect_ad_small_logo.png'

const connectAdConfig: SspConfiguration = {
  platform: 'connect_ad',
  platformName: 'Connect Ad',
  logo: ConnectAdLogo,
  smallLogo: ConnectAdSmallLogo,
}

export default connectAdConfig
