export class AdxComparisonMethodHandler {
  constructor () {
    this.tableComparisonSelect = '#adx_performance_change_comparison_method';
    this.adxHiddenField = '#adx_performance_form_comparison_value';
    this.tableChangeValuesFields = '.adx-performance-table__value-change';
  }

  run () {
    this._setComparisonMethodSelectChangeEvent();
  }

  _setComparisonMethodSelectChangeEvent () {
    $(this.tableComparisonSelect).on('change', this._comparisonMethodSelectChangeCallback.bind(this));
  }

  _comparisonMethodSelectChangeCallback () {
    this._toggleColumnsVisibility();
    this._setAdxHiddenFieldValue();
  }

  _toggleColumnsVisibility () {
    $(this.tableChangeValuesFields).toggle();
  }

  _setAdxHiddenFieldValue () {
    $(this.adxHiddenField).val($(this.tableComparisonSelect).val());
  }
}
