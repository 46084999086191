import { SspConfiguration } from '../types'

import OpenXLogo from 'images/open_x_logo.png'
import OpenXSmallLogo from 'images/open_x_small_logo.png'

const openXConfig: SspConfiguration = {
  platform: 'open_x',
  platformName: 'OpenX',
  logo: OpenXLogo,
  smallLogo: OpenXSmallLogo,
}

export default openXConfig
