import React from "react";
import logoDark from "images/logo-dark-new.svg";
import gmailIcon from "images/gmail-icon.svg";
import outlookIcon from "images/outlook-icon.svg"
import yahooIcon from "images/yahoo-icon.svg"

export const ConfirmationInfo: React.FunctionComponent = () => {
  return (
    <div className="registration registration--background-image-confirmation-info">
      <div className="registration__container registration__container--column">
        <img className="registration__logo" src={logoDark} alt="Insight Yieldbird"/>
        <div className="registration__confirmation-info">
          <div className="registration__header">
            <h2 className="registration__title">Please verify your email</h2>
            <small className="registration__description">Once you verify your email address, you and your <br />team can get started in Yieldbird Insights.</small>
          </div>
          <ul className="confirmation-info__email-buttons email-buttons-list">
            <li className="mr-3">
              <a className="email-button" href="https://gmail.com/" target="_blank" rel="noopener noreferrer">
                <img className="email-button__icon" src={gmailIcon} />
                <span>Open Gmail</span>
              </a>
            </li>
            <li className="mr-3">
              <a className="email-button" href="https://outlook.live.com/" target="_blank" rel="noopener noreferrer">
                <img className="email-button__icon" src={outlookIcon} />
                <span>Open Outlook</span>
              </a>
            </li>
            <li className="mr-3">
              <a className="email-button" href="https://mail.yahoo.com/" target="_blank" rel="noopener noreferrer">
                <img className="email-button__icon" src={yahooIcon} />
                <span>Open Yahoo!</span>
              </a>
            </li>
          </ul>
          <p className="registration__description">Didn't receive email? Check your spam or try again.</p>
        </div>
        <div className="registration__footer">
          <p>Already have an account? <a className="footer__link" href="/users/sign_in">Sign in here</a></p>
        </div>
      </div>
    </div>
  );
}
