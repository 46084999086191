import axios from 'axios'
import getCsrfToken from 'javascripts/utlis/getCsrfToken'
import { IdentifierType, User } from 'javascripts/components/UserSettings/types'

class UserSettingsService {
  addNetworkIdentifier = (identifierName: string, identifierType: IdentifierType) => {
    const url = 'settings/identifiers'
    const data = {
      authenticity_token: getCsrfToken(),
      name: identifierName,
      identifier_type: identifierType,
    }
    return axios.post(url, data)
  }

  removeNetworkIdentifier = (identifierId: number) => {
    const url = `settings/identifiers/${identifierId}`
    const data = {
      authenticity_token: getCsrfToken(),
      identifier_deletion: {
        confirmation: 'CONFIRM',
      },
    }
    return axios.delete(url, { data })
  }

  displayUserRole = (user: User) => {
    const url = `settings/display_role`
    const data = {
      user: user,
    }
    return axios.get(url, { params: data })
  }
}

export default UserSettingsService
