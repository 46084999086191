export class AjaxErrorsHandler {
  constructor (container, caller) {
    this.container = container;
    this.caller = caller;
    this.ajaxErrorTemplate = "script#ajax_error_template[type='text/template']";
    this.ajaxErrorRefresh = '.ajax-error-refresh';
  }

  run () {
    this._showErrorInfo();
    this._setOneTimeRefreshClickEvent();
  }

  _showErrorInfo () {
    $(this.container).html($(this.ajaxErrorTemplate).html());
  }

  _setOneTimeRefreshClickEvent () {
    $(this.container).find(this.ajaxErrorRefresh).one('click', e => {
      e.preventDefault();
      this._retryFailedRequest();
    });
  }

  _retryFailedRequest () {
    $(this.caller).trigger('change');
  }
}
