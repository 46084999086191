import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import axios from 'axios'
import Input from 'javascripts/components/forms/Input'
import YieldbirdLogo from 'images/logo-dark.svg'
import Button, { ButtonTheme } from 'javascripts/components/Button'
import {
  ProfileModalProps,
  DeleteAccountValues,
  DeleteAccountErrors,
} from 'javascripts/components/Profile/types'
import {
  deleteAccountsInits,
  deleteAccountsErrorsInits,
} from 'javascripts/components/Profile/initials'
import getCsrfToken from 'javascripts/utlis/getCsrfToken'
import { ErrorFieldBox } from 'javascripts/components/forms/ErrorFieldBox'
import { HttpStatusCode, redirect } from 'javascripts/components/Profile/httpHelper'

const DeleteAccount: React.FC<ProfileModalProps> = ({ endpoints, shouldOpen, onCloseHandler }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [values, setValues] = useState<DeleteAccountValues>(deleteAccountsInits)
  const [errors, setErrors] = useState<DeleteAccountErrors>(deleteAccountsErrorsInits)

  useEffect(() => {
    setOpen(shouldOpen)
  }, [shouldOpen])

  const closeModal = () => {
    onCloseHandler()
    setValues(deleteAccountsInits)
    setErrors(deleteAccountsErrorsInits)
  }

  const submitForm = () => {
    setLoading(true)

    axios
      .delete(endpoints.profile, {
        data: {
          authenticity_token: getCsrfToken(),
          account_deletion_form: { confirmation: values.confirmation },
        },
      })
      .then(response => {
        if (response.status === HttpStatusCode.OK) {
          redirect(endpoints.signIn)
        } else if (response.status === HttpStatusCode.VALIDATION_ERROR) {
          setLoading(false)
          const errors = response.data.errors

          setErrors({
            confirmation: errors.confirmation,
          })
        }
      })
  }

  return (
    <Modal isOpen={open} onRequestClose={closeModal} ariaHideApp={false} className='Modal'>
      <div className='profile-modal__container'>
        <img className='profile-modal__logo' src={YieldbirdLogo} alt='Yieldbird Insights'></img>

        <div className='profile-modal__title'>Do you want to delete your account?</div>

        <div className='profile-modal__inputs'>
          <div className='profile__form--input-container'>
            <Input
              id='confirmation'
              type='text'
              label='Please type CONFIRM'
              value={values.confirmation}
              onChange={value => setValues({ ...values, confirmation: value })}
            ></Input>
            {errors.confirmation && <ErrorFieldBox errors={errors.confirmation}></ErrorFieldBox>}
          </div>
        </div>

        <div className='profile-modal__buttons'>
          <Button theme={ButtonTheme.GreenOutline} onClickHandler={closeModal}>
            Cancel
          </Button>

          <Button
            theme={ButtonTheme.Green}
            onClickHandler={() => submitForm()}
            disabled={loading || !values.confirmation}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteAccount
