import React, { Fragment } from "react";

interface NetworksProps {
  networks: string[][]
  template: React.ReactType
}

export const Networks: React.FunctionComponent<NetworksProps> = React.memo((props) => (
  <Fragment>
    {
      props.networks.map(network =>
        <props.template network={network} key={ network[0] } />
      )
    }
  </Fragment>
))

Networks.displayName = "Networks";
