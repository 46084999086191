export class BaseTimelineChartModel {
  constructor (rawData) {
    this.rawData = rawData;
    this._assignIds();
  }

  getXAxisDomain () {
    return this.getBlockDomain().map(data => data.id);
  }

  getYAxisDomain () {
    return [0, this._getMaxYValue()];
  }

  getBlockDomain () {
    if (typeof this.blockDomainData === 'undefined') {
      const labels = this._getBlockDomainLabels();
      this.blockDomainData = Object.keys(labels).map((key, index) => ({ label: key, data: labels[key], id: index }));
    }

    return this.blockDomainData;
  }

  getLineDomain () {
    return this.rawData;
  }

  _getMaxYValue () {
    throw new Error('Not implemented');
  }

  _assignIds () {
    this.rawData.forEach((d, i) => {
      d.id = i;
      d.data.forEach((elem, j) => { elem.id = j; });
    });
  }

  _getBlockDomainLabels () {
    const labels = {};

    this.rawData.forEach(elem => {
      elem.data.forEach(d => {
        if (labels[d.label] === undefined) labels[d.label] = [];
        labels[d.label].push({ value: d.value, type: elem.type });
      });
    });

    return labels;
  }
}
