const placeholder = (styles: object) => {
  return {
    ...styles,
    cursor: 'text',
    padding: '0 8px',
    fontSize: '14px',
    marginRight: '0',
    marginLeft: '0',
  }
}

export default placeholder
