export class ChartStandardizer {
  static getPrettyValue(value) {
    return value === undefined || value === null ? value : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  static getUnit(unit) {
    return unit === undefined || unit === null ? '' : unit;
  }

  static getScaledValue(value, round = false) {
    return value >= 1000000 ? `${Math.round(value / 1000)}k` : (round ? Math.round(value) : value);
  }
}
