import React, { useContext, useState } from 'react'
import axios, { AxiosResponse, AxiosError } from 'axios'

import Box from 'javascripts/components/Box'
import InvitationsForm from 'javascripts/components/Invitations/InvitationsForm'
import InvitationSrc from 'images/invitations.png'
import CurrentProfileContext, { CurrentProfile } from 'javascripts/components/CurrentProfile'
import getCsrfToken from 'javascripts/utlis/getCsrfToken'
import { NotificationType, useToastNotifier } from 'javascripts/components/ToastNotifier'

const InvitationsContainer: React.FC = () => {
  const currentProfileCtx = useContext<Partial<CurrentProfile>>(CurrentProfileContext)
  const [email, setEmail] = useState('')
  const { Notifier, createNotifier } = useToastNotifier()

  const inviteUser = (email: string) => {
    if (currentProfileCtx.id) {
      axios({
        method: 'post',
        url: `invitations`,
        data: {
          email,
          authenticity_token: getCsrfToken(),
        },
      })
        .then(inviteUserSuccessHandler)
        .catch(invitaeUserErrorHandler)
    }
  }

  const inviteUserSuccessHandler = (response: AxiosResponse) => {
    clearForm()
    createNotifier(response.data.response, NotificationType.INFO)
  }

  const invitaeUserErrorHandler = (error: AxiosError) => {
    createNotifier(error.response.data.response, NotificationType.ERROR)
  }
  const clearForm = () => setEmail('')

  return (
    <Box className='invitations__container'>
      <header className='box__header'>
        <h3 className='box__title'>Invite with e-mail</h3>
      </header>
      <hr className='line-separator' />

      <div className='invitations__content'>
        <h3 className='invitations__content--title'>Let your team join Yieldbird Insights!</h3>
        <p className='invitations__content--text'>
          Within our limited free trial you can invite all your team to use Yieldbird Insights
        </p>
      </div>

      <img src={InvitationSrc} alt='Invitations Image' />

      <InvitationsForm onInviteUser={inviteUser} email={email} setEmail={setEmail} />
      {Notifier}
    </Box>
  )
}

export default InvitationsContainer
