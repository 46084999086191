import React from 'react';
import classnames from 'classnames';

import { DropdownMenuProps } from './types';

const DropdownMenu: React.FC<DropdownMenuProps> = ({ isOpen, children }) => {

  const dropdownMenuClasses = classnames({
    'dropdown__menu': true,
    'show': isOpen
  })

  return (
    <div className={dropdownMenuClasses}>
      { children }
    </div>
  )
};

export default DropdownMenu;
