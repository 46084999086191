import _ from 'lodash';
import { BaseChart } from './_base_chart';
import { ChartDotsHandler } from './helpers/chart_dots_handler';
import { ChartCssClassProvider } from './helpers/chart_css_class_provider';
import { ChartAxisLayerComponent } from './components/chart_axis_layer_component';
import { ChartBlockLayerComponent } from './components/chart_block_layer_component';
import { ChartInteractionComponent } from './components/chart_interaction_component';
import { ChartStandardizer } from './helpers/chart_standardizer';

export class TimelineChart extends BaseChart {
  constructor (element, data, customAttributes) {
    super(element, data, customAttributes);

    this.dotsHandler = new ChartDotsHandler(this);
    this.cssClassProvider = new ChartCssClassProvider();

    this._include(new ChartAxisLayerComponent());
    this._include(new ChartBlockLayerComponent());
    this._include(new ChartInteractionComponent());

    this.monthXAxisLabelIndexes = _(this.dataModel.getBlockDomain())
      .map(({ label }) => new Date(label).getMonth() + 1)
      .map((month, i) => [month, i])
      .groupBy(_.first)
      .map(pairs => pairs.map(_.last))
      .map(indexes => indexes[Math.floor((indexes.length - 1) / 2)]);
  }

  _defaultAttributes () {
    return {
      margin: { top: 0, right: 0, bottom: 40, left: 0 },
      paddingOuter: 0.3,
      paddingInner: 0.6,
      numberOfTicks: 0,
      unit: undefined,
      xAxisWeekDayLabelsMinWidth: 23,
      lineStrokeWidth: '2px',
      tooltipValuesWithChange: false,
    };
  }

  createChart () {
    super.createChart();

    this._prepareAxisLayer();
    this._prepareBlockLayer();
    this._prepareInteraction();

    this._drawShapes([...this.dataModel.getLineDomain(), this._getBackground()], this.lineStrokeWidth);

    return this;
  }

  dataFilterCallback (_) {
    return true;
  }

  _drawShapes (data, strokeWidth, dashSize = 0) {
    data.forEach((elem, index) => {
      this.chartLayer.insert('path', '.bars-layer')
        .data([this._prepareLineData(elem.data)])
        .attr('class', this.cssClassProvider.getClassFor(this.shape, elem.type))
        .style('stroke-width', strokeWidth)
        .style('stroke-dasharray', (dashSize))
        .attr('d', this._getShape(index));
    });
  }

  _getBackground () {
    return {
      type: 'background',
      data: this.dataModel.getLineDomain()[0].data.map(entry => Object.assign({}, entry, { value: 0 }))
    };
  }

  _prepareLineData (data) {
    const lineData = data.map(elem => {
      elem['xValue'] = this.x(elem.id) + this.x.bandwidth() / 2;
      return elem;
    });

    lineData.unshift({ value: data[0].value, xValue: 0 });
    lineData.push({ value: data[data.length - 1].value, xValue: this.chartWidth });

    return lineData;
  }

  _getShape () {
    throw new Error('Not implemented');
  }

  _formatYAxisLabel (value) {
    return ChartStandardizer.getScaledValue(value);
  }

  _formatXAxisLabel (_, index) {
    if (!this._isLabelVisible(index)) return;

    const label = this.dataModel.getBlockDomain()[index].label;
    if (this._useMonthXAxisLabels()) return this._getNameMonth(label);

    return this._getWeekDay(label);
  }

  _isLabelVisible (labelIndex) {
    return !this._useMonthXAxisLabels() || this.monthXAxisLabelIndexes.includes(labelIndex);
  }

  _useMonthXAxisLabels () {
    return this.helperScale.bandwidth() < this.xAxisWeekDayLabelsMinWidth;
  }

  _getWeekDay (date) {
    return TimelineChart.DAYS[new Date(date).getDay()];
  }

  _mouseOver (d) {
    this.dotsHandler.show(d);
    this.chartLayer.selectAll(`.axis-x .tick:nth-of-type(${d.id + 1}) text`).classed('highlight', true);
  }

  _mouseOut () {
    this.dotsHandler.hide();
    this.chartLayer.selectAll('.axis-x text').classed('highlight', false);
  }

  _formatDateFrom (string) {
    return [this._getDayFrom(string), this._getShortNameMonth(string)].join(' ');
  }

  _getDayFrom (string) {
    return new Date(string).getDate().toString().padStart(2, '0');
  }

  _getNameMonth (string) {
    return TimelineChart.MONTHS[new Date(string).getMonth()];
  }

  _getShortNameMonth (string) {
    return this._getNameMonth(string).slice(0, 3);
  }
}
TimelineChart.MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
TimelineChart.DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
