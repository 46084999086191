import { ResearchAddChartHandler } from './research_add_chart_handler'
import { ResearchDeleteChartHandler } from './research_delete_chart_handler'
import { ResearchEditHandler } from './_research_edit_handler'
import { ResearchScopedSelector } from './__research_scoped_selector'
import { ResearchForm } from './research_form'

export class ResearchReport extends ResearchScopedSelector {
  constructor(uuid, isPublisher, isAllPublishersUser, hasSsp) {
    super(uuid)

    this.isPublisher = isPublisher
    this.isAllPublishersUser = isAllPublishersUser
    this.hasSsp = hasSsp
  }

  create() {
    const researchForm = new ResearchForm(this.uuid, this.isPublisher, this.isAllPublishersUser, this.hasSsp).create()

    this._initializeResearchEditHandler(researchForm)
    this._initializeResearchAddChartHandler(researchForm)
    this._initializeResearchDeleteChartHandler()
  }

  _initializeResearchEditHandler(researchForm) {
    new ResearchEditHandler(this.uuid, researchForm).create()
  }

  _initializeResearchAddChartHandler(researchForm) {
    new ResearchAddChartHandler(researchForm, this._getReportContainerSelector()).create()
  }

  _initializeResearchDeleteChartHandler() {
    new ResearchDeleteChartHandler(this.uuid).create()
  }
}
