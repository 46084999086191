import React from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import FormUserDetails from '../containers/registration/FormUserDetails';
import FormAccountDetails from '../containers/registration/FormAccountDetails';
import { Success } from '../containers/registration/Success';
import logoDark from 'images/logo-dark-new.svg';
import getCsrfToken from '../utlis/getCsrfToken';

interface Props {
  invitationToken: string
  email: string
}

interface State {
  currentStep: number
  invitationToken: string
  email: string
  userName: string
  password: string
  passwordConfirmation: string
  companyName: string
  topDomain: string
}

type InputType = 'userName' | 'password' | 'passwordConfirmation' | 'companyName' | 'topDomain';

export class Registration extends React.Component<Props, State> {
  state = {
    currentStep: 1,
    invitationToken: this.props.invitationToken,
    email: this.props.email,
    userName: '',
    companyName: '',
    topDomain: '',
    password: '',
    passwordConfirmation: ''
  };

  nextStep = () => {
    this.setState(previousState => ({ currentStep: previousState.currentStep + 1 }));
  };

  prevStep = () => {
    this.setState(previousState => ({ currentStep: previousState.currentStep - 1 }));
  };

  handleInputChange = (name: InputType, value: string) => {
    this.setState(({ [name]: value } as unknown) as Pick<State, InputType>);
  };

  sendFormData = () => {
    axios({
      method: 'put',
      url: '/users/invitation',
      data: this.getFormData()
    })
      .then(this.sendFormDataSuccessHandler)
      .catch(this.sendFormDataErrorHandler);
  };

  private getFormData = () => {
    return {
      authenticity_token: getCsrfToken(),
      user: {
        invitation_token: this.state.invitationToken,
        email: this.state.email,
        name: this.state.userName,
        password: this.state.password,
        password_confirmation: this.state.passwordConfirmation,
        company_name: this.state.companyName,
        top_domain: this.state.topDomain
      }
    };
  };

  private sendFormDataSuccessHandler = (response: AxiosResponse) => {
    if (response.status === 201) {
      this.nextStep();
    }
  };

  private sendFormDataErrorHandler = (error: AxiosError) => {
    if (error.response) {
      alert(JSON.stringify(error.response.data.errors));
    }
  };

  render () {
    const { currentStep, email, userName, password, passwordConfirmation, companyName, topDomain } = this.state;
    const userDetailsValues = { email, userName, password, passwordConfirmation };
    const accountDetailsValues = { companyName, topDomain };

    return (
      <div className={`registration registration--background-image-step-${currentStep}`}>
        <div className="registration__container registration__container--column">
          <img className="registration__logo" src={logoDark} alt="Insight Yieldbird" />
          <form
            id="registration_form"
            className="registration__form registration__form--width-50 form"
          >
            {(() => {
              switch (currentStep) {
                case 1:
                  return (
                    <FormUserDetails
                      onNextStep={this.nextStep}
                      handleInputChange={this.handleInputChange}
                      values={userDetailsValues}
                    />
                  );
                case 2:
                  return (
                    <FormAccountDetails
                      onPrevStep={this.prevStep}
                      handleInputChange={this.handleInputChange}
                      values={accountDetailsValues}
                      sendFormData={this.sendFormData}
                    />
                  );
                case 3:
                  return <Success />;
                default:
                  return null;
              }
            })()}
          </form>
          <div className="registration__footer">
            <p>
              Already have an account?{' '}
              <a className="footer__link" href="/users/sign_in">
                Sign in here
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
