import React from 'react';

import PlusIcon from 'images/plus-icon.png';

interface Props {
  endpoint: string
}

const NewConnectSspLink: React.FC<Props> = ({ endpoint }) => {
  return (
    <a href={endpoint} className="ssp__link--new-connection">
      <div className="ssp__link--icon">
        <img src={PlusIcon} alt="Add new SSP connection icon" />
      </div>
      <p className="ssp__link--text">Add new SSP connection</p>
    </a>
  )
}

export default React.memo(NewConnectSspLink);
