export class DonutChartLegend {
  constructor (chartId) {
    const legend = $(`#${chartId} + .donut-chart-legend`);

    legend.on('mouseover', event => {
      const arcId = this._getArcId(event)
      this._emitSetItemHighlight({ chartId, arcId, value: true })
    });

    legend.on('mouseout', event => {
      const arcId = this._getArcId(event)
      this._emitSetItemHighlight({ chartId, arcId, value: false })
    });

    legend.on('legend:setItemHighlight', (event, { arcId, value }) => {
      this._getLegendItem(arcId).toggleClass('donut-chart-legend__label--highlighted', value);
    });

    return legend;
  }

  _getArcId ({ target }) {
    return target.id ? target.id : target.parentElement.id;
  }

  _emitSetItemHighlight ({ chartId, arcId, value }) {
    $(`#${chartId}`).trigger('donutChart:setItemHighlight', { arcId, value });
  }

  _getLegendItem (arcId) {
    return $(`.${arcId}-chart-label`.toLowerCase());
  }
}
