import { TimelineChart } from './_timeline_chart'
import { AreaChartModel } from './models/area_chart_model'
import { AreaChartTooltip } from './tooltips/area_chart_tooltip'

export class AreaChart extends TimelineChart {
  constructor(element, data, customAttributes) {
    super(element, data, customAttributes)

    this.shape = 'area'
  }

  _setData(data) {
    this.dataModel = new AreaChartModel(data)
    this.tooltip = new AreaChartTooltip(this)
  }

  _defaultAttributes() {
    return Object.assign({}, super._defaultAttributes(), {
      sumTitle: 'Sum',
      tooltipValuesWithPercent: true,
      revenueChartTooltip: false,
      margin: { top: 50, left: 50, bottom: 40, right: 37 },
      numberOfTicks: 6,
    })
  }

  _getShape(labelIndex) {
    return d3
      .area()
      .x((d) => d.xValue)
      .y0((_, periodIndex) => this._getY0(labelIndex, periodIndex))
      .y1(0)
  }

  _getY0(labelIndex, periodIndex) {
    return labelIndex <= 0 ? this.y(0) : this.y(this.dataModel.getAreaHeightPerPeriod()[labelIndex - 1][periodIndex])
  }
}
