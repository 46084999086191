export interface ToastNotifierProps {
  config: Config
  onRenderFinished?: Function
}

export interface Config {
  type: NotificationType
  message: string
  time?: number
  embedded?: boolean
}

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export interface NotifierIcons {
  icon: string
  iconExit: string
}
