import React, { useContext } from 'react';

import SavedQueryTile, { NewQueryTile, SavedQueryTypes } from 'javascripts/components/SavedQuery';
import CurrentProfileContext, { CurrentProfile } from 'javascripts/components/CurrentProfile';

interface Props {
  savedQueries: SavedQueryTypes.SavedQuery[]
  queryEndpoint: string
}

const COUNT_FIX_LAYOUT_ELEMENT = 5;

const SavedQueriesContainer: React.FC<Props> = ({ savedQueries, queryEndpoint }) => {

  const currentProfileCtx = useContext<Partial<CurrentProfile>>(CurrentProfileContext);

  const savedQueryList = (savedQueries: SavedQueryTypes.SavedQuery[]) =>
    savedQueries.map(savedQuery =>
      <SavedQueryTile
        key={savedQuery.id}
        savedQuery={savedQuery}
        queryEndpoint={queryEndpoint}
        savedQueryEndpoint={savedQueryEndpoint(savedQuery.id)}
      />
    )

  const savedQueryEndpoint = (queryId: number) =>
    `/panel/${currentProfileCtx.id}/saved_queries/${queryId}`;

  const fixFlexLayout = () =>
    new Array(COUNT_FIX_LAYOUT_ELEMENT)
      .fill({})
      .map((emptyQuery, index) => (
        <SavedQueryTile key={index} savedQuery={emptyQuery} savedQueryClassName="saved-query--hidden" />
      ));

  return (
    <div className="saved-queries__container">
      <NewQueryTile queryEndpoint={queryEndpoint} />
      { savedQueryList(savedQueries) }
      { fixFlexLayout() }
    </div>
  )
};

SavedQueriesContainer.displayName = 'SavedQueriesContainer'

export default React.memo(SavedQueriesContainer);
