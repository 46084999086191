import { SelectOption } from '../forms/Select/types'
import { CurrentProfile } from '../CurrentProfile'

export interface AdUnit {
  id: string
  name: string
  adminCost: number
}

export interface Website {
  id: string
  name: string
  adminCost: number
}

export interface AdUnitDto {
  ad_unit_id: string
  ad_unit_name?: string
  admin_cost: number
}

export interface WebsiteDto {
  site_id: string
  name?: string
  admin_cost: number
}

export interface User {
  id?: number
  name?: string
  email?: string
  isNew?: boolean
}

export interface UserDto {
  user_id: number
  name?: string
  email: string
}

export interface PublisherDomain {
  domainName?: string
  adminCost?: number
  uuid?: string
}

export interface PublisherDomainDto {
  domain_name: string
  admin_cost: number
}

export interface Publisher {
  name: string
  currency: SelectOption
  language: SelectOption
  email: string
  adUnits: AdUnit[]
  websites: Website[]
  users: User[]
  margin: number
  id?: number
  crmId: number | null
  domains: PublisherDomain[]
}

export interface PublisherDto {
  name: string
  currency_code: string
  language: string
  email: string
  margin: number
  id?: number
  crm_id: number | null
  domains?: PublisherDomainDto[]
}

export interface TabListProps {
  selectedTab: number
  onSetSelectedTab?: (position: number) => void
  isNew?: boolean
  disabledTabs?: boolean
}

export interface TabListItemProps {
  name: string
  position: number
  selectedTab: number
  onSelectTab?: Function
  disabled?: boolean
}

export interface PublisherContext {
  publisher: Publisher
  publisherId?: number
  updatePublisherProperties?: (property: string, value: string | []) => void
  updateAdUnits?: (adUnits: Array<AdUnit>) => void
  updateWebsites?: (websites: Array<Website>) => void
  updateInventory?: (adUnits: Array<AdUnit>, websites: Array<Website>) => void
  updateUsers?: (users: Array<User>) => void
  updateDomains?: (domains: Array<PublisherDomain>) => void
  publisherCrmId?: number | null
}

export interface TabViewProps extends PublisherContext {
  onSetSelectedTab?: (position: number) => void
  onGetPublisher?: (publisherId: number) => Promise<void>
  isLoading?: boolean
}

export interface NewPublisherViewProps {
  currentProfile: CurrentProfile
}

export interface EditPublisherViewProps {
  currentProfile: CurrentProfile
  publisherId: number
}

export interface TabViewContainerProps extends TabListProps, PublisherContext {
  onGetPublisher?: (publisherId: number) => Promise<void>
}

export enum PublisherTabIndex {
  NEW_PUBLISHER_TAB = 1,
  INVENTORY_TAB = 2,
  USERS_TAB = 3,
  FINANCES_TAB = 4,
}

export const defaultPublisher: Publisher = {
  name: '',
  currency: { value: 'USD', label: 'USD' },
  language: { value: 'eng', label: 'English' },
  email: '',
  adUnits: [],
  websites: [],
  users: [],
  margin: 0,
  domains: [],
  crmId: null,
}

export interface PublisherData {
  publisher_id?: number
  name: string
  original_revenue: number
  ad_impressions: number
  tech_cost: number
  net_revenue: number
  admin_cost: number
  margin_base: number
  revenue: number
  currency_code: string
  crm_id: number | null
}

export interface PublisherErrors {
  name: string[]
  currency: string[]
  language: string[]
  email: string[]
  crmId: string[]
}

export interface PublisherFormErrors {
  email: string[]
  publisher_users_emails: []
}

export interface DomainErrors {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}
