import React, { useState } from 'react'
import Input from '../forms/Input'
import Button, { ButtonTheme } from '../Button'
import { ErrorFieldBox, errorClass } from '../forms/ErrorFieldBox'
import * as inputValidators from 'javascripts/utlis/inputValidators'

interface Props {
  email: string
  setEmail: (email: string) => void
  onInviteUser: (email: string) => void
}

const InvitationsForm: React.FC<Props> = ({ onInviteUser, email, setEmail }) => {
  const [errors, setErrors] = useState([])

  const inviteUser = () => {
    const isFormValid = validateForm()

    if (isFormValid) onInviteUser(email)
  }

  const validateForm = () => {
    const err = []
    inputValidators.presenceValidation('email', email, err)
    inputValidators.emailFormatValidation(email, err)

    setErrors(err)
    return err.length === 0
  }

  return (
    <div className='invitations__form'>
      <div className={`invitations__form--input ${errorClass(errors)}`}>
        <Input
          type='email'
          id='invitation_email'
          name='email'
          value={email}
          label='Add email'
          onChange={setEmail}
        />
        <ErrorFieldBox errors={errors} />
      </div>

      <Button theme={ButtonTheme.Green} onClickHandler={inviteUser}>
        Send
      </Button>
    </div>
  )
}

export default InvitationsForm
