import React, { useState } from 'react'

import {
  EditPublisherViewProps,
  Publisher,
  PublisherTabIndex,
  AdUnit,
  Website,
  User,
  defaultPublisher,
  PublisherDomain,
} from 'javascripts/components/Publisher/types'
import * as PublisherService from 'javascripts/services/PublisherService'
import { AxiosResponse, AxiosError } from 'axios'
import CurrentProfileContext from 'javascripts/components/CurrentProfile'
import Box from 'javascripts/components/Box'
import TabContainer from 'javascripts/containers/Publisher/TabContainer'
import TabViewContainer from 'javascripts/containers/Publisher/EditPublisher/TabViewContainer'
import { proccessPublisher } from 'javascripts/services/PublisherService'

const EditPublisherView: React.FC<EditPublisherViewProps> = ({ currentProfile, publisherId }) => {
  const [selectedTab, setSelectedTab] = useState(PublisherTabIndex.NEW_PUBLISHER_TAB)
  const [publisher, setPublisher] = useState<Publisher>(defaultPublisher)
  const [publisherCrmId, setPublisherCrmId] = useState<number | null>(null)
  const [disabledTabs, setDisabledTabs] = useState(false)

  const updatePublisherProperties = (property: string, value: string | {}) => {
    const pub = { ...publisher }
    pub[property] = value
    setPublisher(pub)
  }

  const updateInventory = (adUnits: Array<AdUnit>, websites: Array<Website>) =>
    setPublisher({ ...publisher, adUnits, websites })
  const updateWebsites = (websites: Array<Website>) => setPublisher({ ...publisher, websites })
  const updateAdUnits = (adUnits: Array<AdUnit>) => setPublisher({ ...publisher, adUnits })
  const updateUsers = (users: Array<User>) => setPublisher({ ...publisher, users })
  const updateDomains = (domains: Array<PublisherDomain>) => setPublisher({ ...publisher, domains })

  const getPublisher = async (publisherId: number) => {
    setDisabledTabs(true)
    await PublisherService.getPublisherById(currentProfile.id, publisherId)
      .then(getPublisherSuccessHandler)
      .catch(getPublisherErrorHandler)
  }

  const getPublisherSuccessHandler = (response: AxiosResponse) => {
    const { publisher, ad_units, websites, users, domains } = response.data
    setPublisher(proccessPublisher(publisher, ad_units, websites, users, domains))
    setPublisherCrmId(publisher.crm_id)
    setDisabledTabs(false)
  }

  const getPublisherErrorHandler = (_error: AxiosError) => setDisabledTabs(false)

  return (
    <CurrentProfileContext.Provider value={currentProfile}>
      <div className='main-wrapper__item' id='main-wrapper-item'>
        <Box className='publisher'>
          <header className='box__header'>
            <h3 className='box__title'>Publishers</h3>
          </header>
          <hr className='line-separator' />
          <TabContainer selectedTab={selectedTab} onSetSelectedTab={setSelectedTab} disabledTabs={disabledTabs} />
          <TabViewContainer
            selectedTab={selectedTab}
            onSetSelectedTab={setSelectedTab}
            publisher={publisher}
            publisherId={publisherId}
            publisherCrmId={publisherCrmId}
            updatePublisherProperties={updatePublisherProperties}
            updateInventory={updateInventory}
            updateAdUnits={updateAdUnits}
            updateWebsites={updateWebsites}
            updateUsers={updateUsers}
            onGetPublisher={getPublisher}
            updateDomains={updateDomains}
          />
        </Box>
      </div>
    </CurrentProfileContext.Provider>
  )
}

export default EditPublisherView
