import React, { useState } from "react";
import { ErrorFieldBox, errorClass } from '../components/forms/ErrorFieldBox';
import logoDark from "images/logo-dark-new.svg";
import getCsrfToken from "../utlis/getCsrfToken";
import * as inputValidators from "../utlis/inputValidators";

interface Props {
  errors: string[]
}

interface State {
  isValid: boolean
  errors: {
    email: string[]
  }
}

export const PasswordNew: React.FunctionComponent<Props> = ({errors}) => {
  const getInitialState = () => {
    const initialState: State = {
      isValid: true,
      errors: {
        email: [],
      }
    };
    return initialState;
  }
  const [formErrors, setErrorsForm] = useState(getInitialState());
  const [email, setEmail] = useState('');

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void | boolean => {
    const newState = getInitialState();

    inputValidators.presenceValidation('email', email, newState.errors.email);
    inputValidators.emailFormatValidation(email, newState.errors.email);
    inputValidators.setStateValidation(newState);
    setErrorsForm(newState);

    if (newState.isValid) {
      return newState.isValid;
    } else {
      event.preventDefault();
    }
  }

  const getErrors = () => {
    return formErrors.errors.email.concat(errors)
  }

  return (
    <div className="registration registration--background-image-password-recovery">
      <div className="registration__container registration__container--column">
        <img className="registration__logo" src={logoDark} alt="Insight Yieldbird"/>
        <div className="registration__password-recovery">
          <div className="registration__header mb-5">
            <h2 className="registration__title">Recover password</h2>
            <small className="registration__description">Enter your e-mail used in registration.</small>
          </div>
          <form id="new_user_password" className="registration__form form" action="/users/password" noValidate acceptCharset="UTF-8" method="post" onSubmit={handleSubmit}>
            <input name="utf8" type="hidden" value="✓" />
            <input type="hidden" name="authenticity_token" value={getCsrfToken()} />
            <div className="form__body">
              <div className={`form__group ${errorClass(getErrors())}`}>
                <label>Your email</label>
                <input className="form-control"
                  placeholder="E-mail"
                  type="email"
                  name="user[email]"
                  id="user_email"
                  value={email}
                  onChange={(e) => { setEmail(e.target.value)}} />
                <ErrorFieldBox errors={getErrors()}/>
              </div>
            </div>
            <div className="form__buttons form__buttons--left mt-5">
              <input className="button"
                type="submit"
                name="commit"
                value="Send confirmation" />
            </div>
          </form>
        </div>
        <div className="registration__footer">
          <p>Don't have an account? <a className="footer__link" href="/">Sign up here</a></p>
        </div>
      </div>
    </div>
  )
}
