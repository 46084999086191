import React from "react";
import getCsrfToken from "../../utlis/getCsrfToken";
import { Networks } from "./Networks";
import { ConnectedNetwork } from '../../components/NetworksForm/ConnectedNetwork'
import { UnconnectedNetwork } from '../../components/NetworksForm/UnconnectedNetwork'
import { ErrorFieldBox, errorClass } from '../../components/forms/ErrorFieldBox';
import { Props } from '../../components/NetworksForm/NetworksFormProps'
import logoDark from "images/logo-dark.svg";

export const NetworksForm: React.FunctionComponent<Props> = ({ dfp_networks_form: { refreshToken, connectedNetworkPairs, formErrors, unconnectedNetworkPairs } }) => (
  <div className="dfp-networks__box">
    <a className="close" href="/panel">×</a>
    <img className="dfp-networks__logo" src={ logoDark } alt="Insight Yieldbird"/>

    <div className="dfp-networks__title">
      <strong>Please select ad manager<br /> to connect:</strong>
    </div>

    <div className="dfp-networks__form">
      <form id="new_dfp_networks" noValidate action="/panel/dfp_networks" acceptCharset="UTF-8" method="post">
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="authenticity_token" value={ getCsrfToken() } />

        <div className="form-group">
          <input className="form-control" type="hidden" value={ refreshToken } name="dfp_networks[refresh_token]" id="dfp_networks_refresh_token" />
        </div>

        <div className="dfp-networks__connected">
          <Networks
            networks={connectedNetworkPairs}
            template={ConnectedNetwork}
          />
        </div>
        <div className={`form-group check_boxes ${errorClass(formErrors)}`}>
          <Networks
            networks={unconnectedNetworkPairs}
            template={UnconnectedNetwork}
          />
          <ErrorFieldBox errors={formErrors}/>
        </div>

        <input type="submit" name="commit" value="Connect" className="btn-primary btn-block btn--big" data-disable-with="Connect" />
      </form>
    </div>
  </div>
)
