export class ChartGradientBuilder {
  constructor (chartId) {
    this.chartId = chartId;
    this.svg = $(`#${this.chartId} svg`).get(0);
    this.namespace = this.svg.namespaceURI;
    this.elementId = `${this.chartId}-greyGradient`;
  }

  run () {
    if (!this._hasGradient()) {
      this._build();
    }
    this._setFillAttributeToBars();
  }

  _hasGradient () {
    return document.getElementById(this.elementId) !== null;
  }

  _build () {
    const defs = document.createElementNS(this.namespace, 'defs');
    const gradient = document.createElementNS(this.namespace, 'linearGradient');

    $(gradient).attr({ gradientUnits: 'userSpaceOnUse', id: this.elementId, x1: '0%', x2: '0%', y1: '100%', y2: '0%' });

    gradient.appendChild(this._createStopElementWith('0', '0.6'));
    gradient.appendChild(this._createStopElementWith('0.75', '0'));

    defs.appendChild(gradient);
    this.svg.insertBefore(defs, this.svg.firstChild);
  }

  _createStopElementWith (offset, stopOpacity) {
    const stop = document.createElementNS(this.namespace, 'stop');
    $(stop).attr({ offset: offset, 'stop-color': '#dddddd', 'stop-opacity': stopOpacity });
    return stop;
  }

  _setFillAttributeToBars () {
    $(`#${this.chartId} .background-bar`).css('fill', `url('#${this.elementId}')`);
  }
}
