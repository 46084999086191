import { ChartStandardizer } from './helpers/chart_standardizer';

export class BaseChart {
  constructor (element, data, customAttributes) {
    this.el = element;
    this._setDataAndAttributes(data, customAttributes);
    this._addChartRedrawingOnResize();
  }

  _defaultAttributes () {
    throw new Error('Not implemented');
  }

  createChart () {
    this._setSizeParameters();
    this._createChartLayer();
  }

  updateChart (data, customAttributes) {
    this._setDataAndAttributes(data, customAttributes);
    this.redrawChart();
  }

  redrawChart () {
    this.svg.select('svg > :not(defs)').remove();
    this.createChart();
  }

  _setDataAndAttributes (data, customAttributes) {
    this._setData(data);
    this._applyChartAttributes(customAttributes);
  }

  _setData (_) {
    throw new Error('Not implemented');
  }

  _addChartRedrawingOnResize () {
    this.resizeHandler = this._debounce(this._resizeHandler.bind(this), 250);
    window.addEventListener('resize', this.resizeHandler);
  }

  _resizeHandler () {
    if (this.el.offsetParent === null) {
      window.removeEventListener('resize', this.resizeHandler);
    } else {
      this.redrawChart();
    }
  }

  _debounce (callback, time) {
    let interval;
    return (...args) => {
      clearTimeout(interval);
      interval = setTimeout(() => {
        interval = null;
        callback(...args);
      }, time);
    };
  }

  _setSizeParameters () {
    this.width = this.el.clientWidth;
    this.height = this.el.clientHeight;
  }

  _createChartLayer () {
    this.svg = d3.select(this.el).select('svg');
    this.chartLayer = this.svg.append('g');
  }

  _getUnit () {
    return ChartStandardizer.getUnit(this.unit);
  }

  _getPrettyValue (value) {
    return ChartStandardizer.getPrettyValue(value);
  }

  _applyChartAttributes (customAttributes = {}) {
    const defaultAttributes = this._defaultAttributes();

    Object.keys(defaultAttributes).forEach((key) => {
      this[key] = customAttributes[key] || defaultAttributes[key];
    });
  }

  _include (object) {
    Object.getOwnPropertyNames(Object.getPrototypeOf(object)).forEach(method => {
      if (method !== 'constructor') {
        this[method] = object[method];
      }
    });
  }
}
