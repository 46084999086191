import React from 'react'

import { TabListItemProps } from './types'

const TabListItem: React.FC<TabListItemProps> = ({ name, position, selectedTab, onSelectTab, disabled }) => {
  const activeTabClass = position === selectedTab ? 'active' : ''

  const onClickHandler = (position: number) => {
    if (disabled) return
    onSelectTab(position)
  }

  return (
    <div className='tab-list__item' role='tab' onClick={() => onClickHandler(position)}>
      <div className={`tab-list__item--position ${activeTabClass}`}>{position}</div>
      <div className={`tab-list__item--name ${activeTabClass}`}>{name}</div>
    </div>
  )
}

export default TabListItem
