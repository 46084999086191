import React, { useState } from 'react'
import CurrentProfileContext from 'javascripts/components/CurrentProfile'
import Box from 'javascripts/components/Box'
import TabContainer from 'javascripts/containers/Publisher/TabContainer'
import TabViewContainer from 'javascripts/containers/Publisher/NewPublisher/TabViewContainer'
import {
  Publisher,
  defaultPublisher,
  AdUnit,
  Website,
  NewPublisherViewProps,
  User,
  PublisherTabIndex,
  PublisherDomain,
} from 'javascripts/components/Publisher/types'

const NewPublisherView: React.FC<NewPublisherViewProps> = ({ currentProfile }) => {
  const [selectedTab, setSelectedTab] = useState(PublisherTabIndex.NEW_PUBLISHER_TAB)
  const [publisher, setPublisher] = useState<Publisher>(defaultPublisher)

  const updatePublisherProperties = (property: string, value: string | {}) => {
    const pub = { ...publisher }
    pub[property] = value
    setPublisher(pub)
  }

  const updateInventory = (adUnits: Array<AdUnit> = [], websites: Array<Website> = []) =>
    setPublisher({ ...publisher, adUnits, websites })
  const updateWebsites = (websites: Array<Website>) => setPublisher({ ...publisher, websites })
  const updateAdUnits = (adUnits: Array<AdUnit>) => setPublisher({ ...publisher, adUnits })
  const updateUsers = (users: Array<User>) => setPublisher({ ...publisher, users })
  const updateDomains = (domains: Array<PublisherDomain>) => setPublisher({ ...publisher, domains })

  return (
    <CurrentProfileContext.Provider value={currentProfile}>
      <div className='main-wrapper__item' id='main-wrapper-item'>
        <Box className='publisher'>
          <header className='box__header'>
            <h3 className='box__title'>Publishers</h3>
          </header>
          <hr className='line-separator' />
          <TabContainer selectedTab={selectedTab} isNew />
          <TabViewContainer
            selectedTab={selectedTab}
            onSetSelectedTab={setSelectedTab}
            publisher={publisher}
            updatePublisherProperties={updatePublisherProperties}
            updateInventory={updateInventory}
            updateAdUnits={updateAdUnits}
            updateWebsites={updateWebsites}
            updateUsers={updateUsers}
            updateDomains={updateDomains}
          />
        </Box>
      </div>
    </CurrentProfileContext.Provider>
  )
}

export default NewPublisherView
