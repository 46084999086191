import { ResearchReportBaseLoader } from './_research_report_base_loader';

export class AdxGeneratedResearchReportsLoader extends ResearchReportBaseLoader {
  constructor () {
    super();
    this.nameHeader = '.research-header';
  }

  load () {
    super.load();
    this._showNameHeader();
  }

  _showNameHeader () {
    $(this.nameHeader).fadeIn(1000);
  }
}
