import React from 'react'
import UserSettingsTabContainer from 'javascripts/containers/UserSettings/UserSettingsTabContainer'
import CurrentProfileContext from 'javascripts/components/CurrentProfile'
import { UserSerttingsViewProps } from 'javascripts/components/UserSettings/types'

const UserSerttingsView: React.FC<UserSerttingsViewProps> = ({
  currentProfile,
  hbIdentifiers,
  directIdentifiers,
  users,
}) => {
  return (
    <CurrentProfileContext.Provider value={currentProfile}>
      <div className='main-wrapper__item user-settings'>
        <UserSettingsTabContainer
          hbIdentifiers={hbIdentifiers}
          directIdentifiers={directIdentifiers}
          users={users}
        />
      </div>
    </CurrentProfileContext.Provider>
  )
}

export default UserSerttingsView
