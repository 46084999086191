import { SspConfiguration } from '../types'

import IndexExchangeLogo from 'images/index_exchange_logo.png'
import IndexExchangeSmallLogo from 'images/index_exchange_small_logo.png'

const indexExchangeConfig: SspConfiguration = {
  platform: 'index_exchange',
  platformName: 'Index Exchange',
  logo: IndexExchangeLogo,
  smallLogo: IndexExchangeSmallLogo,
}

export default indexExchangeConfig
