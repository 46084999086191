import React, { useContext } from 'react'
import CurrentProfileContext, { CurrentProfile } from 'javascripts/components/CurrentProfile'
import { useMultiSelect } from 'javascripts/components/forms/Select'
import Button, { ButtonTheme } from 'javascripts/components/Button'
import { TabViewProps, PublisherTabIndex } from 'javascripts/components/Publisher/types'
import InventoryForm from 'javascripts/components/Publisher/InventoryForm'
import { convertItemsToSelectOption, convertSelectOptionToItems } from '../publisherUtils'

const InventoryContainer: React.FC<TabViewProps> = ({
  onSetSelectedTab,
  publisher,
  updateInventory
}) => {
  const currentProfileCtx = useContext<Partial<CurrentProfile>>(CurrentProfileContext)

  const [adUnits, addAdUnit, removeAdUnit] = useMultiSelect(convertItemsToSelectOption(publisher.adUnits))
  const [websites, addWebsites, removeWebsites] = useMultiSelect(convertItemsToSelectOption(publisher.websites))

  const goToTab = (tab: PublisherTabIndex): void => {
    updateInventory(convertSelectOptionToItems(adUnits), convertSelectOptionToItems(websites))
    onSetSelectedTab(tab)
  }

  const goToNewTab = () => goToTab(PublisherTabIndex.NEW_PUBLISHER_TAB)
  const goToUserTab = () => goToTab(PublisherTabIndex.USERS_TAB)

  return (
    <div className='tab-view'>
      <h3>Choose publisher's inventory</h3>

      <div>
        <InventoryForm
          id='ad_units'
          placeholder='Add Ad Unit'
          items={adUnits}
          endpoint={`/panel/${currentProfileCtx.id}/publisher_ad_units/search`}
          addItem={addAdUnit}
          removeItem={removeAdUnit}
        />

        <InventoryForm
          id='websites'
          placeholder='Add Websites'
          items={websites}
          endpoint={`/panel/${currentProfileCtx.id}/ssp_sites/search`}
          addItem={addWebsites}
          removeItem={removeWebsites}
        />
      </div>

      <footer className='tab-view__footer'>
        <Button theme={ButtonTheme.GreenOutline} onClickHandler={goToNewTab}>
          Back
        </Button>
        <Button theme={ButtonTheme.Green} onClickHandler={goToUserTab}>
          Next
        </Button>
      </footer>
    </div>
  )
}

export default InventoryContainer
