import { SspConfiguration } from '../types'

import SmartAdServerLogo from 'images/smart_ad_server_logo.png'
import SmartAdServerSmallLogo from 'images/smart_ad_server_small_logo.png'

const smartAdServerConfig: SspConfiguration = {
  platform: 'smart_ad_server',
  platformName: 'SmartAdServer',
  logo: SmartAdServerLogo,
  smallLogo: SmartAdServerSmallLogo,
}

export default smartAdServerConfig
