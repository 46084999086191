import React from 'react'

import { WebsitePerformanceHeaderCellProps } from './types'

const WebsitePerformanceHeaderCell: React.FC<WebsitePerformanceHeaderCellProps> = ({ column, label, setSorting, sortParams }) => {

  const getSortingIcon = (column) => {
    const sortOrderIcon = sortParams.order === 'asc' ? 'up' : 'down'
    const classString = `fa fa-sort-${sortOrderIcon}`
    return column === sortParams.column
      ? (<i className={classString} />)
      : null
  }

  return (
    <div
      className='website-performance__cell website-performance__cell-header'
      onClick={() => setSorting(column)}
    >
      {label} {getSortingIcon(column)}
    </div>
  )
}

export default WebsitePerformanceHeaderCell
