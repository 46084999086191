import React, { useState } from 'react'
import getCsrfToken from 'javascripts/utlis/getCsrfToken'

interface Props {
  endpoint: string
}

type FileInputEvent = React.ChangeEvent<HTMLInputElement>
const DEFAULT_LABEL = 'Choose a file...'

const ReportFileUpload: React.FC<Props> = ({ endpoint }) => {
  const [label, setLabel] = useState(DEFAULT_LABEL)
  const [hasFile, setHasFile] = useState(false)

  const onChangeHandler = (event: FileInputEvent) => {
    if (event.target.files.length > 0) {
      setHasFile(true)
      setLabel(event.target.value.split('\\').pop())
    } else {
      setHasFile(false)
      setLabel(DEFAULT_LABEL)
    }
  }

  return (
    <div className='file-upload-container'>
      <h1>Upload CSV/XLS report</h1>
      <form encType='multipart/form-data' action={endpoint} acceptCharset='UTF-8' method='post' className='file-upload'>
        <input name='utf8' type='hidden' value='✓' />
        <input type='hidden' name='authenticity_token' value={getCsrfToken()} />
        <input type='file' name='file' id='file' className='file-input file-button' accept='.csv, application/vnd.ms-excel' onChange={onChangeHandler} />
        <label htmlFor='file' className='button-primary button-primary--green-outline'>
          {label}
        </label>
        {hasFile && (
          <input
            type='submit'
            name='commit'
            value='Upload'
            data-disable-with='upload'
            className='button-primary button-primary--green'
          />
        )}
      </form>
    </div>
  )
}

export default ReportFileUpload
