import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import Button, { ButtonTheme } from 'javascripts/components/Button'
import { Publisher, PublisherDomain, DomainErrors } from './types'
import PublisherDomainListItem from './PublisherDomainListItem'

interface Props {
  publisher: Publisher
  updateDomains: Function
  domainErrors?: DomainErrors
}

const PublisherDomainsForm: React.FC<Props> = ({ publisher, updateDomains, domainErrors }) => {
  const addDomain = () => {
    const newDomain = {
      domainName: undefined,
      adminCost: undefined,
      uuid: uuidv4(),
    }

    updateDomains([...publisher.domains, newDomain])
  }

  const deleteDomain = (uuid: string) => {
    const filteredDomains = publisher.domains.filter((domain) => domain.uuid !== uuid)
    updateDomains(filteredDomains)
  }

  const updateDomain = (updatedDomain: PublisherDomain) => {
    const updatedDomains = publisher.domains.map((domain) => {
      if (domain.uuid === updatedDomain.uuid) {
        domain = { ...updatedDomain }
      }
      return domain
    })
    updateDomains(updatedDomains)
  }

  return (
    <div>
      {publisher.domains.length > 0 && (
        <>
          {publisher.domains.map((domain) => (
            <PublisherDomainListItem
              domain={domain}
              key={domain.uuid}
              errors={domainErrors ? domainErrors[domain.uuid] : undefined}
              updateDomain={updateDomain}
              deleteDomain={deleteDomain}
            />
          ))}
        </>
      )}

      <Button theme={ButtonTheme.GreenOutline} onClickHandler={addDomain} className='publisher__form-button--add-new'>
        Add Next
      </Button>
    </div>
  )
}

export default PublisherDomainsForm
