const multiValueLabel = (styles: object) => {
  return {
    ...styles,
    color: '#ffffff',
    cursor: 'default',
    padding: '5px 0',
    paddingLeft: '0',
    fontSize: '12px',
  }
}

export default multiValueLabel
