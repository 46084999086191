import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import axios from 'axios'
import Input from 'javascripts/components/forms/Input'
import YieldbirdLogo from 'images/logo-dark.svg'
import Button, { ButtonTheme } from 'javascripts/components/Button'
import {
  ProfileModalProps,
  ChangePasswordValues,
  ChangePasswordErrors,
} from 'javascripts/components/Profile/types'
import {
  changePasswordInits,
  changePasswordErrorsInits,
} from 'javascripts/components/Profile/initials'
import getCsrfToken from 'javascripts/utlis/getCsrfToken'
import { ErrorFieldBox } from 'javascripts/components/forms/ErrorFieldBox'
import { HttpStatusCode, redirect } from 'javascripts/components/Profile/httpHelper'

const ChangePassword: React.FC<ProfileModalProps> = ({ endpoints, shouldOpen, onCloseHandler }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [values, setValues] = useState<ChangePasswordValues>(changePasswordInits)
  const [errors, setErrors] = useState<ChangePasswordErrors>(changePasswordErrorsInits)

  useEffect(() => {
    setOpen(shouldOpen)
  }, [shouldOpen])

  const closeModal = () => {
    onCloseHandler()
    setValues(changePasswordInits)
    setErrors(changePasswordErrorsInits)
  }

  const submitForm = () => {
    setLoading(true)

    axios
      .patch(endpoints.updatePassword, {
        authenticity_token: getCsrfToken(),
        user: {
          current_password: values.currentPassword,
          password: values.newPassword,
          password_confirmation: values.passwordConfirmation,
        },
      })
      .then(response => {
        if (response.status === HttpStatusCode.OK) {
          redirect(endpoints.signIn)
        } else if (response.status === HttpStatusCode.VALIDATION_ERROR) {
          setLoading(false)
          const errors = response.data.errors

          setErrors({
            currentPassword: errors.current_password,
            newPassword: errors.password,
            passwordConfirmation: errors.password_confirmation,
          })
        }
      })
  }

  return (
    <Modal isOpen={open} onRequestClose={closeModal} ariaHideApp={false} className='Modal'>
      <div className='profile-modal__container'>
        <img className='profile-modal__logo' src={YieldbirdLogo} alt='Yieldbird Insights'></img>

        <div className='profile-modal__title'>Change your password</div>

        <div className='profile-modal__inputs'>
          <div className='profile__form--input-container'>
            <Input
              id='current-pwd'
              type='password'
              label='Current password'
              value={values.currentPassword}
              onChange={value => setValues({ ...values, currentPassword: value })}
            ></Input>
            {errors.currentPassword && (
              <ErrorFieldBox
                prefix='Current password'
                errors={errors.currentPassword}
              ></ErrorFieldBox>
            )}
          </div>

          <div className='profile__form--input-container'>
            <Input
              id='new-pwd'
              type='password'
              label='New password'
              value={values.newPassword}
              onChange={value => setValues({ ...values, newPassword: value })}
            ></Input>
            {errors.newPassword && (
              <ErrorFieldBox prefix='New password' errors={errors.newPassword}></ErrorFieldBox>
            )}
          </div>

          <div className='profile__form--input-container'>
            <Input
              id='confirm-new-pwd'
              type='password'
              label='Confirm new password'
              value={values.passwordConfirmation}
              onChange={value => setValues({ ...values, passwordConfirmation: value })}
            ></Input>
            {errors.passwordConfirmation && (
              <ErrorFieldBox
                prefix='Confirmed password'
                errors={errors.passwordConfirmation}
              ></ErrorFieldBox>
            )}
          </div>
        </div>

        <div className='profile-modal__buttons'>
          <Button theme={ButtonTheme.GreenOutline} onClickHandler={closeModal}>
            Cancel
          </Button>

          <Button
            theme={ButtonTheme.Green}
            onClickHandler={() => submitForm()}
            disabled={loading || !values.newPassword || !values.passwordConfirmation}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ChangePassword
