import DomHelper from 'javascripts/utlis/domHelper';

interface Config {
  containerClass: string
  allCheckedOnStart: boolean
  preventUncheckAll: boolean
  withAllButton: boolean
  allButtonId: string
}

export class CheckboxHelper {
  private activeClass: string;
  private defaultConfig: Config;

  constructor () {
    this.activeClass = 'active';
    this.defaultConfig = {
      containerClass: 'checkbox-container',
      allCheckedOnStart: true,
      preventUncheckAll: true,
      withAllButton: true,
      allButtonId: 'all-button'
    };
  }

  initializeCheckboxes = (userConfig: Config): void => {
    const config: Config = this.prepareConfig(userConfig);
    const checkboxes: HTMLCollection = this.getCheckboxes(config.containerClass);

    Array.from(checkboxes).forEach((checkbox: Element, i: number) => {
      const { label, input } = this.unwrapCheckbox(checkbox);

      input.checked = config.allCheckedOnStart || (!config.allCheckedOnStart && i === 0);
      if (input.checked) {
        DomHelper.appendClass(label, this.activeClass);
      }
      this.initializeCheckboxClick(label, input, config, i, checkboxes);
    });

    if (config.withAllButton) {
      this.initializeAllButton(checkboxes, config);
    }
  };

  private initializeCheckboxClick = (
    label: HTMLLabelElement,
    input: HTMLInputElement,
    config: Config,
    index: number,
    checkboxes: HTMLCollection
  ): void => {
    const toggleCheckbox = () => {
      DomHelper.toggleClass(label, this.activeClass);
      input.checked = !input.checked;
    };

    label.addEventListener('click', e => {
      e.preventDefault();

      if (config.preventUncheckAll) {
        if (!this.lastUnchecked(index, checkboxes)) {
          toggleCheckbox();
        }
      } else {
        toggleCheckbox();
      }

      if (config.withAllButton) {
        const selectAllButton = this.getSelectAllButton(config.allButtonId) as HTMLElement;

        if (this.allChecked(checkboxes)) {
          DomHelper.appendClass(selectAllButton, this.activeClass);
        } else if (!this.allChecked(checkboxes)) {
          DomHelper.removeClass(selectAllButton, this.activeClass);
        }
      }
    });
  };

  private initializeAllButton = (checkboxes: HTMLCollection, userConfig: Config): void => {
    const config: Config = this.prepareConfig(userConfig);
    const button = this.getSelectAllButton(config.allButtonId) as HTMLElement;

    if (this.allChecked(checkboxes)) DomHelper.appendClass(button, this.activeClass);

    button.addEventListener('click', e => {
      e.preventDefault();

      const allChecked = this.allChecked(checkboxes);
      DomHelper.toggleClass(button, this.activeClass);

      Array.from(checkboxes).forEach((checkbox, i) => {
        const { label, input } = this.unwrapCheckbox(checkbox);

        if (!allChecked) {
          DomHelper.appendClass(label, this.activeClass);
          input.checked = true;
        } else {
          if (i === 0 && config.preventUncheckAll) {
            DomHelper.appendClass(label, this.activeClass);
            input.checked = true;
          } else {
            DomHelper.removeClass(label, this.activeClass);
            input.checked = false;
          }
        }
      });
    });
  };

  private getCheckboxes = (containerClass: string): HTMLCollection =>
    document.getElementsByClassName(containerClass)[0].getElementsByClassName('checkbox');

  private getSelectAllButton = (buttonId: string): HTMLElement =>
    document.getElementById(buttonId) as HTMLElement;

  private allChecked = (checkboxes: HTMLCollection): boolean => {
    return Array.from(checkboxes).every(checkbox => {
      const { input }: { input: HTMLInputElement } = this.unwrapCheckbox(checkbox);
      return input.checked === true;
    });
  };

  private lastUnchecked = (index: number, checkboxes: HTMLCollection): boolean => {
    return Array.from(checkboxes).every((checkbox, i) => {
      const { input }: { input: HTMLInputElement } = this.unwrapCheckbox(checkbox);
      const checked = input.checked;
      return i === index ? checked === true : checked === false;
    });
  };

  private unwrapCheckbox = (
    checkbox: Element
  ): { label: HTMLLabelElement; input: HTMLInputElement } => {
    const label = checkbox.firstChild as HTMLLabelElement;
    const input = label.firstChild as HTMLInputElement;
    return { label, input };
  };

  private prepareConfig = (userConfig: Config): Config =>
    Object.assign({}, this.defaultConfig, userConfig);
}
