export class Sidebar {
  private readonly MAX_COLLAPSED_WIDTH = 1480
  private readonly LOCAL_STORAGE_COLLAPSED_KEY = 'sidebarCollapsed'
  private arrow: Element
  private menu: Element
  private menuLinks: HTMLCollectionOf<Element>
  private collapsed: boolean

  constructor() {
    this.arrow = document.getElementsByClassName('menu__arrow')?.[0]

    if (this.arrow !== undefined) {
      this.menu = document.getElementsByClassName('menu')?.[0]
      this.menuLinks = document.getElementsByClassName('menu__link')

      this.getFromLocalStorage()
      this.initializeListeners()
    }
  }

  getFromLocalStorage = () => {
    if (window.innerWidth <= this.MAX_COLLAPSED_WIDTH) {
      this.setInLocalStorage(true)
    }

    this.collapsed = window.localStorage.getItem(this.LOCAL_STORAGE_COLLAPSED_KEY) == 'true'
    this.toggleCollapse(this.collapsed, false)
  }

  initializeListeners = () => {
    this.arrow.addEventListener('click', () => {
      this.collapsed = !this.collapsed

      this.toggleCollapse(this.collapsed)
      this.setInLocalStorage(this.collapsed)
    })

    window.addEventListener('resize', () => {
      this.collapsed = window.innerWidth <= this.MAX_COLLAPSED_WIDTH

      this.toggleCollapse(this.collapsed)
      this.setInLocalStorage(this.collapsed)
    })
  }

  toggleCollapse = (condition: boolean, withTransition = true) => {
    this.menu.classList.toggle('menu--collapsed', condition)
    this.arrow.classList.toggle('menu__arrow--collapsed', condition)
    for (const menuLink of this.menuLinks) {
      menuLink.classList.toggle('menu__link--hidden', condition)
    }

    this.menu.classList.toggle('menu--collapsed-transition-off', !withTransition)
    this.arrow.classList.toggle('menu__arrow--collapsed-transition-off', !withTransition)
  }

  setInLocalStorage = (condition: boolean) => {
    window.localStorage.setItem(this.LOCAL_STORAGE_COLLAPSED_KEY, condition.toString())
  }
}
