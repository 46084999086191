import { useState } from 'react'
import { SelectOption } from '../types'

const useMultiSelect = (
  defaultValues: SelectOption[]
): [SelectOption[], (values: SelectOption[]) => void, (value: string) => void] => {
  const [values, setValues] = useState(defaultValues)

  const addValue = (selectedValues: SelectOption[]) => {
    setValues([...selectedValues])
  }

  const removeValue = (value: string) => {
    const newValues = values.filter(v => v.value !== value)
    setValues(newValues)
  }

  return [values, addValue, removeValue]
}

export default useMultiSelect
