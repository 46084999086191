import React, { memo } from 'react'
import Loader from 'react-loader-spinner'

const LOADER_TYPE = 'Oval'
const LOADER_COLOR = '#33decb'

const SimpleLoader: React.FC = () => {
  return (
    <div className='loader__container'>
      <Loader type={LOADER_TYPE} color={LOADER_COLOR} />
    </div>
  )
}

export default memo(SimpleLoader)
