import { ResearchChart } from '../research_chart';
import { ResearchChartLegend } from './research_chart_legend';
import { ResearchChartMetricsBar } from './research_chart_metrics_bar';
import { ResearchChartSwitcher } from './research_chart_switcher';

export class ResearchChartContainer {
  constructor (element, data, form, customAttributes, currency, translations) {
    this.element = element;
    this.data = data;
    this.form = form;
    this.time = form.time;
    this.customAttributes = customAttributes;
    this.currency = currency;
    this.translations = translations;
    this.chart = new ResearchChart(this.element, this._getCurrentData(), this._getCustomAttributes(), this.time);
    this.legend = new ResearchChartLegend(this.chart, this._getSortedLabelsForLegend());
  }

  createChart () {
    this._createMetricsBar();
    this._drawChart();
    this._createSwitcher();
    this._buildLegend();
  }

  redraw (metric) {
    if (metric === this._getCurrentMetric()) {
      return;
    }
    this.currentMetric = metric;
    this.chart.updateChart(this._getCurrentData(), this._getCustomAttributes());
  }

  _createMetricsBar () {
    new ResearchChartMetricsBar(this).create();
  }

  _drawChart () {
    this.chart.createChart();
  }

  _createSwitcher () {
    new ResearchChartSwitcher(this).create();
  }

  _buildLegend () {
    this.legend.build();
    if (ResearchChartContainer.DIMENSIONS_WITH_OTHERS.includes(this._getFormDimension())) {
      this.legend.toggle('Others');
    }
  }

  _getFormDimension () {
    return this.form['dimension'];
  }

  _getCurrentData () {
    return this.data[this._getCurrentMetric()];
  }

  _getCustomAttributes () {
    return Object.assign({}, this.customAttributes, this._getUnit());
  }

  _getUnit () {
    const currentMetric = this._getCurrentMetric();

    if (ResearchChartContainer.CURRENCY_METRICS.includes(currentMetric)) {
      return { unit: this.currency };
    } else if (ResearchChartContainer.PERCENTAGE_METRICS.includes(currentMetric)) {
      return { unit: '%' };
    }

    return {};
  }

  _getCurrentMetric () {
    return this.currentMetric || ResearchChartContainer.DEFAULT_METRIC;
  }

  _getSortedLabelsForLegend () {
    return this.data.revenue.slice().sort(this._sortByValuesSum).map(elem => elem.type);
  }

  _sortByValuesSum (a, b) {
    const reduceCallback = (sum, current) => { return sum + current.value; };
    const last = a.data.length;
    const first = last / 2;

    return b.data.slice(first, last).reduce(reduceCallback, 0) - a.data.slice(first, last).reduce(reduceCallback, 0);
  }
}

ResearchChartContainer.DEFAULT_METRIC = 'revenue';
ResearchChartContainer.CURRENCY_METRICS = ['revenue', 'ecpm', 'rcpm'];
ResearchChartContainer.PERCENTAGE_METRICS = ['viewability', 'ctr', 'coverage'];
ResearchChartContainer.DIMENSIONS_WITH_OTHERS = ['ad_units', 'geo', 'websites'];
