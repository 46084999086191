import React, { useState } from 'react';
import copy from 'copy-to-clipboard'

interface Props {
  copyValue: string | undefined
  delay?: number
  copyMessage?: string
}

const CopyLink: React.FC<Props> = ({ copyValue, delay, copyMessage, children }) => {

  const [isCopied, setIsCopied] = useState(false);

  const copyToClipBoard = () => {
    if (copyValue) {
      setIsCopied(true);
      copy(copyValue);
      setTimeout(() => setIsCopied(false), delay)
    }
  }

  return (
    <div
      className="copy"
      onClick={copyToClipBoard}
    >
      { children }
      { isCopied && <span>{ copyMessage }</span> }
    </div>
  )
};

CopyLink.defaultProps = {
  delay: 1500,
  copyMessage: 'Copied'
};

export default CopyLink;
