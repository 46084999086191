import { SspConfiguration } from '../types'

import SovrnLogo from 'images/sovrn_logo.jpg'
import SovrnSmallLogo from 'images/sovrn_logo.jpg'

const sovrnConfig: SspConfiguration = {
  platform: 'sovrn',
  platformName: 'Sovrn',
  logo: SovrnLogo,
  smallLogo: SovrnSmallLogo,
}

export default sovrnConfig
