import React from 'react'
import Box from 'javascripts/components/Box'
import ProfileContainer from 'javascripts/containers/profile/ProfileContainer'
import CurrentUserContext, { CurrentUser } from 'javascripts/components/CurrentUser'
import ProfileContext from 'javascripts/components/Profile'
import { Endpoints } from 'javascripts/components/Profile/types'

interface ProfileProps {
  currentUser: CurrentUser
  profilesAuthorizer: boolean
  endpoints: Endpoints
}

const Profile: React.FC<ProfileProps> = ({ currentUser, profilesAuthorizer, endpoints }) => {
  return (
    <CurrentUserContext.Provider value={currentUser}>
      <ProfileContext.Provider value={{ profilesAuthorizer, endpoints }}>
        <div className='main-wrapper__item'>
          <Box className='profile'>
            <header className='box__header'>
              <h3 className='box__title'>Profile</h3>
            </header>
            <hr className='line-separator' />
            <ProfileContainer />
          </Box>
        </div>
      </ProfileContext.Provider>
    </CurrentUserContext.Provider>
  )
}

export default Profile
