import { Spinner } from 'javascripts/spinner'
import { AjaxErrorsHandler } from './ajax_errors_handler'

export class RemoteForm {
  constructor(formContainer, dataContainer) {
    this.formContainer = formContainer
    this.previousRequest = undefined
    this.dataContainer = dataContainer
    this.spinner = new Spinner(dataContainer, { extraWide: true })
    this.delay = this._getDelay()

    this._intializeAjaxBefore()
    this._intializeAjaxBeforeSend()
    this._intializeAjaxError()
    this._intializeAjaxComplete()
    this._handleFormChange()
  }

  _intializeAjaxBefore () {
    $(this.formContainer).on('ajax:before', () => {
      $(this.dataContainer).trigger('data:beforeChange')
      this.spinner.show()
    })
  }

  _intializeAjaxBeforeSend () {
    $(this.formContainer).on('ajax:beforeSend', (event) => {
      if (this.previousRequest !== undefined) {
        this.previousRequest.abort()
      }
      this.previousRequest = event.detail[0]
    })
  }

  _intializeAjaxError () {
    $(this.formContainer).on('ajax:error', (event) => {
      if (event.detail[2].status === 401) {
        window.location.replace('/users/sign_in')
      } else {
        new AjaxErrorsHandler(this.dataContainer, event.target).run()
        this.spinner.hide()
      }
    })
  }

  _intializeAjaxComplete () {
    $(this.formContainer).on('ajax:success', () => {
      $(this.dataContainer).trigger('data:changed')
      this.spinner.hide()
    })
  }

  _handleFormChange () {
    $(this.formContainer).on(
      'change',
      this._debounce(() => {
        Rails.fire(document.querySelector(this.formContainer), 'submit')
      }, this.delay)
    )
  }

  // https://davidwalsh.name/javascript-debounce-function
  _debounce (func, wait, immediate) {
    let timeout
    return function () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this
      const args = [...args]
      const later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      const callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }

  _getDelay () {
    return this._isTestEnvironment() ? 0 : 500
  }

  _isTestEnvironment () {
    return /HeadlessChrome/.test(window.navigator.userAgent)
  }
}
