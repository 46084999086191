import React from 'react';

import Box from 'javascripts/components/Box';
import SavedQueriesContainer from 'javascripts/containers/savedQueries/SavedQueriesContainer';
import { SavedQueryTypes } from 'javascripts/components/SavedQuery';
import CurrentProfileContext, { CurrentProfile } from 'javascripts/components/CurrentProfile';

interface Props {
  savedQueries: SavedQueryTypes.SavedQuery[]
  queryEndpoint: string
  currentProfile: CurrentProfile
}

const SavedQueries: React.FC<Props> = ({ savedQueries, queryEndpoint, currentProfile }) => {
  return (
    <CurrentProfileContext.Provider value={currentProfile}>
      <div className="main-wrapper__item">
        <Box className="saved-queries">
          <header className="box__header">
            <h3 className="box__title">Saved reports</h3>
          </header>
          <hr className="line-separator" />

          <SavedQueriesContainer
            savedQueries={savedQueries}
            queryEndpoint={queryEndpoint}
          />
        </Box>
      </div>
    </CurrentProfileContext.Provider>
  )
};

export default SavedQueries;
