import {
  ChangePasswordValues,
  ProfileFormValues,
  Endpoints,
  ChangePasswordErrors,
  ProfileErrors,
  DeleteAccountValues,
  DeleteAccountErrors,
} from 'javascripts/components/Profile/types'

export const changePasswordInits: ChangePasswordValues = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const profileFormInits: ProfileFormValues = {
  name: '',
  email: '',
  company: '',
}

export const profileFormEndpointsInits: Endpoints = {
  profile: '',
  redirect: '',
  updatePassword: '',
  signOut: '',
  signIn: '',
}

export const changePasswordErrorsInits: ChangePasswordErrors = {
  currentPassword: [],
  newPassword: [],
  passwordConfirmation: [],
}

export const profileErrorsInits: ProfileErrors = {
  name: [],
  company: [],
}

export const deleteAccountsInits: DeleteAccountValues = {
  confirmation: '',
}

export const deleteAccountsErrorsInits: DeleteAccountErrors = {
  confirmation: [],
}
