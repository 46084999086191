import React from 'react';

import { NewQueryTileProps } from './types';

const NewQueryTile: React.FC<NewQueryTileProps> = ({ queryEndpoint }) => {
  return (
    <div className={`saved-query new-query--tile`}>
      <a className="saved-query__content" href={queryEndpoint}>
        <div className="ball"></div>
        <div className="saved-query__name">Add New Reports</div>
      </a>
    </div>
  )
};

NewQueryTile.displayName = 'NewQueryTile';

export default React.memo(NewQueryTile);
