export class SortableTable {
  constructor () {
    this.el = '.sortable-table-header';
    this.className = 'floating-header';
    this.selector = `.${this.className}`;
  }

  updateHeaders () {
    this._removePreviousHeader();
    this._createHeader();
    this._setWidth();
    this._setScrollEvent();
    this._addSortedClass();
  }

  _removePreviousHeader () {
    $(this.selector).remove();
  }

  _createHeader () {
    $(this.el).clone()
      .appendTo('.sortable-table thead')
      .css('width', $(this.el).width())
      .addClass(this.className)
      .removeClass('sortable-table-header');
  }

  _setWidth () {
    $(this.el).find('th').each((i, elem) => {
      $(this.selector).find('th').eq(i).width($(elem).width());
    });
  }

  _setScrollEvent () {
    if ($(this.el).length > 0) {
      $(window).scroll(this._updateTableHeaders.bind(this)).trigger('scroll');
    }
  }

  _addSortedClass () {
    $('i.fa').closest('th').addClass('sorted-column');
  }

  _updateTableHeaders () {
    $('.sortable-table').each((_, elem) => {
      const offset = $(elem).offset();
      const scrollTop = $(window).scrollTop();
      const visibility = (scrollTop - $(this.el).height() > offset.top) ? 'visible' : 'hidden';
      $(this.selector, elem).css('visibility', visibility);
    });
  }
}
