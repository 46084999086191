interface Window {
  Rails: typeof Rails;
  jQuery: JQueryStatic;
  $: JQueryStatic;
  Popper: any;
  d3: any;
  moment: any;
}

require.context('../images', true);

const Rails = require('rails-ujs');
Rails.start();
window.Rails = Rails;

window.$ = window.jQuery = require('jquery');
window.d3 = require('d3');
window.Popper = require('popper.js');
require('bootstrap/dist/js/bootstrap');
require('select2/dist/js/select2.full');
window.moment = require('moment');
require('daterangepicker');
require('slick-carousel');

require("javascripts/globals");

const WebpackerReact = require('webpacker-react').default;
const ComponentsExportedToRails = require('javascripts/components/exported_to_rails').default;
WebpackerReact.registerComponents(ComponentsExportedToRails);

$(() => WebpackerReact.mountComponents());
