import moment from 'moment';

import { DatePicker } from './datePicker';

export class AdvancedTimeSelect extends DatePicker {
  private static readonly ELEMENT: string = '.advanced-time-select';
  private static readonly INPUT: string = '.advanced-time-select input';
  private static readonly CURRENT_TIME_TEXT: string = '.advanced-time-select .current-time-text';
  private static readonly CURRENT_TIME_RANGE: string = '.advanced-time-select .current-time-range';
  private static readonly DATEPICKER_CONTAINER: string = '.daterangepicker';
  private static readonly SELECTED_OPTION: string = '.advanced-time-select .selected-option';
  private static readonly TIME_RANGE_CLASS: string = '.advanced-time-select__dropdown-menu-header-time-range';
  private static readonly TIME_PATTERN: string = 'DD MMM YYYY';

  constructor() {
    super('.advanced-time-select .date_picker', undefined);
  }

  create() {
    super.create();
    this.setTimeDropdownClickEvent();
    this.preventFromClosing();
    $(AdvancedTimeSelect.INPUT).val($(AdvancedTimeSelect.SELECTED_OPTION).val() || '');
  }

  onChange(callback: Function) {
    $(AdvancedTimeSelect.SELECTED_OPTION).change(() => {
      $(AdvancedTimeSelect.INPUT).val($(AdvancedTimeSelect.SELECTED_OPTION).val() || '');
      callback();
    });
  }

  datePickerOnChangeCallback(startDate: moment.Moment, endDate: moment.Moment) {
    const date = `${startDate.format(AdvancedTimeSelect.TIME_PATTERN)} - ${endDate.format(AdvancedTimeSelect.TIME_PATTERN)}`;
    this.updateSelectedOption('', date, date);
  }

  private updateSelectedOption(
    timeText: string | undefined = '',
    timeRange: string | undefined = '',
    timeValue: string | undefined = '',
  ) {
    $(AdvancedTimeSelect.TIME_RANGE_CLASS).text(timeRange);
    $(AdvancedTimeSelect.SELECTED_OPTION).text(timeText || timeRange).val(timeValue).trigger('change');
    $(AdvancedTimeSelect.CURRENT_TIME_TEXT).text(timeText);
    $(AdvancedTimeSelect.CURRENT_TIME_RANGE).text(timeRange);
  }

  private setTimeDropdownClickEvent() {
    $(`${AdvancedTimeSelect.ELEMENT} .advanced-time-select__dropdown-menu-select`).click((e) => {
      e.preventDefault();
      this.updateSelectedOption(e.target.dataset.timeText, e.target.dataset.timeRange, e.target.dataset.timeValue);
    });
  }

  private preventFromClosing() {
    $(`${AdvancedTimeSelect.ELEMENT} .keep-open a.custom_date`).click((e) => {
      e.preventDefault();
      e.stopPropagation();
    });
    $(AdvancedTimeSelect.DATEPICKER_CONTAINER).click((e) => {
      e.preventDefault();
      e.stopPropagation();
    });
  }
}
