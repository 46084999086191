import { ResearchBaseRemoteHandler } from './_research_base_remote_handler';
import { ResearchDisableableElementsToggler } from './research_disableable_elements_toggler';

export class ResearchDownloadHandler extends ResearchBaseRemoteHandler {
  constructor (containerSelector, elementSelector) {
    super(containerSelector, elementSelector);

    this.researchDownloadUuid = '#research_download_uuid';
    this.researchDownloadReportName = '#research_download_report_name';
    this.researchDownloadFormQueries = '#research_download_form_queries';
    this.researchHeaderName = '.research-header .name';
  }

  create () {
    $(this.element).on('click', () => {
      this.spinner.show();
      this._setDownloadFormValues();
      this._waitForDownloadStart();
    });
  }

  _setDownloadFormValues () {
    $(this.researchDownloadReportName).val($(this.researchHeaderName).html());
    $(this.researchDownloadFormQueries).val(JSON.stringify(this._getFormQueries()));
  }

  _waitForDownloadStart () {
    if (this._hasDownloadBeenStarted()) {
      this._removeDownloadCookie();
      this._hideSpinnerAndEnableDownloadButton();
    } else {
      setTimeout(this._waitForDownloadStart.bind(this), 50);
    }
  }

  _hasDownloadBeenStarted () {
    return document.cookie.match(this._getDownloadCookieName());
  }

  _getDownloadCookieName () {
    return `research_download_id_${$(this.researchDownloadUuid).val()}_started`;
  }

  _removeDownloadCookie () {
    document.cookie = this._getDownloadCookieName() + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';
  }

  _hideSpinnerAndEnableDownloadButton () {
    this.spinner.hide();
    ResearchDisableableElementsToggler.enable(['download']);
  }
}
