import { OptionProps } from 'react-select/src/types'

const control = (styles: object, props: OptionProps) => {
  return {
    ...styles,
    height: '41px',
    borderColor: props.isFocused ? '#00c9b2' : '#dfe3e9',
    boxShadow: props.isFocused ? '0 0 0 1px #00c9b2' : 'none',

    ':hover': {
      cursor: 'pointer',
      borderColor: '#dfe3e9',
    },
  }
}

export default control
