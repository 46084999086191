import { RemoteForm } from '../remote_form';

export class Profiles {
  constructor() {
    this.chartTimeSelectForm = '#profiles_chart_time_select_form';
    this.chartData = '#chosen_profile_chart';
    this.tableTimeSelectForm = '#profiles_table_time_select_form';
    this.tableData = '#profiles_table_data';

    this._initializeChartRemoteForm();
    this._initializeTableRemoteForm();
  }

  _initializeChartRemoteForm () {
    new RemoteForm(this.chartTimeSelectForm, this.chartData);
  }

  _initializeTableRemoteForm () {
    new RemoteForm(this.tableTimeSelectForm, this.tableData);
  }
}
