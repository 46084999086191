import React, { useState } from 'react'
import Input from 'javascripts/components/forms/Input'
import Button, { ButtonTheme } from 'javascripts/components/Button'
import NetworkTile from 'javascripts/components/UserSettings/NetworkTile'
import UserSettingsService from 'javascripts/services/UserSettingsService'
import { AxiosResponse, AxiosError } from 'axios'
import { AddNetworkContainerProps, IdentifierType, Identifier } from 'javascripts/components/UserSettings/types'
import { presenceValidation } from 'javascripts/utlis/inputValidators'
import { errorClass, ErrorFieldBox } from 'javascripts/components/forms/ErrorFieldBox'
import { NotificationType, useToastNotifier } from 'javascripts/components/ToastNotifier'

const AddNetworkContainer: React.FC<AddNetworkContainerProps> = ({
  inputId,
  inputLabel,
  identifiers,
  identifierType,
}) => {
  const [value, setValue] = useState('')
  const [values, setValues] = useState(identifiers)
  const [errors, setErrors] = useState([])
  const { Notifier, createNotifier } = useToastNotifier()
  const userSettingsService = new UserSettingsService()

  const addNetwork = (value: string) => {
    if (validForm(value)) {
      userSettingsService
        .addNetworkIdentifier(value, identifierType)
        .then(networkSuccessHandler)
        .catch(addNetworkIdentifierErrorHandler)
    }
  }

  const addNetworkIdentifierErrorHandler = (error: AxiosError) =>
    createNotifier(error.response.data.errors, NotificationType.ERROR)

  const removeNetwork = (itemId: number): void => {
    userSettingsService.removeNetworkIdentifier(itemId).then(networkSuccessHandler).catch(removeNetworkErrorHandler)
  }

  const networkSuccessHandler = (response: AxiosResponse) => {
    const { message, hb_identifiers, direct_identifiers } = response.data
    createNotifier(message, NotificationType.SUCCESS)
    setValue('')
    setNetwroks(identifierType, hb_identifiers, direct_identifiers)
  }
  const removeNetworkErrorHandler = (error: AxiosError) =>
    createNotifier(error.response.data.errors, NotificationType.ERROR)

  const setNetwroks = (
    identifierType: IdentifierType,
    hbIdentifiers: Identifier[],
    directIdentifiers: Identifier[]
  ) => {
    if (identifierType === IdentifierType.HB) {
      setValues(hbIdentifiers)
    } else {
      setValues(directIdentifiers)
    }
  }

  const validForm = (value: string): boolean => {
    const err = []
    presenceValidation('name', value, err)
    setErrors(err)
    return err.length === 0
  }

  return (
    <section className={inputId}>
      <div className='networks__form'>
        <div className={errorClass(errors)}>
          <Input
            id={inputId}
            name={inputId}
            label={inputLabel}
            value={value}
            onChange={(value) => setValue(value)}
            inputClassName='networks__input'
          />
          <ErrorFieldBox errors={errors} />
        </div>
        <Button className='networks__button--add' theme={ButtonTheme.Green} onClickHandler={() => addNetwork(value)}>
          Add
        </Button>
      </div>
      <div className='networks__items'>
        {values.map((item) => (
          <NetworkTile key={item.id} item={item} onRemoveHandler={removeNetwork} />
        ))}
      </div>
      {Notifier}
    </section>
  )
}

export default AddNetworkContainer
