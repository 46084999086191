import React from 'react'

interface Props {
  id?: string
  type?: string
  name?: string
  value?: string | number
  label?: string
  required?: boolean
  disabled?: boolean
  onChange: (input: string) => void
  inputClassName?: string
  placeholder?: string
  selectOnFocus?: boolean
  disableScroll?: boolean
  disableAutoComplete?: boolean
}

interface State {
  focused: boolean
  pureFocused: boolean
}

type Event = React.FormEvent<HTMLInputElement>

export default class Input extends React.Component<Props, State> {
  state = {
    focused: false,
    pureFocused: false,
  }

  handleChange = (event: Event) => {
    this.props.onChange(event.currentTarget.value)
  }

  handleFocus = (event: Event) => {
    this.setState({ focused: true, pureFocused: true })
    if (this.props.selectOnFocus) {
      event.currentTarget.select()
    }
  }

  handleWheel = (event: Event) => {
    if (this.props.disableScroll) {
      event.currentTarget.blur()
    }
  }

  handleBlur = () => this.setState({ focused: false, pureFocused: false })

  // workaround for handle browser autofill event (https://github.com/facebook/react/issues/1159)
  handleAutoFill = (e: { animationName: string }) =>
    this.setState({
      focused: e.animationName === 'onAutoFillStart',
    })

  getLabelStyle = (): string => {
    const { focused, pureFocused } = this.state

    return `text-input__label \
      ${focused || this.props.value?.toString() ? 'text-input__label--nested' : ''} \
      ${focused && pureFocused ? 'text-input__label--nested-focused' : ''}`
  }

  render() {
    const {
      id,
      type,
      name,
      value,
      label,
      required,
      disabled,
      inputClassName,
      placeholder,
      disableAutoComplete,
    } = this.props

    return (
      <div className='text-input'>
        <label htmlFor={id} className={this.getLabelStyle()}>
          {label}
        </label>
        <input
          className={`text-input__field ${inputClassName}`}
          id={id}
          type={type}
          name={name}
          value={value}
          required={required}
          disabled={disabled}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onAnimationStart={this.handleAutoFill}
          placeholder={placeholder}
          onWheel={(event) => this.handleWheel(event)}
          autoComplete={disableAutoComplete ? 'off' : 'on'}
        />
      </div>
    )
  }
}
