const multiValueRemove = (styles: object) => {
  return {
    ...styles,
    color: '#2e3947',
    cursor: 'pointer',
    paddingLeft: '0.5rem',
    paddingRight: '0',

    ':hover': {
      backgroundColor: 'inherit',
      color: '#2e3947',
    },
  }
}

export default multiValueRemove
