import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import CurrentUserContext, { CurrentUser } from 'javascripts/components/CurrentUser'
import ProfileContext, { ProfileContextProps } from 'javascripts/components/Profile'
import { ProfileFormValues, Endpoints, ProfileErrors } from 'javascripts/components/Profile/types'
import {
  profileFormInits,
  profileFormEndpointsInits,
  profileErrorsInits,
} from 'javascripts/components/Profile/initials'
import Input from 'javascripts/components/forms/Input'
import Button, { ButtonTheme } from 'javascripts/components/Button'
import ChangePasswordModal from 'javascripts/components/Profile/ChangePassword'
import DeleteAccountModal from 'javascripts/components/Profile/DeleteAccount'
import getCsrfToken from 'javascripts/utlis/getCsrfToken'
import { ErrorFieldBox } from 'javascripts/components/forms/ErrorFieldBox'
import { HttpStatusCode, redirect } from 'javascripts/components/Profile/httpHelper'

const ProfileForm: React.FC = () => {
  const currentUserCtx = useContext<Partial<CurrentUser>>(CurrentUserContext)
  const profileCtx = useContext<Partial<ProfileContextProps>>(ProfileContext)

  const [loading, setLoading] = useState<boolean>(true)
  const [valuesChanged, setValuesChanged] = useState<boolean>(false)
  const [values, setValues] = useState<ProfileFormValues>(profileFormInits)
  const [endpoints, setEndpoints] = useState<Endpoints>(profileFormEndpointsInits)
  const [changePwdModalOpen, setChangePwdModalOpen] = useState<boolean>(false)
  const [deleteAccountModalOpen, setdeleteAccountModalOpen] = useState<boolean>(false)
  const [errors, setErrors] = useState<ProfileErrors>(profileErrorsInits)

  useEffect(() => {
    setLoading(false)

    setValues(currentUserCtxValues)

    setEndpoints({
      profile: profileCtx.endpoints!.profile, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      updatePassword: profileCtx.endpoints!.updatePassword, // eslint-disable-line @typescript-eslint/no-non-null-assertion
      redirect: '/panel/',
      signOut: '/users/sign_out',
      signIn: '/users/sign_in',
    })
  }, [])

  useEffect(() => {
    setValuesChanged(!_.isEqual(values, currentUserCtxValues))
    setErrors(profileErrorsInits)
  }, [values])

  const currentUserCtxValues = {
    name: currentUserCtx.name ?? '',
    company: currentUserCtx.company_name ?? '',
    email: currentUserCtx.email ?? '',
  }

  const submitForm = () => {
    setLoading(true)

    axios
      .patch(endpoints.profile, {
        authenticity_token: getCsrfToken(),
        user: {
          name: values.name,
          company_name: values.company,
        },
      })
      .then(response => {
        if (response.status === HttpStatusCode.OK) {
          redirect(endpoints.redirect)
        } else if (response.status === HttpStatusCode.VALIDATION_ERROR) {
          setLoading(false)
          const errors = response.data.errors

          setErrors({
            name: errors.name,
            company: errors.company_name,
          })
        }
      })
  }

  return (
    <div className='profile__form'>
      <div className='profile__form--input-container'>
        <Input
          id='name'
          type='text'
          name='name'
          label='Name'
          value={values.name}
          onChange={value => setValues({ ...values, name: value })}
          disabled={loading}
        ></Input>
        {errors.name && <ErrorFieldBox prefix='Name' errors={errors.name}></ErrorFieldBox>}
      </div>

      <div className='profile__form--input-container'>
        <Input
          id='company'
          type='text'
          name='company'
          label='Company'
          value={values.company}
          onChange={value => setValues({ ...values, company: value })}
          disabled={loading}
        ></Input>
        {errors.company && <ErrorFieldBox prefix='Company' errors={errors.company}></ErrorFieldBox>}
      </div>

      <Input
        id='email'
        type='text'
        name='email'
        label='Your e-mail'
        value={values.email}
        onChange={value => setValues({ ...values, email: value })}
        disabled={true}
      ></Input>

      <div className='profile__reset-and-delete'>
        You can reset password.{' '}
        <a
          id='change-password'
          className='profile__reset-and-delete--link'
          onClick={() => setChangePwdModalOpen(true)}
        >
          Click here.
        </a>
      </div>

      {!profileCtx.profilesAuthorizer && (
        <div className='profile__reset-and-delete profile__delete'>
          You can delete an account.{' '}
          <a
            id='delete-account'
            className='profile__reset-and-delete--link'
            onClick={() => setdeleteAccountModalOpen(true)}
          >
            Click here.
          </a>
        </div>
      )}

      <div className='profile__buttons'>
        <Button theme={ButtonTheme.GreenOutline} href={endpoints.redirect} disabled={loading}>
          Cancel
        </Button>

        <Button
          theme={ButtonTheme.Green}
          onClickHandler={() => submitForm()}
          disabled={loading || !valuesChanged}
        >
          Update settings
        </Button>
      </div>

      <ChangePasswordModal
        endpoints={endpoints}
        shouldOpen={changePwdModalOpen}
        onCloseHandler={() => setChangePwdModalOpen(false)}
      ></ChangePasswordModal>

      <DeleteAccountModal
        endpoints={endpoints}
        shouldOpen={deleteAccountModalOpen}
        onCloseHandler={() => setdeleteAccountModalOpen(false)}
      ></DeleteAccountModal>
    </div>
  )
}

export default ProfileForm
