import { Switcher } from 'javascripts/switcher';

export class ResearchChartSwitcher {
  constructor (container) {
    this.container = container;
    this.switcherId = `${container.element.id}-switcher`;
  }

  create () {
    $(this.container.element).closest('.research-chart').append(this._getContainer());
    this._initializeSwitcher();
  }

  _getContainer () {
    return this._getTemplate().replace(ResearchChartSwitcher.SWITCHER_ID_TAG, this.switcherId);
  }

  _initializeSwitcher () {
    new Switcher(`#${this.switcherId}`)
      .onSwitch(this.container.chart.setShouldCompare.bind(this.container.chart)).run();
  }

  _getTemplate () {
    return $(ResearchChartSwitcher.TEMPLATE_SELECTOR).html();
  }
}

ResearchChartSwitcher.TEMPLATE_SELECTOR = "script#research-chart-switcher[type='text/template']";
ResearchChartSwitcher.SWITCHER_ID_TAG = '{switcher_id}';
