export class DailyUpliftOverview {
  constructor () {
    this.icon = '.icon-question-mark';
  }

  run () {
    this._initializeBenchmarkTooltip();
  }

  _initializeBenchmarkTooltip () {
    $(this.icon).tooltip();
  }
}
