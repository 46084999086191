import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Box from 'javascripts/components/Box'
import UserSettingsNetworksConatiner from './UserSettingsNetworksConatiner'
import { UserSettingsTabProps } from 'javascripts/components/UserSettings/types'
import UserListContainer from './UserListContainer'

const UserSettingsTabContainer: React.FC<UserSettingsTabProps> = ({
  hbIdentifiers,
  directIdentifiers,
  users,
}) => {
  const [selectedTab, setSelectedTab] = useState(0)

  return (
    <Box>
      <Tabs selectedIndex={selectedTab} onSelect={index => setSelectedTab(index)}>
        <header className='box__header'>
          <TabList>
            <Tab>
              <h3 className='box__title'>Network</h3>
            </Tab>
            <Tab>
              <h3 className='box__title'>Users</h3>
            </Tab>
          </TabList>
        </header>
        <hr className='line-separator' />

        <TabPanel>
          <UserSettingsNetworksConatiner
            hbIdentifiers={hbIdentifiers}
            directIdentifiers={directIdentifiers}
          />
        </TabPanel>
        <TabPanel>
          <UserListContainer users={users} />
        </TabPanel>
      </Tabs>
    </Box>
  )
}

export default UserSettingsTabContainer
