import { ResearchScopedSelector } from './__research_scoped_selector';
import { ScrollingAnimator } from '../scrolling_animator';
import { ResearchDisableableElementsToggler } from './research_disableable_elements_toggler';

export class ResearchEditHandler extends ResearchScopedSelector {
  constructor (uuid, researchForm) {
    super(uuid);

    this.researchForm = researchForm;
    this.editSelector = '.research-edit';
    this.formSelector = '.form';
  }

  create () {
    $(this._getScopedSelector(this.editSelector)).on('click', e => {
      e.preventDefault();

      this._toggleComponentsAvailability(true);
      this._scrollToContainerTop();
    });
  }

  _toggleComponentsAvailability (editAction) {
    $(this._getScopedSelector(this.formSelector)).toggle(editAction);
    $(this._getScopedSelector(this.editSelector)).toggle(!editAction);

    editAction
      ? ResearchDisableableElementsToggler.disable()
      : ResearchDisableableElementsToggler.tryToEnable();

    this.researchForm.updateFormState(true);
  }

  _scrollToContainerTop (referenceElement = this._getReportContainerSelector()) {
    new ScrollingAnimator(referenceElement).animate();
  }
}
