import { AdvancedTimeSelect } from './advancedTimeSelect'
import { ApplicableDataSelect } from './applicableDataSelect'
import { OverviewForm } from './overview_form'
import { TopListChart } from './top_list_chart'

export class Dashboard {
  constructor(isPublisher) {
    this.isPublisher = isPublisher
    this.overviewPerformanceForm = '#new_overview_performance_form'
    this.overviewData = '#overview-data'
    this.overviewTimeSelect = '#overview_performance_form_date'
    this.overviewApplicableDataSelect = '#overview_performance_form_applicable_data'
    this.demandChannelsReportChartPerformanceForm = '#demand_channels_report_chart_performance_form'
    this.demandChannelsReportChartData = '#demand-channels-report-chart-data'
    this.programmaticChannelChartPerformanceForm = '#programmatic_channel_chart_performance_form'
    this.programmaticChannelChartData = '#programmatic-channel-chart-data'
    this.topAdUnitsChartPerformanceForm = '#top_ad_units_chart_performance_form'
    this.topAdUnitsChartData = '#top-ad-units-chart-data'
    this.topCountriesChartPerformanceForm = '#top_countries_chart_performance_form'
    this.topCountriesChartData = '#top-countries-chart-data'
    this.devicesChartPerformanceForm = '#devices_chart_performance_form'
    this.devicesChartData = '#devices-chart-data'
    this.chartPerformanceForm = '#new_chart_performance_form'
    this.iconQuestionMark = '.icon-question-mark'
    this.headerBoxIds = [
      'top_ad_units_chart_performance_form',
      'top_countries_chart_performance_form',
      'devices_chart_performance_form',
      'demand_channels_report_chart_performance_form',
      'programmatic_channel_chart_performance_form',
    ]
  }

  run () {
    this._initializeTopListCharts()
    this._initializeRemoteForms()
    this._initializeQuestionMarkTooltips()
    this._initializeCustomSelects()
    this._initializeAdvancedTimeSelect()
    this._initializeApplicableDataSelect()
    this._initializeOverviewOnChange()
  }

  _initializeAdvancedTimeSelect() {
    this.AdvancedTimeSelect = new AdvancedTimeSelect(this.isPublisher)
    this.AdvancedTimeSelect.create()
  }

  _initializeApplicableDataSelect() {
    this.ApplicableDataSelect = new ApplicableDataSelect(this.isPublisher)
    this.ApplicableDataSelect.create()
  }

  _initializeTopListCharts () {
    new TopListChart(this.topAdxSitesChartData).run()
    new TopListChart(this.topAdUnitsChartData).run()
    new TopListChart(this.topCountriesChartData).run()
  }

  _initializeRemoteForms () {
    this.overviewForm = new OverviewForm(this.overviewPerformanceForm, this.overviewData)

    this.demandChannelsForm = new OverviewForm(
      this.demandChannelsReportChartPerformanceForm,
      this.demandChannelsReportChartData
    )

    this.programmaticChannelForm = new OverviewForm(
      this.programmaticChannelChartPerformanceForm,
      this.programmaticChannelChartData
    )

    this.topAdUnitsForm = new OverviewForm(this.topAdUnitsChartPerformanceForm, this.topAdUnitsChartData)

    this.topCountriesForm = new OverviewForm(
      this.topCountriesChartPerformanceForm,
      this.topCountriesChartData
    )

    this.devicesForm = new OverviewForm(this.devicesChartPerformanceForm, this.devicesChartData)
  }

  _initializeQuestionMarkTooltips () {
    $(this.iconQuestionMark).tooltip()
  }

  _initializeOverviewOnChange () {
    this.AdvancedTimeSelect.onChange(() => this._overviewOnChange())
    this.ApplicableDataSelect.onChange(() => this._overviewOnChange())
  }

  _overviewOnChange () {
    const timeInputValue = $(this.overviewTimeSelect).attr('value')
    const applicableDataInputValue = $(this.overviewApplicableDataSelect).attr('value')

    this._getForms().forEach((form) => form.submit(timeInputValue, applicableDataInputValue))
    this._dispatchOverviewChangeEvent(timeInputValue, applicableDataInputValue)
  }

  _dispatchOverviewChangeEvent (date, applicableData) {
    const event = new CustomEvent('OverviewChange', { detail: { date, applicableData } })
    window.dispatchEvent(event)
  }

  _getForms () {
    return [
      this.demandChannelsForm,
      this.programmaticChannelForm,
      this.topAdUnitsForm,
      this.topCountriesForm,
      this.devicesForm,
    ].filter((form) => form !== undefined)
  }

  _getSliderOptions () {
    return {
      slidesToShow: 5,
      slidesToScroll: 1,
      infinite: false,
      initialSlide: 26,
      speed: 125,
    }
  }

  _initializeCustomSelects () {
    let selects = []
    this.headerBoxIds.forEach((id) => {
      selects = [...selects, `#${id}--time`, `#${id}--metric`]
    })

    selects.forEach((selectId) => {
      $(selectId).select2({
        minimumResultsForSearch: -1,
        dropdownPosition: 'below',
        dropdownCssClass: 'select2-dropdown__dashboard',
      })
    })
  }
}
