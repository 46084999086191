import React from 'react'

import { NetworkTileProps } from './types'

const NetworkTile: React.FC<NetworkTileProps> = ({ item, onRemoveHandler }) => {
  return (
    <div className='network-tile'>
      <div className='close' onClick={() => onRemoveHandler(item.id)}>
        &times;
      </div>
      {item.name}
    </div>
  )
}

export default NetworkTile
