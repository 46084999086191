import React from "react";

interface Props {
  step: number
}

export const StepBar: React.FunctionComponent<Props> = ({step}) => {
  return (
    <div className="multi-steps__container">
      <span className="multi-steps__counter">{step}/3</span>
      <ul className="multi-steps__progress">
        <li className="dot is-active"></li>
        <li className={`dot ${step >= 2  ? 'is-active' : ''}`}></li>
        <li className={`dot ${step >= 3  ? 'is-active' : ''}`}></li>
      </ul>
    </div>
  );
}
