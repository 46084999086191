import React from "react";
import { NoNewNetworks } from '../components/NetworksForm/NoNewNetworks'
import { NetworksForm } from '../containers/NetworksForm/NetworksForm'
import { Props } from '../components/NetworksForm/NetworksFormProps'

export const NetworksConnection: React.FunctionComponent<Props> = (props) => {
  if (props.dfp_networks_form.noUnconnectedNetworks) {
    return <NoNewNetworks />
  } else {
    return <NetworksForm dfp_networks_form={props.dfp_networks_form} />
  }
}
