import { Spinner } from 'javascripts/spinner';

export class ProfilesTable {
  constructor () {
    this.chartLinks = '.chart-link';
    this.chartData = '#chosen_profile_chart';
    this.spinner = new Spinner(this.chartData);
  }

  run () {
    this._initializeSpinner();
  }

  _initializeSpinner () {
    $(this.chartLinks).on('ajax:before', () => {
      $('html, body').animate({ scrollTop: 0 }, 'slow');
      this.spinner.show();
    });

    $(this.chartLinks).on('ajax:success', () => {
      this.spinner.hide();
    });
  }
}
