import axios from 'axios'
import getCsrfToken from 'javascripts/utlis/getCsrfToken'
import CurrencyCodes from 'javascripts/components/forms/Select/CurrencySelect/CurrencyCodes'
import {
  Publisher,
  PublisherErrors,
  AdUnit,
  User,
  PublisherDto,
  AdUnitDto,
  WebsiteDto,
  UserDto,
  Website,
  PublisherDomainDto,
  PublisherDomain,
} from 'javascripts/components/Publisher/types'

import { v4 as uuidv4 } from 'uuid'

export const createPublisher = (profileId: number, publisher: Publisher) => {
  const url = `/panel/${profileId}/publishers`
  const data = {
    publisher: convertToPublisherDto(publisher),
    ad_units: convertToAdUnitsDto(publisher.adUnits),
    websites: convertToWebsitesDto(publisher.websites),
    users_to_invite: convertToUserEmail(publisher.users),
    domains: convertToDomainDto(publisher.domains),
    authenticity_token: getCsrfToken(),
  }
  return axios.post(url, data)
}

export const validatePublisher = (profileId: number, publisher: Publisher) => {
  const url = `/panel/${profileId}/publishers/validate`
  const data = {
    publisher: convertToPublisherDto(publisher),
    ad_units: convertToAdUnitsDto(publisher.adUnits),
    websites: convertToWebsitesDto(publisher.websites),
    users_to_invite: convertToUserEmail(publisher.users),
    domains: convertToDomainDto(publisher.domains),
    authenticity_token: getCsrfToken(),
  }
  return axios.post(url, data)
}

export const getPublisherById = (profileId: number, publisherId: number) => {
  const url = `/panel/${profileId}/publishers/${publisherId}`
  return axios.get(url)
}

export const updatePublisherData = (publisher: Publisher) => {
  const url = `update`
  const data = {
    publisher: convertToPublisherDto(publisher),
    authenticity_token: getCsrfToken(),
  }
  return axios.patch(url, data)
}

export const updateInventory = (adUnits: AdUnit[], websites: Website[]) => {
  const url = `inventory`
  const data = {
    inventory: {
      ad_units: adUnits.map((adUnit) => adUnit.id),
      websites: websites.map((website) => website.id),
    },
    authenticity_token: getCsrfToken(),
  }
  return axios.patch(url, data)
}

export const updateUsers = (users: User[]) => {
  const url = `invite`
  const data = {
    users_to_invite: convertToUserEmail(users),
    authenticity_token: getCsrfToken(),
  }
  return axios.patch(url, data)
}

export const updateMargins = (adUnits: AdUnit[], websites: Website[]) => {
  const url = `margins`
  const data = {
    margins: {
      ad_units: convertToAdUnitsDto(adUnits),
      websites: convertToWebsitesDto(websites),
    },
    authenticity_token: getCsrfToken(),
  }
  return axios.patch(url, data)
}

export const updateDomains = (domains: PublisherDomain[]) => {
  const url = `domains`
  const data = {
    domains: convertToDomainDto(domains),
    authenticity_token: getCsrfToken(),
  }
  return axios.patch(url, data)
}

export const proccessPublisher = (
  publisher: PublisherDto,
  adUnits: AdUnitDto[],
  websites: WebsiteDto[],
  users: [],
  domains: PublisherDomainDto[]
): Publisher => {
  return {
    name: publisher.name,
    currency: CurrencyCodes.find((codes) => codes.value === publisher.currency_code),
    language: { value: 'en', label: 'English' },
    email: publisher.email,
    adUnits: convertToAdUnits(adUnits),
    websites: convertToWebsites(websites),
    users: convertToUsers(users),
    margin: convertMarginToValue(publisher.margin),
    id: publisher.id,
    domains: convertToDomains(domains),
    crmId: publisher.crm_id,
  }
}

export const convertToPublisherErrors = (
  publisherErrors: { [key: string]: string[] }
): PublisherErrors => {
  return {
    name: publisherErrors.name ?? [],
    currency: publisherErrors.currency_code ?? [],
    language: publisherErrors.language ?? [],
    email: publisherErrors.email ?? [],
    crmId: publisherErrors.crm_id ?? [],
  }
}

const convertToPublisherDto = (publisher: Publisher): PublisherDto => {
  return {
    name: publisher.name,
    currency_code: publisher.currency.value,
    language: publisher.language.value,
    email: publisher.email,
    margin: convertMarginToPercent(publisher.margin),
    crm_id: publisher.crmId,
  }
}

const convertToAdUnitsDto = (adUnits: AdUnit[]) =>
  adUnits.map((adUnit) => ({
    ad_unit_id: adUnit.id,
    admin_cost: convertMarginToPercent(adUnit.adminCost),
  }))

const convertToWebsitesDto = (websites: Website[]) =>
  websites.map((website) => ({
    site_id: website.id,
    name: website.name,
    admin_cost: convertMarginToPercent(website.adminCost),
  }))

const convertMarginToPercent = (margin: number) => margin / 100
const convertMarginToValue = (margin: number) => parseFloat((margin * 100).toFixed(2))

const convertToAdUnits = (adUnits: AdUnitDto[]): AdUnit[] =>
  adUnits.map((adUnit) => ({
    id: adUnit.ad_unit_id,
    name: adUnit.ad_unit_name,
    adminCost: convertMarginToValue(adUnit.admin_cost),
  }))

const convertToWebsites = (websites: WebsiteDto[]): Website[] =>
  websites.map((website) => ({
    id: website.site_id,
    name: website.name,
    adminCost: convertMarginToValue(website.admin_cost)
  }))

const convertToUsers = (users: UserDto[]): User[] =>
  users.map((user) => ({
    id: user.user_id,
    name: user.name,
    email: user.email,
    isNew: false,
  }))

const convertToUserEmail = (users: User[]) => users.map((user) => user.email)

const convertToDomains = (domains: PublisherDomainDto[] = []): PublisherDomain[] =>
  domains.map((domain) => ({
    domainName: domain.domain_name,
    adminCost: convertMarginToValue(domain.admin_cost),
    uuid: uuidv4(),
  }))

const convertToDomainDto = (domains: PublisherDomain[] = []): PublisherDomainDto[] =>
  domains.map((domain) => ({
    domain_name: domain.domainName,
    admin_cost: convertMarginToPercent(domain.adminCost),
  }))
