const loadingMessage = (styles: object) => {
  return {
    ...styles,
    color: '#2e3947',
    fontSize: '12px',
    lineHeight: '28px',
    textAlign: 'left' as const,
    padding: '0 12px',
  }
}

export default loadingMessage
