export class ResearchScopedSelector {
  constructor (uuid) {
    this.uuid = uuid;
    this.container = '.research-report';
  }

  _getScopedSelector (selector) {
    return `${this._getReportContainerSelector()} ${selector}`;
  }

  _getReportContainerSelector () {
    return `${this.container}[data-uuid="${this.uuid}"]`;
  }
}
