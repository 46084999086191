import React from 'react'

import { TabViewProps } from 'javascripts/components/Publisher/types'

import PublisherDataContainer from './PublisherDataContainer'
import PublisherWrapperForm from './PublisherWrapperForm'

const PublisherContainer: React.FC<TabViewProps> = (props) => {
  return (
    <PublisherDataContainer {...props}>
      <PublisherWrapperForm />
    </PublisherDataContainer>
  )
}

export default PublisherContainer
