import React, { useState, useContext } from 'react'
import Button, { ButtonTheme } from 'javascripts/components/Button'
import { TabViewProps, User } from 'javascripts/components/Publisher/types'
import { presenceValidation, emailFormatValidation } from 'javascripts/utlis/inputValidators'
import UsersForm from 'javascripts/components/Publisher/UsersForm'
import * as PublisherService from 'javascripts/services/PublisherService'
import { useToastNotifier, NotificationType } from 'javascripts/components/ToastNotifier'
import { AxiosError } from 'axios'
import CurrentProfileContext, { CurrentProfile } from 'javascripts/components/CurrentProfile'
import { useSimpleLoader } from 'javascripts/components/SimpleLoader'

const LOADER_TIMEOUT = 500

const UsersContainer: React.FC<TabViewProps> = ({ publisher, updateUsers, onGetPublisher }) => {
  const currentProfileCtx = useContext<Partial<CurrentProfile>>(CurrentProfileContext)
  const [userEmail, setUserEmail] = useState('')
  const [errors, setErrors] = useState([])
  const { isLoading, setIsLoading, SimpleLoader } = useSimpleLoader(false)
  const { Notifier, createNotifier } = useToastNotifier()
  const publisherEndspoint = `/panel/${currentProfileCtx.id}/publishers`

  const addUser = () => {
    const isInputValid = validInput()
    if (isInputValid) createNewUser()
  }

  const validInput = (): boolean => {
    const err = []
    presenceValidation('email', userEmail, err)
    emailFormatValidation(userEmail, err)
    setErrors(err)

    return err.length === 0
  }

  const createNewUser = () => {
    const user = { email: userEmail, isNew: true }
    updateUsers([...publisher.users, user])
    setUserEmail('')
  }

  const removeUser = (user: User) => {
    const newUsers = publisher.users.filter((u: User) => u.email !== user.email || u.isNew === false)
    updateUsers(newUsers)
  }

  const save = () => {
    setIsLoading(true)
    PublisherService.updateUsers(publisher.users)
      .then(() => onGetPublisher(publisher.id))
      .then(() => createNotifier('Publisher users updated!', NotificationType.SUCCESS))
      .catch(updateUsersErrorHandler)
      .finally(() => setIsLoading(false, LOADER_TIMEOUT))
  }

  const updateUsersErrorHandler = (error: AxiosError) =>
    createNotifier(createErrorMessage(error.response.data.errors), NotificationType.ERROR)

  const createErrorMessage = (errors: any) => {
    return Object.entries(errors)
      .map(([key, value]) => `${key} ${value}`)
      .join('\n')
  }

  return (
    <div className='tab-view'>
      <h3>Grant access for external users</h3>
      {isLoading ? (
        <SimpleLoader />
      ) : (
        <>
          <UsersForm
            publisher={publisher}
            errors={errors}
            addUser={addUser}
            userEmail={userEmail}
            setUserEmail={setUserEmail}
            onRemoveUser={removeUser}
          />

          <footer className='tab-view__footer'>
            <Button theme={ButtonTheme.GreenOutline} href={publisherEndspoint}>
              Back
            </Button>
            <Button theme={ButtonTheme.Green} onClickHandler={save}>
              Save
            </Button>
          </footer>
        </>
      )}
      {Notifier}
    </div>
  )
}

export default UsersContainer
