import React from 'react'

interface Props {
  className?: string
  id?: string
}

const Box: React.FC<Props> = ({ className, id, children }) => {
  return <div id={id} className={`box ${className}`}>{children}</div>
}

export default Box
