import React from 'react'
import PublisherTabList from 'javascripts/components/Publisher/PublisherTabList'
import { TabListProps } from 'javascripts/components/Publisher/types'

const TabContainer: React.FC<TabListProps> = ({ selectedTab, isNew, onSetSelectedTab, disabledTabs }) => {
  return (
    <>
      <PublisherTabList
        selectedTab={selectedTab}
        isNew={isNew}
        onSetSelectedTab={onSetSelectedTab}
        disabledTabs={disabledTabs}
      />
      <hr className='line-separator' />
    </>
  )
}

export default TabContainer
