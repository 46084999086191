const multiValue = (styles: object) => {
  return {
    ...styles,
    height: '1.8rem',
    padding: '0 0.4rem 0 0.8rem',
    backgroundColor: '#009897',
    border: 'none',
    borderRadius: '4px',
    margin: '5px 5px 0 0',
  }
}

export default multiValue
