import React from 'react'
import { PublisherData } from './types'
import Button, { ButtonTheme } from '../Button'

interface Props {
  index?: number
  publisher: PublisherData
}

const PublisherListItem: React.FC<Props> = ({ publisher }) => {
  const editPublisherEndpoint = `publishers/${publisher.publisher_id}/edit`

  return (
    <div className='publisher-list__row'>
      <div className='publisher-list__cell'>{publisher.publisher_id}</div>
      <div className='publisher-list__cell'>{publisher.crm_id}</div>
      <div className='publisher-list__cell' title={publisher.name}>{publisher.name}</div>
      <div className='publisher-list__cell'>{publisher.original_revenue.toFixed(2)}</div>
      <div className='publisher-list__cell'>{publisher.ad_impressions}</div>
      <div className='publisher-list__cell'>{publisher.tech_cost.toFixed(2)}</div>
      <div className='publisher-list__cell'>{publisher.net_revenue.toFixed(2)}</div>
      <div className='publisher-list__cell'>{publisher.admin_cost.toFixed(2)}</div>
      <div className='publisher-list__cell'>{publisher.margin_base.toFixed(2)}</div>
      <div className='publisher-list__cell'>{publisher.revenue.toFixed(2)}</div>
      <div className='publisher-list__cell'>{publisher.currency_code}</div>
      <div className='publisher-list__cell publisher-list__cell-button'>
        <Button
          theme={ButtonTheme.Green}
          href={editPublisherEndpoint}
          className='publisher-list__button'
        >
          Edit
        </Button>
      </div>
    </div>
  )
}

export default PublisherListItem
