import { BaseChartTooltip } from './_base_chart_tooltip';

export class MultilineChartTooltip extends BaseChartTooltip {
  _getMessage({ data, label }) {
    const records = this.chart.tooltipValuesWithChange ? this._getRecordsWithChange(data) : data;
    return [this._getDateHeaderWith(label), this.getTooltipRowsWithColors(records)].join('');
  }

  _getAnchor(data) {
    return Math.max(...data.data.map(d => d.value));
  }

  _getClassNameFor(label) {
    return this.chart.cssClassProvider.getClassFor('tooltip-info', label);
  }

  _getRecordsWithChange(records) {
    const valueChange = (records[0].value - records[1].value);
    const percentageChange = this._getPercentageValue(valueChange, records[1].value);
    const valueColorClass = percentageChange >= 0 ? 'value-change-positive' : 'value-change-negative';

    return [...records, {
      type: 'Uplift',
      value: `<span class="${valueColorClass}">${this._getPrettyValueWithUnit(valueChange.toFixed(2))}</span> (${percentageChange}%)</span>`
    }];
  }
}
