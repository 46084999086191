import { ResearchScopedSelector } from './__research_scoped_selector';
import { ResearchDisableableElementsToggler } from './research_disableable_elements_toggler';

export class ResearchDeleteChartHandler extends ResearchScopedSelector {
  constructor (uuid) {
    super(uuid);

    this.deleteChartButton = '.research-delete-chart';
    this.cancelableClass = '.cancelable';
  }

  create () {
    this._setDeleteChartButtonClickEvent();
  }

  _setDeleteChartButtonClickEvent () {
    $(this._getScopedSelector(this.deleteChartButton)).on('click', e => {
      e.preventDefault();

      if (this._isOnlyOneContainerPresent()) {
        window.location.reload();
      } else {
        this._animateContainerRemoval();
        $(document).trigger('reportContainerRemoved');
      }
    });
  }

  _animateContainerRemoval () {
    const container = $(this._getReportContainerSelector());

    container.fadeOut(ResearchDeleteChartHandler.FADE_OUT_DURATION_IN_MS, () => {
      container.remove();
      this._updateClickableElementsAvailability();
    });
  }

  _updateClickableElementsAvailability () {
    ResearchDisableableElementsToggler.tryToEnable();
  }

  _isOnlyOneContainerPresent () {
    return $(this.container).not(this.cancelableClass).length === 1;
  }
}

ResearchDeleteChartHandler.FADE_OUT_DURATION_IN_MS = 250;
