import { AdvancedTimeSelect } from '../advancedTimeSelect';

export class ResearchDisableableElementsToggler {
  static tryToEnable (keys = this._getDefaultKeys()) {
    if (this._noVisibleResearchForm()) {
      this.enable(keys);
    }
  }

  static enable (keys = this._getDefaultKeys()) {
    this._changeDisabledState(keys, false);
  }

  static disable (keys = this._getDefaultKeys()) {
    this._changeDisabledState(keys, true);
  }

  static _getDefaultKeys () {
    return Object.keys(this.ELEMENTS);
  }

  static _noVisibleResearchForm () {
    return $(`${this.RESEARCH_FORM}:visible`).length === 0;
  }

  static _changeDisabledState (keys, disable) {
    keys.forEach(key => {
      const element = $(this.ELEMENTS[key]);

      element.attr('disabled', disable);
      disable ? element.addClass('disabled') : element.removeClass('disabled');
    });
  }
}

ResearchDisableableElementsToggler.RESEARCH_FORM = '.new_research_form';
ResearchDisableableElementsToggler.ELEMENTS = {
  share: '.research-share-report',
  download: '.research-download',
  saveReport: '.research-save-report',
  time: AdvancedTimeSelect.SELECTED_OPTION
};
