import { ResearchChartContainer } from '../research_chart/research_chart_container';

export class ResearchChartMetricsBar {
  constructor (container) {
    this.container = container;
  }

  create () {
    $(this.container.element.parentElement).before(this._getContainer());
    this._setClickEvent();
  }

  _getContainer () {
    return this._getTemplate().replace(this._getButtonTemplate(), this._getButtons());
  }

  _setClickEvent () {
    $(this.container.element.parentElement).siblings().find('.button-primary').on('click', this._clickHandler.bind(this));
  }

  _getTemplate () {
    return $(ResearchChartMetricsBar.TEMPLATE_SELECTOR).html();
  }

  _getButtonTemplate () {
    return this._getTemplate().match(ResearchChartMetricsBar.BUTTON_REGEX)[0];
  }

  _getButtons () {
    return this._getMetrics().map(this._getButton.bind(this)).join('');
  }

  _getMetrics () {
    return Object.keys(this.container.data);
  }

  _getButton (metric) {
    return this
      ._getButtonTemplate()
      .replace(ResearchChartMetricsBar.ACTIVE_CLASS_TAG, this._getButtonClassNameFor(metric))
      .replace(ResearchChartMetricsBar.DATA_METRIC_TAG, metric)
      .replace(ResearchChartMetricsBar.METRIC_NAME_TAG, this.container.translations[metric]);
  }

  _getButtonClassNameFor (metric) {
    return metric === ResearchChartContainer.DEFAULT_METRIC ? ResearchChartMetricsBar.ACTIVE_CLASS_NAME : '';
  }

  _clickHandler (event) {
    $(event.target).siblings().removeClass(ResearchChartMetricsBar.ACTIVE_CLASS_NAME);
    $(event.target).addClass(ResearchChartMetricsBar.ACTIVE_CLASS_NAME);
    this.container.redraw(event.target.dataset.metric);
  }
}

ResearchChartMetricsBar.TEMPLATE_SELECTOR = "script#research-chart-metrics-bar[type='text/template']";
ResearchChartMetricsBar.ACTIVE_CLASS_TAG = '{active_class}';
ResearchChartMetricsBar.DATA_METRIC_TAG = '{data_metric}';
ResearchChartMetricsBar.METRIC_NAME_TAG = '{metric_name}';
ResearchChartMetricsBar.BUTTON_REGEX = /<a(.|[\r\n])*<\/a>/;
ResearchChartMetricsBar.ACTIVE_CLASS_NAME = 'active';
