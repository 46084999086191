import { RemoteForm } from './remote_form'

export class OverviewForm extends RemoteForm {
  constructor (formContainer: string, dataContainer: string) {
    super(formContainer, dataContainer)
  }

  submit (time: string, applicableData: string) {
    const form = this._prepareForm(time, applicableData, $(this.formContainer))
    this._isFormExists(form) && Rails.fire(form, 'submit')
  }

  _prepareForm (time: string, applicableData: string, form: JQuery<HTMLElement>) {
    const timeInput = $('<input>').attr('type', 'hidden').attr('name', 'chart_performance_form[time]').val(time)
    const applicableDataInput = $('<input>').attr('type', 'hidden').attr('name', 'chart_performance_form[applicable_data]').val(applicableData)
    return form.append(timeInput).append(applicableDataInput)[0]
  }

  _isFormExists (form: HTMLElement) {
    return Boolean(form)
  }

}
