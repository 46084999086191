import React from 'react';

import { DropdownToggleProps } from './types';

const DropdownToggle: React.FC<DropdownToggleProps> = ({ onClickHandler, children }) => {
  return (
    <div
      className="dropdown__toggle"
      onClick={onClickHandler}
    >
      { children }
    </div>
  )
};

export default DropdownToggle;
