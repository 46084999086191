import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
  href: string
  className?: string
}

const RoundBtn: React.FC<Props> = ({ children, href, className }) => {
  return (
    <a className={`round-btn ${className}`} href={href}>
      <div className='ball'></div>
      <div className='round-btn__content'>{children}</div>
    </a>
  )
}

export default RoundBtn
