import React, { useLayoutEffect, useState } from 'react'
import Button, { ButtonTheme } from 'javascripts/components/Button'

interface Props {
  backHandler?: Function
  backHref?: string
  saveHandler?: Function
}

const PublisherFinanceFooter: React.FC<Props> = ({ backHandler, backHref, saveHandler }) => {
  const [width, setWidth] = useState(document.getElementById('main-wrapper-item').getBoundingClientRect().width - 2)

  const resizeHandler = () => setWidth(document.getElementById('main-wrapper-item').getBoundingClientRect().width - 2)

  useLayoutEffect(() => {
    window.addEventListener('resize', () => resizeHandler())
    return () => {
      window.removeEventListener('resize', () => resizeHandler())
    }
  })

  return (
    <footer className='tab-view__footer tab-view__footer--fixed' style={{ width: `${width}px` }}>
      <Button theme={ButtonTheme.GreenOutline} href={backHref} onClickHandler={backHandler}>
        Back
      </Button>
      <Button theme={ButtonTheme.Green} onClickHandler={saveHandler}>
        Save
      </Button>
    </footer>
  )
}

export default PublisherFinanceFooter
