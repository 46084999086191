export class FilterWrapper {
  constructor (dataContainer) {
    this.dataContainer = dataContainer;
    this.filtersWrapper = $('.adx-performance-filters');
    this.viewToggle = $('.adx-performance-filters__toggle');
  }

  create () {
    this._addOnClickEvent();
  }

  _addOnClickEvent () {
    this.viewToggle.click(this._filterWrapperToggle.bind(this));
  }

  _filterWrapperToggle () {
    this.filtersWrapper.toggleClass('adx-performance-filters--hidden');
    this.viewToggle.toggleClass('adx-performance-filters__toggle--hidden');
    this.viewToggle.text(
      this.viewToggle.is('.adx-performance-filters__toggle--hidden')
        ? 'Show filters'
        : 'Hide filters'
    );
  }
}
