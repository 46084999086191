import { SspConfiguration } from '../types'

import PubmaticLogo from 'images/pubmatic_logo.png'
import PubmaticSmallLogo from 'images/pubmatic_small_logo.png'

const pubmaticConfig: SspConfiguration = {
  platform: 'pubmatic',
  platformName: 'Pubmatic',
  logo: PubmaticLogo,
  smallLogo: PubmaticSmallLogo,
}

export default pubmaticConfig
