import React, { useState } from 'react'
import Input from '../components/forms/Input'
import logoDark from 'images/logo-dark.svg'
import getCsrfToken from '../utlis/getCsrfToken'

type InputNames = 'email' | 'password'

export const SessionsNew: React.FunctionComponent = () => {
  const [form, setValues] = useState({
    email: '',
    password: '',
  })

  const handleInputChange = (name: InputNames, value: string) => {
    setValues({
      ...form,
      [name]: value,
    })
  }

  return (
    <div className='registration registration--background-image-sign-in'>
      <div className='registration__container registration__container--row'>
        <div className='sign-in'>
          <img className='registration__logo' src={logoDark} alt='Insight Yieldbird' />
          <form
            id='new_user'
            className='registration__form form'
            action='/users/sign_in'
            noValidate
            acceptCharset='UTF-8'
            method='post'
          >
            <div className='registration__header'>
              <h2 className='registration__title-welcome'>Welcome back!</h2>
            </div>
            <input name='utf8' type='hidden' value='✓' />
            <input type='hidden' name='authenticity_token' value={getCsrfToken()} />
            <div className='form__body'>
              <Input
                id='user_email'
                type='email'
                name='user[email]'
                label='Your email'
                value={form.email}
                onChange={value => {
                  handleInputChange('email', value)
                }}
              />
              <Input
                id='user_password'
                type='password'
                name='user[password]'
                label='Password'
                value={form.password}
                onChange={value => {
                  handleInputChange('password', value)
                }}
              />
            </div>

            <div className='form__buttons form__buttons--left'>
              <input
                className='button'
                type='submit'
                name='commit'
                value='Log in'
                data-disable-with='Log in'
              />
            </div>

            <div>
              <div className='recovery'>
                <p>
                  Forgot password? You can reset it.{' '}
                  <a className='sign-in__recovery-link' href='/users/password/new'>
                    Click here.
                  </a>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
