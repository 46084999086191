import { MultipleSelect } from 'javascripts/filters/components/multiple_select';
import { ResearchScopedSelector } from './__research_scoped_selector';

export class ResearchDynamicFilter extends ResearchScopedSelector {
  constructor(uuid, filterRowId) {
    super(uuid);

    this.filterRowId = filterRowId;
    this.researchBodySelector = '.research';
    this.dynamicFilterSelector = '.dynamic-filter';
  }

  create () {
    this._setSequenceId();
    this._setDeleteClickEvent();
    this._setDimensionChangeEvent();
    this._setMethodSelectChangeEvent();
    this._setMethodSelectValues();
    this._initializeSelectDropdowns();
    this._setDynamicFiltersToggleEvent();
    this._setDataInputType(this._getMethodSelect().val(), false);
  }

  _setSequenceId () {
    this.id = this._getFiltersCount() + 1;

    const body = this._getLastDynamicFilter();
    const templateWithId = body.html().replace(/filter_id/g, this.id);

    body.html(templateWithId);

    this._getLastDynamicFilter().attr('id', this.id);
    $(this.researchBodySelector).data('dynamic-filters-count', this.id);
    this.dataBody = new XMLSerializer().serializeToString(this._getRawData());
  }

  _setDeleteClickEvent () {
    this._getBody()
      .find('a.research__delete-filter-btn')
      .on('click', e => {
        e.preventDefault();
        this._getBody().remove();
      });
  }

  _setDimensionChangeEvent () {
    this._getFiltersDimension().on('change', () => {
      this._setMethodSelectValues();
      this._setDataInputType(this._getMethodSelect().val());
    });
  }

  _setMethodSelectChangeEvent () {
    this._getMethodSelect().on('change', e => {
      const methodSelectValue = e.target.value;

      if (this._isMethodSelectValueSearchable(methodSelectValue) === this._isDataTextInput()) {
        this._setDataInputType(methodSelectValue);
      }
    });
  }

  _setMethodSelectValues () {
    const methodSelect = this._getMethodSelect();
    const selectedValue = methodSelect.val();

    methodSelect[0].options.length = 0;

    this._getMethodSelectOptions(methodSelect).forEach((e, i) => {
      const selected = e[1] === selectedValue;
      methodSelect[0].options[i] = new Option(e[0], e[1], selected, selected);
    });
  }

  _initializeSelectDropdowns () {
    this._getBody()
      .find('.dynamic-filter__select')
      .each((_, element) => {
        this._initializeSelect2(element);
      });
  }

  _initializeSelect2 (element) {
    $(element).select2({
      theme: element.className,
      minimumResultsForSearch: -1,
      dropdownPosition: 'below',
      dropdownCssClass: 'select2-dropdown__research'
    });
  }

  _setDynamicFiltersToggleEvent () {
    $(this._getBody()).on('dynamicFilter:toggleDynamicFilters', (event, disabledFilters) => {
      this._toggleSelectedFilters(disabledFilters.split(','));
      this._toggleUnselectedFilters(this._formatedDimensionValues(disabledFilters.split(',')));
    });
  }

  _toggleSelectedFilters (disabledFilters) {
    const selectedFilterDimension = this._getFiltersDimension()
      .find('option:selected')
      .val();
    if ($.inArray(selectedFilterDimension, disabledFilters) >= 0) {
      this._disableChosenFilter();
    } else {
      this._enableChosenFilter();
    }
  }

  _toggleUnselectedFilters (filtersToDisable) {
    const filtersToEnable = $(ResearchDynamicFilter.ALL_FILTERS)
      .not(filtersToDisable)
      .get();
    const dimensionSelect = this._getFiltersDimension();
    dimensionSelect.find(filtersToDisable.join(', ')).prop('disabled', true);
    dimensionSelect.find(filtersToEnable.join(', ')).prop('disabled', false);

    const elementWithoutSelect2 = dimensionSelect.select2('destroy').get(0);
    this._initializeSelect2(elementWithoutSelect2);
  }

  _formatedDimensionValues (options) {
    return $.map(options, option => {
      return 'option[value="' + option + '"]';
    });
  }

  _disableChosenFilter () {
    this._toggleChosenFilterDisabledState(true);
  }

  _enableChosenFilter () {
    this._toggleChosenFilterDisabledState(false);
  }

  _toggleChosenFilterDisabledState (shouldDisable) {
    this._getBody()
      .find('select, input')
      .each((_, inputElement) => {
        $(inputElement).prop('disabled', shouldDisable);
      });
  }

  _setDataInputType (methodSelectValue, clearSelect = true) {
    if (this._isMethodSelectValueSearchable(methodSelectValue)) {
      this._initializeDataSelect(clearSelect);
    } else {
      this._initializeDataInput();
    }
  }

  _initializeDataSelect (clearSelect) {
    const dataSelector = this._getScopedSelector(`#${this._getRawData().id}`);

    this._getFilterInput().replaceWith(this.dataBody.replace(/input/, 'select'));
    this._setDataSelectAttributes(clearSelect);

    new MultipleSelect(dataSelector, {
      theme: 'dynamic-filter'
    }).create();

    if (clearSelect) {
      this._getFilterInput()
        .val([])
        .trigger('change');
    }

    if ($.inArray(this._getFiltersDimension().val(), ['device', 'product', 'programmatic_channel']) >= 0) {
      this._setDataSelectEvent(dataSelector);
    }
  }

  _initializeDataInput () {
    const select = this._getBody()
      .find('.select2-container')
      .not('.select2-container--dynamic-filter__select');

    if (select.get(0)) {
      this._getFilterInput().replaceWith(this.dataBody);
      this._getFilterInput().val('');
      select.remove();
    }
  }

  _setDataSelectAttributes (clearSelect) {
    const dataSelect = this._getFilterInput();
    const dimensionValue = this._getFiltersDimension().val();

    dataSelect.data(this._getDataSelectAttributes(dataSelect, dimensionValue, clearSelect));
  }

  _setDataSelectEvent (dataSelector) {
    $(dataSelector).on('select2:select', e => {
      const dataSelect = $(e.target);
      const allOptionsNotSelected = dataSelect.val().length !== dataSelect.data('options').length;

      if (allOptionsNotSelected) {
        dataSelect.select2('open');
      }
    });
  }

  _getMethodSelectOptions (methodSelect) {
    const nonSearchableMethodOptions = methodSelect.data('non-searchable-method-options');
    const searchableMethodOptions = methodSelect.data('searchable-method-options');

    return this._isDimensionOnlySearchable(this._getFiltersDimension().val())
      ? searchableMethodOptions
      : [...nonSearchableMethodOptions, ...searchableMethodOptions];
  }

  _getDataSelectAttributes (dataSelect, dimensionValue, clearSelect) {
    return {
      endpoint: dataSelect.data('endpoints')[dimensionValue],
      placeholder: dataSelect.data('placeholders')[dimensionValue],
      options: dataSelect.data('selection-options')[dimensionValue],
      selected: clearSelect ? [] : dataSelect.data('selected'),
      skipInitialAjax: dimensionValue !== 'ad_unit',
      minimumInputLength: ($.inArray(dimensionValue, ['device', 'product', 'programmatic_channel']) >= 0) ? 0 : 1
    };
  }

  _getLastDynamicFilter () {
    return $(this._getScopedSelector(`#${this.filterRowId}${this.dynamicFilterSelector}:last`));
  }

  _getBody () {
    return $(this._getScopedSelector('.dynamic-filters')).find(
      `#${this.id}${this.dynamicFilterSelector}`
    );
  }

  _getFiltersDimension () {
    return this._getBody().find('select.dynamic-filter-dimension');
  }

  _getMethodSelect () {
    return this._getBody().find('.dynamic-filter-method');
  }

  _getFilterInput () {
    return this._getBody().find('.dynamic-filter__input');
  }

  _getRawData () {
    return this._getFilterInput().get(0);
  }

  _getFiltersCount () {
    return $(this.researchBodySelector).data('dynamic-filters-count') || 0;
  }

  _isMethodSelectValueSearchable (methodSelectValue) {
    return $.inArray(methodSelectValue, ResearchDynamicFilter.SEARCHABLE_METHOD_VALUES) >= 0;
  }

  _isDataTextInput () {
    return this._getRawData().type === 'text';
  }

  _isDimensionOnlySearchable (dimensionValue) {
    return $.inArray(dimensionValue, ResearchDynamicFilter.ONLY_SEARCHABLE_DIMENSIONS) >= 0;
  }
}

ResearchDynamicFilter.SEARCHABLE_METHOD_VALUES = ['is_any_of', 'is_none_of'];
ResearchDynamicFilter.ONLY_SEARCHABLE_DIMENSIONS = ['geo', 'device', 'programmatic_channel', 'product', 'platform'];

ResearchDynamicFilter.ALL_FILTERS = [
  'option[value="website"]',
  'option[value="ad_unit_domain"]',
  'option[value="programmatic_channel"]',
  'option[value="ad_unit"]',
  'option[value="geo"]',
  'option[value="device"]',
  'option[value="product"]',
  'option[value="platform"]',
  'option[value="yield_partner"]'
];
