import React, { useContext } from 'react'

import { SspTile, SspTypes } from 'javascripts/components/Ssps'
import CurrentProfileContext, { CurrentProfile } from 'javascripts/components/CurrentProfile'

const ConnectSspTileContainer: React.FC<SspTypes.ConnectSspTileContainerProps> = ({ ssps }) => {
  const currentProfileCtx = useContext<Partial<CurrentProfile>>(CurrentProfileContext)

  const resizeTile = (tileRefElement: HTMLDivElement | null) => {
    if (tileRefElement) {
      const width = tileRefElement.getBoundingClientRect().width
      const height = `${width * 0.55}px`
      tileRefElement.style.height = height
    }
  }

  const createNewConnectionEndpoint = (platformName: string) =>
    encodeURI(`/panel/${currentProfileCtx.id}/ssps/new?ssp_form[platform]=${platformName}`)

  const sspsList = () =>
    ssps.map(sspName => (
      <SspTile
        key={sspName}
        platformName={sspName}
        onResizeHandler={resizeTile}
        newConnectionEndpoint={createNewConnectionEndpoint(sspName)}
      />
    ))

  return <div className='ssp__tiles-container'>{sspsList()}</div>
}

export default ConnectSspTileContainer
