import { Styles } from 'react-select/src/styles'

import container from './container'
import control from './control'
import loadingMessage from './loadingMessage'
import noOptionsMessage from './noOptionsMessage'
import option from './option'
import placeholder from './placeholder'
import singleValue from './singleValue'

const styles: Styles = {
  container,
  control,
  loadingMessage,
  noOptionsMessage,
  option,
  placeholder,
  singleValue,
}

export default styles
