import { BaseChart } from './_base_chart';
import { DonutChartLegend } from './donut_chart_legend';

export class DonutChart extends BaseChart {
  _defaultAttributes () {
    return {
      radiusMargin: 0.8,
      outerRadius: 0,
      radiusLength: undefined,
      thickness: DonutChart.THICKNESS,
      highlightThickness: 30,
      unit: undefined
    };
  }

  createChart () {
    super.createChart();
    this._moveChartLayerToTheMiddle();
    this._setRadiusParameters();
    this._drawChart();
    this.legend = new DonutChartLegend(this.el.id);

    return this;
  }

  getArcElementIdFor (label) {
    if (label) return label.toLowerCase().replace(/ /g, '-');
  }

  _setData (data) {
    this.data = data;
  }

  _moveChartLayerToTheMiddle () {
    this.chartLayer.attr('transform', `translate(${this.width / 2}, ${this.height / 2})`);
  }

  _setRadiusParameters () {
    const smallerSide = (Math.min(this.width, this.height) / 2);
    this.radius = this.radiusLength || smallerSide * this.radiusMargin;
  }

  _drawChart () {
    this._createPie();
    this._createArcs();

    const ringData = this._prepareRingData(this.data);
    this._drawArc(ringData);
    this._bindItemHighlightOnEvent();
  }
  
  _appendText ({ textElement, text, yOffset }) {
    textElement.append('tspan')
      .text(text)
      .attr('x', 0)
      .attr('y', yOffset);
  }

  _getPrettyValueWithUnit (data) {
    const prettyValue = this._getPrettyValue(data.value);
    const unit = data.unit || this._getUnit();

    return `${prettyValue} ${unit}`;
  }

  _percentValue (value, total) {
    if (total === 0) return '0%';
    return `${Math.round(value / total * 100)}%`;
  }

  _createPie () {
    this.pie = d3.pie()
      .sort(null)
      .value(d => d.value);
  }

  _createArcs () {
    this.arc = this._createArc(DonutChart.THICKNESS);
    this.highlightedArc = this._createArc(this.highlightThickness);
  }

  _drawArc (arcData) {
    const sumValueForRing = this._getSumValueForRing(arcData);

    this.chartLayer.selectAll('arc')
      .data(this.pie(arcData))
      .enter()
      .append('path')
      .attr('d', this.arc)
      .attr('id', d => `arc-${this.getArcElementIdFor(d.data.label)}`)
      .attr('class', d => `arc--${d.data.color}`)
      .on('mouseover', d => this._setItemHighlight(this.getArcElementIdFor(d.data.label), true))
      .on('mouseout', d => this._setItemHighlight(this.getArcElementIdFor(d.data.label), false));

    this.chartLayer.selectAll('arc')
      .data(this.pie(arcData))
      .enter()
      .append('text')
      .text(d => `${this._percentValue(d.data.value, sumValueForRing)}`)
      .attr("transform", d => `translate(${this.arc.centroid(d)})`)
      .style("text-anchor", "middle")
      .style("font-size", 14)
      .style("font-weight", 500)
      .style("fill", "#ffffff")
      .on('mouseover', d => this._setItemHighlight(this.getArcElementIdFor(d.data.label), true))
      .on('mouseout', d => this._setItemHighlight(this.getArcElementIdFor(d.data.label), false));
  }

  _getSumValueForRing (data) {
    return data.reduce((sum, arc) => sum + arc.value, 0);
  }

  _prepareRingData (ringData) {
    return ringData.every(arc => arc.value === 0)
      ? ringData.map(arc => Object.assign({}, arc, { value: 1 }))
      : ringData;
  }

  _createArc (thickness) {
    return d3.arc()
      .outerRadius(() => this.radius + thickness)
      .innerRadius(() => this.radius - DonutChart.THICKNESS)
      .startAngle(d => d.startAngle - Math.PI / 2)
      .endAngle(d => d.endAngle - Math.PI / 2);
  }

  _bindItemHighlightOnEvent () {
    $(this.el).on('donutChart:setItemHighlight', (event, { arcId, value }) => {
      this._setItemHighlight(arcId, value);
    });
  }

  _setItemHighlight (arcId, value) {
    $(`#central-label-${arcId}`).toggleClass('donut-chart__central-label--shown', value);
    $(this.legend).trigger('legend:setItemHighlight', { arcId, value });
    this.chartLayer.select(`#arc-${arcId}`)
      .transition()
      .duration(300)
      .attr('d', value ? this.highlightedArc : this.arc);
  }
}

DonutChart.THICKNESS = 20;
