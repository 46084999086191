import React from 'react';

import { DeleteModalProps } from './types';

const DeleteModal: React.FC<DeleteModalProps> = ({ reportName, reportId, savedQueryEndpoint }) => {
  return (
    <div className="modal" id={`report-modal-${reportId}`} tabIndex={-1} role="dialog" aria-hidden={true}>
      <div className="modal-dialog">
        <div className="modal-content saved-query__delete--content">
          <div className="modal-body saved-query__delete--body">
            <p className="saved-query__delete--text saved-query__delete--bold">Do you want to delete?</p>
            <p className="saved-query__delete--text">{`name "${reportName}" report`}</p>
          </div>

          <div className="modal-footer saved-query__delete--footer">
            <a className="button-primary button-primary--green saved-query__delete--btn" href="#" data-dismiss="modal">Back</a>
            <a className="button-primary button-primary--red saved-query__delete--btn" href={savedQueryEndpoint} data-method="delete">Delete</a>
          </div>
        </div>
      </div>
    </div>
  )
};

export default React.memo(DeleteModal);
