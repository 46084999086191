export class ApplicableDataSelect {
  private static readonly ELEMENT: string = '.applicable-data-select';
  private static readonly INPUT: string = '.applicable-data-select input';
  private static readonly SELECTED_OPTION: string = '.applicable-data-select .selected-option';

  create() {
    this.setDropdownClickEvent();
    this.preventFromClosing();
    $(ApplicableDataSelect.INPUT).val($(ApplicableDataSelect.SELECTED_OPTION).val() || '');
  }

  onChange(callback: Function) {
    $(ApplicableDataSelect.SELECTED_OPTION).change(() => {
      $(ApplicableDataSelect.INPUT).val($(ApplicableDataSelect.SELECTED_OPTION).val() || '');
      callback();
    });
  }

  private setDropdownClickEvent() {
    $(`${ApplicableDataSelect.ELEMENT} .applicable-data-select__dropdown-menu-select`).click((e) => {
      e.preventDefault();
      $(ApplicableDataSelect.SELECTED_OPTION).text(e.target.dataset.text).val(e.target.dataset.value).trigger('change');
    });
  }

  private preventFromClosing() {
    $(`${ApplicableDataSelect.ELEMENT} .keep-open a.custom_date`).click((e) => {
      e.preventDefault();
      e.stopPropagation();
    });
  }
}
