import React, { useLayoutEffect, useRef } from 'react'

import { SspTileProps } from './types'
import Tile from 'javascripts/components/Tile'
import { SspLogo } from '.'

const SspTile: React.FC<SspTileProps> = ({ platformName, onResizeHandler, newConnectionEndpoint }) => {
  const tileRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    onResizeHandler(tileRef.current)
    window.addEventListener('resize', () => onResizeHandler(tileRef.current))
    return () => {
      window.removeEventListener('resize', () => onResizeHandler(tileRef.current))
    }
  }, [])

  return (
    <a href={newConnectionEndpoint} id={`${platformName}_link`}>
      <Tile className='ssp__tile' reference={tileRef}>
        <SspLogo className='ssp__tile-image' platformName={platformName} />
      </Tile>
    </a>
  )
}

SspTile.displayName = 'SspTile'

export default React.memo(SspTile)
