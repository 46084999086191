import React from 'react'

interface Props {
  id?: string
  name?: string
  value?: string
  onChange: (input: string) => void
  placeholder: string
  className?: string
}

type Event = React.FormEvent<HTMLInputElement>

const SearchInput: React.FC<Props> = ({ id, name, value, onChange, placeholder, className }) => {
  const handleChange = (event: Event) => onChange(event.currentTarget.value)

  return (
    <div className={`search-input-container ${className}`}>
      <input
        className='text-input__field search-input'
        id={id}
        type='text'
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
      <span>
        <i className='fa fa-search' />
      </span>
    </div>
  )
}

export default SearchInput
