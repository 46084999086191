import React from 'react'

import TabListItem from './TabListItem'
import { TabListProps } from './types'

const PublisherTabList: React.FC<TabListProps> = ({ selectedTab, isNew, onSetSelectedTab, disabledTabs }) => {
  const onSelectTab = (position: number) => {
    !isNew && onSetSelectedTab(position)
  }

  return (
    <div className='tab-list'>
      <TabListItem
        position={1}
        name={isNew ? 'Create' : 'Edit'}
        selectedTab={selectedTab}
        onSelectTab={onSelectTab}
        disabled={disabledTabs}
      />
      <TabListItem
        position={2}
        name='Inventory'
        selectedTab={selectedTab}
        onSelectTab={onSelectTab}
        disabled={disabledTabs}
      />
      <TabListItem
        position={3}
        name='Users'
        selectedTab={selectedTab}
        onSelectTab={onSelectTab}
        disabled={disabledTabs}
      />
      <TabListItem
        position={4}
        name='Finances'
        selectedTab={selectedTab}
        onSelectTab={onSelectTab}
        disabled={disabledTabs}
      />
    </div>
  )
}

export default PublisherTabList
