import ToastNotifier, { NotificationType, Config } from 'javascripts/components/ToastNotifier'
import DomHelper from 'javascripts/utlis/domHelper'

export class ToastNotifierHelper {
  static inject = (type: NotificationType, message: string) => {
    DomHelper.injectReactComponent(ToastNotifier, { config: { type, message } })
  }

  static injectWithConfig = (config: Config, container?: HTMLElement) => {
    DomHelper.injectReactComponent(ToastNotifier, { config }, container)
  }
}

export { NotificationType }
