import { useState } from 'react'
import SimpleLoader from './SimpleLoader'

const useSimpleLoader = (visible = false) => {
  const [isVisible, setIsVisible] = useState(visible)

  const setIsLoading = (isLoading: boolean, timeout = 0) => {
    if (timeout === 0) {
      setIsVisible(isLoading)
    } else {
      setTimeout(() => setIsVisible(isLoading), timeout)
    }
  }

  return {
    isLoading: isVisible,
    setIsLoading,
    SimpleLoader,
  }
}

export default useSimpleLoader
