export class CustomCheckbox {
  constructor () {
    this.checkboxes = 'input[type="checkbox"]';
  }

  run () {
    $(this.checkboxes).each(function () {
      var label = $('<label/>').attr('for', $(this).attr('id'));
      $(this).after(label);
    });
  }
}

$(function () {
  new CustomCheckbox().run();
});
