import { Endpoint } from 'javascripts/components/Profile/types'

export enum HttpStatusCode {
  OK = 204,
  VALIDATION_ERROR = 222,
}

export const redirect = (endpoint: Endpoint) => {
  window.location.replace(endpoint)
}
