import { CurrentProfile } from 'javascripts/components/CurrentProfile'

export enum IdentifierType {
  HB = 'hb',
  DIRECT = 'direct',
}

export interface Identifier {
  id: number
  profile_id: number
  name: string
  identifier_type: IdentifierType
}

export interface NetworkTileProps {
  item: Identifier
  onRemoveHandler: (item: number) => void
}

export interface AddNetworkContainerProps {
  inputId: string
  inputLabel: string
  identifiers: Identifier[]
  identifierType: IdentifierType
}

export interface UserSettingsNetworksProps {
  hbIdentifiers: Identifier[]
  directIdentifiers: Identifier[]
}

export interface UserSettingsTabProps extends UserListContainerProps {
  hbIdentifiers: Identifier[]
  directIdentifiers: Identifier[]
}

export interface User {
  id: number
  name: string
  email: string
}

export interface UserListItemProps {
  user: User
  isOwner: Function
}

export interface UserListContainerProps {
  users: User[]
}

export interface UserSerttingsViewProps extends UserSettingsTabProps {
  currentProfile: CurrentProfile
}

export enum UserRole {
  owner = 'Owner',
  user = 'User',
}

export const UserRoles = [
  // {
  //   value: 'owner',
  //   label: UserRole.owner,
  // },
  // {
  //   value: 'user',
  //   label: UserRole.user,
  // },
  { value: 'AED', label: 'AED' },
  { value: 'AFN', label: 'AFN' },
]
