import React from 'react';

interface Props {
  endpoint: string
}
interface State {
  progressValue: number
}

export class ProgressBar extends React.Component<Props, State> {
  private static readonly PROGRESS_BAR_DELAY = 10000;
  private static readonly REFRESH_DELAY = 120000;
  private progresIntervalId: number;
  private refreshIntervalId: number;

  constructor (props: Props) {
    super(props)
    this.state = { progressValue: 0 }

    this.fetchData();
    this.progresIntervalId = window.setInterval(this.fetchData, ProgressBar.PROGRESS_BAR_DELAY);
    this.refreshIntervalId = window.setInterval(this.refreshPage, ProgressBar.REFRESH_DELAY);
  }

  private refreshPage = () =>
    document
      .querySelectorAll('[data-progressbar-refresh="true"]')
      .forEach(form => Rails.fire(form, 'submit'));

  private fetchData = () => {
    $.get(this.props.endpoint, data => {
      const value: number = data.value;

      this.setState({ progressValue: value });
      if (value >= 100) {
        window.clearInterval(this.progresIntervalId);
        window.clearInterval(this.refreshIntervalId);
      }
    });
  };

  render () {
    return (
      <div className="box progress__box">
        <div className="progress__info">Data already uploaded</div>
        <div className="progress__wrapper">
          <div className="progress">
            <div className="progress-bar" style={{ width: `${this.state.progressValue}%` }}>
              <span className="progress__value">{this.state.progressValue}%</span>
            </div>
            <div className="progress__legend">
              <span>0%</span>
              <span>100%</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
