import React, { useContext } from 'react'
import CurrentProfileContext, { CurrentProfile } from 'javascripts/components/CurrentProfile'
import AddNetworkContainer from './AddNetworkContainer'
import {
  UserSettingsNetworksProps,
  IdentifierType,
} from 'javascripts/components/UserSettings/types'

const UserSettingsNetworksConatiner: React.FC<UserSettingsNetworksProps> = ({
  hbIdentifiers,
  directIdentifiers,
}) => {
  const currentProfileCtx = useContext<Partial<CurrentProfile>>(CurrentProfileContext)

  return (
    <div className='user-settings__networks'>
      <h3 className='networks__title'>{currentProfileCtx.display_name || ''}</h3>
      <AddNetworkContainer
        inputId='network-hb'
        inputLabel='Your header bidding identifier'
        identifiers={hbIdentifiers}
        identifierType={IdentifierType.HB}
      />

      <AddNetworkContainer
        inputId='network-direct'
        inputLabel='Your direct identifier'
        identifiers={directIdentifiers}
        identifierType={IdentifierType.DIRECT}
      />
    </div>
  )
}

export default UserSettingsNetworksConatiner
