/* eslint-disable @typescript-eslint/no-this-alias */
import DomHelper from 'javascripts/utlis/domHelper';
import ArrayHelper from 'javascripts/utlis/arrayHelper';

export class AdxTableOverview {
  constructor () {
    this.tableChangeValuesFields = '.adx-performance-table__value-change';
    this.adxHiddenField = '#adx_performance_form_comparison_value';
    this.tableComparisonSelect = '#adx_performance_change_comparison_method';
    this.toggleSitesReportsButton = '.adx-domain-report__toggle';
    this.domainTooltip = '.domain';
    this.dateTooltip = '.date-tooltip';
    this.dataContainer = '#adx-performance';
    this.withoutSeparator = 'without-separator';
    this.greenSeparator = 'green-separator';
    this.tempIds = [];
  }

  run () {
    this._initializaDataChangedEvent();
    this._initializeTooltips();
    this._initializeToggleSitesReportsEvent();
    this._setInitialColumnsVisibility();
    this._setOddReportsColor();
  }

  _setOddReportsColor () {
    $('.adx-domain-report')
      .filter(':odd')
      .each((_, element) => {
        $(element).addClass('adx-performance-table__row--grey');
        $('.' + $(element).data('reportId')).addClass('adx-performance-table__row--grey');
      });
  }

  _initializaDataChangedEvent () {
    $(this.dataContainer).trigger('data:changed');
  }

  _initializeTooltips () {
    $(`${this.domainTooltip}, ${this.dateTooltip}`).tooltip({
      placement: 'top'
    });
  }

  _setInitialColumnsVisibility () {
    if ($(this.adxHiddenField).val() === 'value_change') {
      this._toggleColumnsVisibility();
    }
  }

  _toggleColumnsVisibility () {
    $(this.tableChangeValuesFields).toggle();
  }

  _initializeToggleSitesReportsEvent () {
    const parent = this;
    $(this.toggleSitesReportsButton).click(function (event) {
      event.preventDefault();

      const dataReportId = $(this).data('target');
      $('.adx-site-report.' + dataReportId).toggle();

      if (!parent.tempIds.includes(dataReportId)) {
        parent._clearSeparators(dataReportId);
        parent.tempIds.push(dataReportId);
      } else {
        parent._restoreSeparators();
        ArrayHelper.removeElementByValue(parent.tempIds, dataReportId);
      }
    });
  }

  _clearSeparators (className) {
    const parent = this;
    const tableRowElements = document.getElementsByClassName(className);

    [...tableRowElements].forEach((el, i) => {
      if (tableRowElements.length === 1) {
        DomHelper.appendClass(el.previousSibling, parent.withoutSeparator);
        DomHelper.appendClass(el, parent.greenSeparator);
        return;
      }

      if (i === 0) {
        DomHelper.appendClass(el.previousSibling, parent.withoutSeparator);
        DomHelper.appendClass(el, parent.withoutSeparator);
      } else if (i === tableRowElements.length - 1) {
        DomHelper.appendClass(el, parent.greenSeparator);
      } else {
        DomHelper.appendClass(el, parent.withoutSeparator);
      }
    });
  }

  _restoreSeparators () {
    const parent = this;
    [
      ...document.getElementsByClassName(this.withoutSeparator),
      ...document.getElementsByClassName(this.greenSeparator)
    ].forEach(el => {
      DomHelper.removeClass(el, parent.withoutSeparator);
      DomHelper.removeClass(el, parent.greenSeparator);
    });
  }
}
