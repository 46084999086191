import { ScrollingAnimator } from '../scrolling_animator';
import { ResearchDisableableElementsToggler } from './research_disableable_elements_toggler';
import { ResearchBaseRemoteHandler } from './_research_base_remote_handler';

export class ResearchAddChartHandler extends ResearchBaseRemoteHandler {
  constructor (researchForm, containerSelector) {
    super(containerSelector, ResearchAddChartHandler.ADD_CHART);
    this.researchForm = researchForm;
  }

  create () {
    this._getElement().on('click', e => {
      e.preventDefault();
      this._addChartClicked();
      this.researchForm.updateFormState();
    });
  }

  _addChartClicked () {
    $.ajax(this._getAjaxOptions()).done(data => {
      this._insertReportContainer(data.body);
      ResearchDisableableElementsToggler.disable();
    });
  }

  _insertReportContainer (body) {
    const newContainer = $(body).insertAfter(this.container);
    new ScrollingAnimator(newContainer).animate();
  }

  _getAjaxOptions () {
    const options = super._getAjaxOptions();
    options.data.push({ name: 'add_chart', value: true });

    return options;
  }

  _getEndpoint () {
    return $(this.container).data('add-chart-endpoint');
  }
}

ResearchAddChartHandler.ADD_CHART = '.research-add-chart';
