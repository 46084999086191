export class ChartAxisLayerComponent {
  _prepareAxisLayer () {
    this._setChartSizeParameters();
    this._moveChartLayer();
    this._setAxesScales();
    this._setHelperScale();
    this._addYAxis();
    this._addXAxis();
  }

  _setChartSizeParameters () {
    this.chartWidth = this.width - this.margin.left - this.margin.right;
    this.chartHeight = this.height - this.margin.top - this.margin.bottom;
  }

  _moveChartLayer () {
    this.chartLayer.attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
  }

  _setAxesScales () {
    this.x = d3.scaleBand()
      .rangeRound([0, this.chartWidth])
      .paddingInner(this.paddingInner)
      .paddingOuter(this.paddingOuter);

    this.y = d3.scaleLinear().rangeRound([this.chartHeight, 0]);
    this.x.domain(this.dataModel.getXAxisDomain());
    this.y.domain(this.dataModel.getYAxisDomain());
  }

  _setHelperScale () {
    this.helperScale = d3.scaleBand()
      .rangeRound([0, this.chartWidth])
      .paddingOuter(this.paddingOuter - this.paddingInner / 2)
      .paddingInner(0)
      .domain(this.dataModel.getXAxisDomain());
  }

  _addYAxis () {
    this.chartLayer.append('g')
      .attr('class', 'axis axis-y')
      .call(this._getYAxis());

    this.chartLayer.selectAll('.axis-y text').attr('x', -10);
  }

  _addXAxis () {
    this.chartLayer.append('g')
      .attr('class', 'axis axis-x')
      .attr('transform', `translate(0, ${this.chartHeight})`)
      .call(this._getXAxis());
  }

  _getYAxis () {
    return d3.axisLeft(this.y)
      .ticks(this.numberOfTicks)
      .tickSize(-this.chartWidth)
      .tickFormat(this._formatYAxisLabel.bind(this));
  }

  _getXAxis () {
    return d3.axisBottom(this.x)
      .tickSize(this.xAxisTickSize === undefined ? -this.chartHeight : this.xAxisTickSize)
      .tickPadding(14)
      .tickFormat(this._formatXAxisLabel.bind(this));
  }
}
