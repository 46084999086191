import React, { useState } from "react";
import logoDark from "images/logo-dark-new.svg";
import { ErrorFieldBox, errorClass } from '../components/forms/ErrorFieldBox';
import getCsrfToken from "../utlis/getCsrfToken";
import * as inputValidators from "../utlis/inputValidators";

interface Props {
  resetPasswordToken: string
}

interface State {
  isValid: boolean
  errors: {
    password: string[]
    passwordConfirmation: string[]
  }
}

type InputNames = 'password' | 'passwordConfirmation';

export const PasswordEdit: React.FunctionComponent<Props> = ({resetPasswordToken}) => {
  const getInitialState = () => {
    const initialState: State = {
      isValid: true,
      errors: {
        password: [],
        passwordConfirmation: []
      }
    };
    return initialState;
  }

  const [form, setValues] = useState({
    password: '',
    passwordConfirmation: ''
  });
  const [formErrors, setErrorsForm] = useState(getInitialState())

  const handleInputChange = (name: InputNames, value: string) => {
    setValues({...form, [name]: value});
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void | boolean => {
    const newState = getInitialState();

    inputValidators.presenceValidation('password', form.password, newState.errors.password);
    inputValidators.lengthValidation('password', form.password, newState.errors.password);
    inputValidators.passwordFormatValidation(form.password, newState.errors.password);
    inputValidators.passwordEqualityValidation(form.password, form.passwordConfirmation, newState.errors.passwordConfirmation);
    inputValidators.setStateValidation(newState);

    setErrorsForm(newState)

    if (newState.isValid) {
      return newState.isValid;
    } else {
      event.preventDefault();
    }
  }


  return (
    <div className="registration registration--background-image-password-recovery">
      <div className="registration__container registration__container--column">
        <img className="registration__logo" src={logoDark} alt="Insight Yieldbird"/>
        <div className="registration_password-recovery">
          <div className="registration__header mb-5">
            <h2 className="registration__title">Recover password</h2>
            <small className="registration__description">Enter your new password</small>
          </div>
          <form id="new_user_password" className="registration__form form" noValidate action="/users/password" method="post" acceptCharset="UTF-8" onSubmit={handleSubmit}>
            <input type="hidden" name="utf8" value="✓" />
            <input type="hidden" name="_method" value="put" />
            <input type="hidden" name="authenticity_token" value={getCsrfToken()} />
            <input type="hidden" value={resetPasswordToken} name="user[reset_password_token]" id="user_reset_password_token" />
            <div className="form__body">
              <div className={`form__group ${errorClass(formErrors.errors.password)}`}>
                <label>Your new password</label>
                <input className="form-control"
                  placeholder="New password"
                  type="password"
                  name="user[password]"
                  id="user_password"
                  value={form.password}
                  onChange={(e) => { handleInputChange('password', e.target.value)}} />
                <ErrorFieldBox errors={formErrors.errors.password}/>
              </div>
              <div className={`form__group ${errorClass(formErrors.errors.passwordConfirmation)}`}>
                <input className="form-control"
                  placeholder="Confirm new password"
                  type="password"
                  name="user[password_confirmation]"
                  id="user_password_confirmation"
                  value={form.passwordConfirmation}
                  onChange={(e) => { handleInputChange('passwordConfirmation', e.target.value)}} />
                <ErrorFieldBox errors={formErrors.errors.passwordConfirmation}/>
              </div>
            </div>
            <div className="form__buttons form__buttons--left mt-5">
              <input className="button"
                type="submit"
                name="commit"
                value="Confirm" />
            </div>
          </form>
        </div>
        <div className="registration__footer">
          <p>Don't have an account? <a className="footer__link" href="/">Sign up here</a></p>
        </div>
      </div>
    </div>
  )
}
