import React, { useState } from 'react'
import CurrentProfileContext, { CurrentProfile } from 'javascripts/components/CurrentProfile'
import Box from 'javascripts/components/Box'
import SearchInput from 'javascripts/components/forms/SearchInput'
import RoundBtn from 'javascripts/components/RoundBtn'
import PublisherListContainer from 'javascripts/containers/Publisher/PublisherListContainer'
import { PublisherData } from 'javascripts/components/Publisher/types'

interface Props {
  currentProfile: CurrentProfile
  publishers: PublisherData[]
}

const PublisherView: React.FC<Props> = ({ currentProfile, publishers }) => {
  const [publisherData, setPublisherData] = useState<PublisherData[]>(publishers)

  const newPublisherEndpoint = `/panel/${currentProfile.id}/publishers/new`

  const filterByValue = (value: string) => (publisher: PublisherData) =>
    publisher.name.toLowerCase().includes(value.toLowerCase())

  const onChange = (value: string) => {
    const result = publishers.filter(filterByValue(value))
    setPublisherData(result)
  }

  return (
    <CurrentProfileContext.Provider value={currentProfile}>
      <div className='main-wrapper__item'>
        <Box className='publisher'>
          <header className='box__header'>
            <h3 className='box__title'>Publishers</h3>
          </header>
          <hr className='line-separator' />
          <div className='publisher-list__form'>
            <SearchInput
              id='publisher-filter-input'
              name='publisher-filter-input'
              onChange={onChange}
              placeholder='publishers'
            />
            <RoundBtn href={newPublisherEndpoint}>Add NEW Publisher</RoundBtn>
          </div>
        </Box>

        <PublisherListContainer publishers={publisherData} />
      </div>
    </CurrentProfileContext.Provider>
  )
}

export default PublisherView
