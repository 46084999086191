import { Spinner } from 'javascripts/spinner';
import { AdxPerformanceFilter } from 'javascripts/filters/adx_performance_filter';
import { AdvancedTimeSelect } from 'javascripts/advancedTimeSelect';
import { SortableTable } from 'javascripts/sortable_table';
import { FilterWrapper } from 'javascripts/filters/components/filter_wrapper';

export class AdxPerformance {
  constructor () {
    this.formContainer = '#new_adx_performance_form';
    this.dataContainer = '#adx-performance';
    this.submitFiltersButton = '#submit-filters-btn';
    this.adxSortColumnHiddenField = '#adx_performance_form_sort_column';
    this.selectedSortColumn = '.sorted-column';
    this.spinner = new Spinner(this.dataContainer, { extraWide: true, extraBottom: true });
  }

  run () {
    this._createFilterWrapper();
    this._initializeFilter();
    this._initializeTableSelects();
    this._updateTableHeaders();
    this._initializeFiltersSubmitHandler();
    this._initializeTimeSelectChangeEvent();
    this._initializeDataChangeHandler();
    this._initializeResizeChangeHandler();
    this._setSortColumnValue();
  }

  _createFilterWrapper () {
    new FilterWrapper(`${this.dataContainer}-content`).create();
  }

  _initializeFilter () {
    new AdxPerformanceFilter(this.dataContainer).create();
  }

  _initializeTableSelects () {
    this.AdvancedTimeSelect = new AdvancedTimeSelect();
    this.AdvancedTimeSelect.create();
    $('#adx_performance_change_comparison_method').select2({
      minimumResultsForSearch: -1,
      dropdownPosition: 'below',
      dropdownCssClass: 'select2-dropdown__adx-performance'
    });
    $('.adx-performance-table-filters').removeClass('adx-performance-table-filters--hidden');
  }

  _updateTableHeaders () {
    new SortableTable().updateHeaders();
  }

  _initializeFiltersSubmitHandler () {
    $(this.submitFiltersButton).on('click', () => {
      this.spinner.show();
    });
  }

  _initializeTimeSelectChangeEvent () {
    this.AdvancedTimeSelect.onChange(() => {
      $(this.submitFiltersButton).click();
    });
  }

  _initializeDataChangeHandler () {
    $(this.dataContainer).on('data:changed', () => {
      this._updateTableHeaders();
      this.spinner.hide();
    });
  }

  _initializeResizeChangeHandler () {
    window.addEventListener('resize', this._updateTableHeaders);
  }

  _setSortColumnValue () {
    if (this._isSortColumnValueEmpty()) {
      this._setDefaultSortValue();
    } else {
      this._setSelectedSortValue();
    }
  }

  _isSortColumnValueEmpty () {
    return $(this.selectedSortColumn).length === 0;
  }

  _setDefaultSortValue () {
    $(this.adxSortColumnHiddenField).val('sum_revenue');
  }

  _setSelectedSortValue () {
    $(this.adxSortColumnHiddenField).val($(this.selectedSortColumn).attr('id'));
  }
}
