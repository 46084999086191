export type SortingColumns = 'site_name' | 'revenue' | 'ad_impressions' | 'ecpm'
export type SortingDirection = 'asc' | 'desc'

export type WebsiteSorting = {
  column: SortingColumns
  order: SortingDirection
}

export const defaultSorting: WebsiteSorting = {
  column: "revenue",
  order: 'desc'
}

export interface WebsitePerformanceContainerProps {
  currency: string
  profileId: number
}
