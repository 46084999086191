import axios from 'axios';
import getCsrfToken from 'javascripts/utlis/getCsrfToken';

export const createSspConnection = (profileId: number, formData: any) => {
  const url = `/panel/${profileId}/ssps`;
  return axios({
    method: 'post',
    url: url,
    data: formData
  });
}

export const deleteSspConnection = (profileId: number, sspId: number) => {
  return axios.delete(`/panel/${profileId}/ssps/${sspId}`, {
    data: { authenticity_token: getCsrfToken() }
  });
}
