import { SspConfiguration } from '../types'

import CriteoLogo from 'images/criteo_logo.png'
import CriteoSmallLogo from 'images/criteo_small_logo.png'

const criteoConfig: SspConfiguration = {
  platform: 'criteo',
  platformName: 'Criteo',
  logo: CriteoLogo,
  smallLogo: CriteoSmallLogo,
}

export default criteoConfig
