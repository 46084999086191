import React, { useState, useContext } from 'react'
import { TabViewProps, PublisherTabIndex, PublisherFormErrors } from 'javascripts/components/Publisher/types'
import * as publisherService from 'javascripts/services/PublisherService'
import { AxiosResponse, AxiosError } from 'axios'
import { presenceValidation } from 'javascripts/utlis/inputValidators'
import CurrentProfileContext, { CurrentProfile } from 'javascripts/components/CurrentProfile'
import FinanceAdminCostForm from 'javascripts/components/Publisher/FinanceAdminCostForm'
import { NotificationType, useToastNotifier } from 'javascripts/components/ToastNotifier'
import PublisherFinancesSearch from '../PublisherFinances/PublisherFinancesSearch'
import { bulkUpdateAdUnits, getAdUnits } from '../publisherUtils'
import PublisherFinanceFooter from '../PublisherFinances/PublisherFinanceFooter'

const PUBLISHER_LIST_TIMEOUT = 1000

const FinancesContainer: React.FC<TabViewProps> = ({
  onSetSelectedTab,
  publisher,
  updatePublisherProperties,
  updateAdUnits,
  updateWebsites,
}) => {
  const currentProfileCtx = useContext<Partial<CurrentProfile>>(CurrentProfileContext)
  const [errors, setErrors] = useState([])
  const { Notifier, createNotifier } = useToastNotifier()
  const [searchQuery, setSearchQuery] = useState<string>(undefined)

  const savePublisher = () => {
    const isFormValid = validForm()

    if (isFormValid) {
      publisherService
        .createPublisher(currentProfileCtx.id, publisher)
        .then(savePublisherSuccessHandler)
        .catch(savePublisherErrorHandler)
    }
  }

  const validForm = () => {
    const err = []
    presenceValidation('margin', publisher.margin.toString(), err)
    setErrors(err)
    return err.length === 0
  }

  const savePublisherSuccessHandler = (_response: AxiosResponse) => {
    createNotifier('Publisher was successfully created.', NotificationType.SUCCESS)
    setTimeout(() => window.location.replace(`/panel/${currentProfileCtx.id}/publishers`), PUBLISHER_LIST_TIMEOUT)
  }

  const savePublisherErrorHandler = (error: AxiosError) => {
    createNotifier(createMessage(error.response.data.errors), NotificationType.ERROR)
  }

  const createMessage = (errors: PublisherFormErrors) => {
    let message = ''
    Object.keys(errors).forEach((errorSource) => {
      errors[errorSource].forEach((error) => {
        Object.keys(error).forEach((field) => {
          message += field + ' ' + error[field].join(`\n${field} `) + '\n'
        })
      })
    })

    return message
  }

  return (
    <div className='tab-view'>
      <PublisherFinancesSearch
        errors={errors}
        publisher={publisher}
        updatePublisherProperties={updatePublisherProperties}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        bulkUpdateAdUnits={bulkUpdateAdUnits(publisher.adUnits, searchQuery, updateAdUnits)}
      />

      <hr className='line-separator' />
      <FinanceAdminCostForm items={getAdUnits(publisher.adUnits, searchQuery)} updateItems={updateAdUnits} label='Ad Unit' />
      <FinanceAdminCostForm items={publisher.websites} updateItems={updateWebsites} label='Website' />

      <PublisherFinanceFooter backHandler={() => onSetSelectedTab(PublisherTabIndex.USERS_TAB)} saveHandler={savePublisher} />
      {Notifier}
    </div>
  )
}

export default FinancesContainer
