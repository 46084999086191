import React, { useMemo } from 'react';

import SavedQueryDropdown from './SavedQueryDropdown';
import { SavedQueryTileProps } from './types';
import SavedQueriesTileIcon from 'images/icon-saved-queries-tile.png';
import DeleteModal from './DeleteModal';

const SavedQueryTile: React.FC<SavedQueryTileProps> = ({ savedQuery, savedQueryClassName, queryEndpoint, savedQueryEndpoint }) => {

  const getQueryUrl = () => `${queryEndpoint}/${savedQuery.id}`;
  const createLinkToCopy = () => `${process.env.YES_PANEL_HOST_WITH_PORT}${getQueryUrl()}`;
  const memoizedCreateLinkToCopy = useMemo(createLinkToCopy, [queryEndpoint, savedQuery.id]);

  return (
    <div className={`saved-query ${savedQueryClassName}`}>
      <div className="saved-query__content">
        <div className="ball">
          <img src={SavedQueriesTileIcon} alt="Saved Reports Tile icon" />
        </div>
        <a className="saved-query__name" href={getQueryUrl()}>{ savedQuery.name }</a>
      </div>

      <SavedQueryDropdown
        title="Edit"
        urlToCopy={memoizedCreateLinkToCopy}
        reportId={savedQuery.id}
      />

      <DeleteModal
        reportName={savedQuery.name}
        reportId={savedQuery.id}
        savedQueryEndpoint={savedQueryEndpoint}
      />
    </div>
  )
};

SavedQueryTile.displayName = 'SavedQueryTile';

export default React.memo(SavedQueryTile);
