import React from 'react'
import Input from 'javascripts/components/forms/Input'
import { AdUnit, Website } from './types'

interface Props {
  items: Array<AdUnit> | Array<Website>
  updateItems: Function
  label: string
}

const FinanceAdminCostForm: React.FC<Props> = ({ items, updateItems, label }) => {
  return (
    <div>
      {items.length > 0 && (
        <>
          <div className='admin-cost__header admin-cost__row'>
            <div className='admin-cost__title'>Admin Cost</div>
            <div className='admin-cost__title'>{label}</div>
          </div>
        </>
      )}

      {items.length > 0 &&
        items.map((item) => {
          return (
            <div key={item.id} className='admin-cost__body admin-cost__row'>
              <div className='admin-cost__cell'>
                <div className='publisher-margin__input-container'>
                  <Input
                    type='number'
                    id={item.name}
                    name={`margin-${item.name}`}
                    value={item.adminCost}
                    onChange={(value) => {
                      item.adminCost = parseFloat(value)
                      updateItems(items)
                    }}
                    inputClassName='publisher-margin__input'
                    selectOnFocus={true}
                    disableScroll={true}
                    disableAutoComplete={true}
                  />
                  <span className='publisher-margin__input-suffix'>%</span>
                </div>
              </div>
              <div className='admin-cost__cell'>{item.name}</div>
            </div>
          )
        })}
    </div>
  )
}

export default FinanceAdminCostForm
