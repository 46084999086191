/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'

import { getWebsitePerformance } from 'javascripts/services/DashboardSevice'
import { useEventListener } from 'javascripts/components/Dashboard/useEventListener'
import WebsitePerformance from 'javascripts/components/Dashboard/WebsitePerformance'

import { Spinner } from 'javascripts/spinner';

import { WebsitePerformanceContainerProps, SortingColumns, SortingDirection, defaultSorting } from './types'
import { compareValues } from 'javascripts/utlis/sortHelper';

const DATA_CONTAINER = "#website-performance-data"

const WebsitePerformanceContainer: React.FC<WebsitePerformanceContainerProps> = ({ currency, profileId }) => {
  const [date, setDate] = useState('seven_days')
  const [applicableData, setApplicableData] = useState('all_revenues')

  const [data, setData] = useState<Array<any>>([])
  const [sortParams, setSortParams] = useState(defaultSorting)

  const spinner = new Spinner(DATA_CONTAINER, { extraWide: true, extraBottom: true });

  useEffect(() => {
    spinner.show()
    getWebsitePerformance(profileId, date, applicableData).then(res => {
      const values = res.data.data.map(data => data.table)
      setData(sort(values))
      spinner.hide()
    })
  }, [date, applicableData, sortParams])

  useEventListener('OverviewChange', (event) => {
    setApplicableData(event.detail.applicableData)
    setDate(event.detail.date)
  })

  const sort = (data: Array<any>) => {
    const { column, order } = sortParams
    return data.sort(compareValues(column, order))
  }

  const setSorting = (column: SortingColumns) => {
    let order = 'asc' as SortingDirection
    if (column === sortParams.column) {
      order = sortParams.order === 'asc' ? 'desc' : 'asc'
    }
    setSortParams({ column, order })
  }

  return (
    <div id='website-performance'>
      <WebsitePerformance
        data={data} date={date} applicableData={applicableData} currency={currency}
        profileId={profileId} setSorting={setSorting} sortParams={sortParams}
      />
    </div>
  )

}

export default WebsitePerformanceContainer
