import React, { useState, useContext } from 'react'
import { TabViewProps } from 'javascripts/components/Publisher/types'
import * as publisherService from 'javascripts/services/PublisherService'
import axios, { AxiosError } from 'axios'
import { presenceValidation } from 'javascripts/utlis/inputValidators'
import CurrentProfileContext, { CurrentProfile } from 'javascripts/components/CurrentProfile'
import FinanceAdminCostForm from 'javascripts/components/Publisher/FinanceAdminCostForm'
import { NotificationType, useToastNotifier } from 'javascripts/components/ToastNotifier'
import { useSimpleLoader } from 'javascripts/components/SimpleLoader'
import PublisherFinancesSearch from '../PublisherFinances/PublisherFinancesSearch'
import { bulkUpdateAdUnits, getAdUnits } from '../publisherUtils'
import PublisherFinanceFooter from '../PublisherFinances/PublisherFinanceFooter'

const LOADER_TIMEOUT = 500

const FinancesContainer: React.FC<TabViewProps> = ({
  publisher,
  updatePublisherProperties,
  updateAdUnits,
  updateWebsites,
  onGetPublisher,
}) => {
  const currentProfileCtx = useContext<Partial<CurrentProfile>>(CurrentProfileContext)
  const [errors, setErrors] = useState([])
  const { isLoading, setIsLoading, SimpleLoader } = useSimpleLoader(false)
  const [searchQuery, setSearchQuery] = useState<string>(undefined)

  const { Notifier, createNotifier } = useToastNotifier()
  const publisherEndspoint = `/panel/${currentProfileCtx.id}/publishers`

  const save = () => {
    const isFormValid = validForm()

    if (isFormValid) {
      setIsLoading(true)
      axios
        .all([
          publisherService.updatePublisherData(publisher),
          publisherService.updateMargins(publisher.adUnits, publisher.websites),
        ])
        .then((_responses) => onGetPublisher(publisher.id))
        .then(() => createNotifier('Publisher margins updated!', NotificationType.SUCCESS))
        .catch(savePublisherErrorHandler)
        .finally(() => setIsLoading(false, LOADER_TIMEOUT))
    }
  }

  const validForm = () => {
    const err = []
    presenceValidation('margin', publisher.margin, err)
    setErrors(err)
    return err.length === 0
  }

  const savePublisherErrorHandler = (error: AxiosError) =>
    createNotifier(createErrorMessage(error.response.data.errors), NotificationType.ERROR)

  const createErrorMessage = (errors: any) => {
    return Object.entries(errors)
      .map(([key, value]) => `${key} ${value}`)
      .join('\n')
  }

  return (
    <>
      <div className='tab-view'>
        {isLoading
          ? (
            <>
              <h3>Margin</h3>
              <SimpleLoader />
            </>
          )
          : (
            <>
              <PublisherFinancesSearch
                errors={errors}
                publisher={publisher}
                updatePublisherProperties={updatePublisherProperties}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                bulkUpdateAdUnits={bulkUpdateAdUnits(publisher.adUnits, searchQuery, updateAdUnits)}
              />
              {publisher.adUnits.length > 0 ||
                (publisher.websites.length > 0 && <h3 className='publisher-admin__cost'>Admin Cost</h3>)}

              {publisher.adUnits.length > 0 && (
                <>
                  <hr className='line-separator' />
                  <FinanceAdminCostForm items={getAdUnits(publisher.adUnits, searchQuery)} updateItems={updateAdUnits} label='Ad Unit' />
                </>
              )}

              {publisher.websites.length > 0 && (
                <FinanceAdminCostForm items={publisher.websites} updateItems={updateWebsites} label='Website' />
              )}
            </>
          )}
        {Notifier}
      </div>
      <PublisherFinanceFooter backHref={publisherEndspoint} saveHandler={save} />
    </>
  )
}

export default FinancesContainer
