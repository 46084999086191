import { TimelineChart } from './_timeline_chart';
import { MultilineChartModel } from './models/multiline_chart_model';
import { MultilineChartTooltip } from './tooltips/multiline_chart_tooltip';

export class MultilineChart extends TimelineChart {
  constructor (element, data, customAttributes) {
    super(element, data, customAttributes);

    this.shape = 'line';
  }

  _setData (data) {
    this.dataModel = new MultilineChartModel(data);
    this.tooltip = new MultilineChartTooltip(this);
  }

  _getShape () {
    return d3.line()
      .x(d => d.xValue)
      .y(d => this.y(d.value));
  }
}
