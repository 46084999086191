import React from 'react'
import ToastNotifier, { NotificationType } from 'javascripts/components/ToastNotifier'

async function sleep(milliseconds: number) {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

function createDetector() {
  const detector = document.createElement('div')
  detector.className = 'ad-unit'
  detector.style.cssText =
    'width: 1px !important; height: 1px !important; position: absolute !important; left: -10000px !important; top: -1000px !important;'

  return detector
}

function isDetectorHidden(detectorProperties: CSSStyleDeclaration) {
  return detectorProperties.display === 'none' || detectorProperties.visibility === 'hidden'
}

async function detectAdblock(samples: number, delay: number) {
  const detector = createDetector()
  const computedProperties = getComputedStyle(detector)

  document.body.appendChild(detector)

  try {
    for (let i = 0; i < samples; i++) {
      if (isDetectorHidden(computedProperties)) return true
      await sleep(delay)
    }

    return false
  } finally {
    document.body.removeChild(detector)
  }
}

interface State {
  adblockDetected: boolean
}

export class AdblockDetector extends React.Component<{}, State> {
  private static readonly SAMPLES = 10
  private static readonly DELAY_BETWEEN_SAMPLES_IN_MILLISECONDS = 100

  constructor(props: {}) {
    super(props)

    this.state = {
      adblockDetected: false,
    }
  }

  componentDidMount() {
    detectAdblock(
      AdblockDetector.SAMPLES,
      AdblockDetector.DELAY_BETWEEN_SAMPLES_IN_MILLISECONDS
    ).then(adblockDetected => this.setState({ adblockDetected }))
  }

  render() {
    return (
      this.state.adblockDetected && (
        <ToastNotifier
          config={{
            type: NotificationType.ERROR,
            message: 'Please, disable AdBlock to avoid application malfunction.',
            time: 0,
            embedded: true,
          }}
        />
      )
    )
  }
}
