import { MultipleSelect } from 'javascripts/filters/components/multiple_select'
import { CheckboxHelper } from 'javascripts/utlis/checkboxHelper'

export class AdxPerformanceFilter {
  constructor(dataContainer) {
    this.formName = 'adx_performance_form'
    this.dataContainer = dataContainer
  }

  create() {
    this._intializeChecboxesComponent()
    this._intializeMultipleSelect()
  }

  _intializeChecboxesComponent() {
    new CheckboxHelper().initializeCheckboxes({
      containerClass: 'adx_performance_form_products',
      allButtonId: 'products',
    })
    new CheckboxHelper().initializeCheckboxes({
      containerClass: 'adx_performance_form_devices',
      allButtonId: 'device',
    })
  }

  _intializeMultipleSelect() {
    new MultipleSelect('.adx-performance__website', {
      dropdownParent: '.adx-performance-filters',
      withCustomSelectionAdapter: false,
    }).create()
    new MultipleSelect('.adx-performance__countries', {
      dropdownParent: '.adx-performance-filters',
      withCustomSelectionAdapter: false,
    }).create()
  }
}
