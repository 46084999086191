import React from "react";

export const UnconnectedNetwork = (props: { network: string[] }) => (
  <span className="checkbox">
    <label htmlFor={ "dfp_networks_network_codes_to_connect_" + props.network[0] }>
      <input
        className="check_boxes optional"
        type="checkbox"
        value={ props.network[0] }
        defaultChecked
        name="dfp_networks[network_codes_to_connect][]"
        id={ "dfp_networks_network_codes_to_connect_" + props.network[0] }
      />
      <label htmlFor={ "dfp_networks_network_codes_to_connect_" + props.network[0] } />
      { props.network[1] }
    </label>
  </span>
)
