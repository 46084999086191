import { ChartGradientBuilder } from '../helpers/chart_gradient_builder';

export class ChartInteractionComponent {
  _prepareInteraction () {
    this._addInteractiveBars();
    this._setGradient();
    this._addMouseEvents();
    this._addTooltip();
  }

  _addInteractiveBars () {
    this.chartLayer.selectAll('.interactive-bar')
      .data(this.dataModel.getBlockDomain())
      .enter()
      .append('rect')
      .attr('class', 'interactive-bar')
      .attr('x', d => this.helperScale(d.id))
      .attr('y', 0)
      .attr('width', this.helperScale.bandwidth())
      .attr('height', this.chartHeight + this.margin.bottom);
  }

  _setGradient () {
    new ChartGradientBuilder(this.el.id).run();
  }

  _addMouseEvents () {
    this.chartLayer.selectAll('.interactive-bar')
      .on('mouseover', this._mouseOverCallback.bind(this));

    this.svg.on('mouseout', this._mouseOutCallback.bind(this));
  }

  _addTooltip () {
    this.tooltip.create();
  }

  _mouseOverCallback (d) {
    if (typeof this._mouseOver === 'function') {
      this._mouseOver(d);
    }

    this.tooltip.show(d);
    this._highlightBar(d.id);
  }

  _mouseOutCallback () {
    if (typeof this._mouseOut === 'function') {
      this._mouseOut();
    }

    this.tooltip.hide();
    this._removeHighlightFromBar();
  }

  _highlightBar (id) {
    this.barContainers.filter(`:nth-child(${id + 1})`).classed('highlight', true);
  }

  _removeHighlightFromBar () {
    this.barContainers.classed('highlight', false);
  }
}
