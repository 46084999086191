import React from 'react'
import { errorClass, ErrorFieldBox } from 'javascripts/components/forms/ErrorFieldBox'
import Input from 'javascripts/components/forms/Input'
import Button, { ButtonTheme } from 'javascripts/components/Button'
import UserListItem from 'javascripts/components/Users/UserListItem'
import { Publisher, User } from './types'

interface Props {
  publisher: Publisher
  errors: string[]
  addUser: Function
  userEmail: string
  setUserEmail: (input: string) => void
  onRemoveUser: (user: User) => void
  isNew?: boolean
}

const UsersForm: React.FC<Props> = ({
  publisher,
  errors,
  addUser,
  userEmail,
  setUserEmail,
  onRemoveUser,
  isNew,
}) => {
  return (
    <>
      {publisher.users.length > 0 && (
        <div className='user-list__container'>
          {publisher.users.map((user: User, index: number) => (
            <UserListItem key={index} user={user} onRemoveUser={onRemoveUser} isNew={isNew} />
          ))}
        </div>
      )}

      <div className='tab-view__users'>
        <div className={`tab-view__users--input ${errorClass(errors)}`}>
          <Input
            type='email'
            id='userEmail'
            name='userEmail'
            value={userEmail}
            label='Publisher email'
            onChange={setUserEmail}
            inputClassName='publisher--user-email'
          />
          <ErrorFieldBox errors={errors} />
        </div>
        <Button
          theme={ButtonTheme.Green}
          className='tab-view__users--button'
          onClickHandler={addUser}
        >
          Add user
        </Button>
      </div>
    </>
  )
}

export default UsersForm
