const singleValue = (styles: object) => {
  return {
    ...styles,
    color: '#778ca2',
    cursor: 'default',
    padding: '5px 8px',
    fontSize: '14px',
  }
}

export default singleValue
