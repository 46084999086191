import React, { ReactNode } from 'react'

export enum ButtonTheme {
  Green = 'green',
  GreenOutline = 'green-outline',
  GreenTransparent = 'transparent-green',
  Red = 'red',
  RedOutline = 'red-outline',
  Gray = 'gray',
}

type ReactMouseEl = React.MouseEvent<HTMLAnchorElement, MouseEvent>

interface ButtonProps {
  children: ReactNode
  theme: ButtonTheme
  href?: string
  onClickHandler?: Function
  disabled?: boolean
  className?: string
}

const Button: React.FC<ButtonProps> = ({
  children,
  theme,
  href,
  onClickHandler,
  disabled = false,
  className,
}) => {
  const buttonClick = (e: ReactMouseEl) => {
    if (!href) e.preventDefault()
    if (onClickHandler) onClickHandler()
  }

  const disabledClass = disabled ? ' disabled' : ''
  const btnClassName = `button-primary button-primary--${theme} ${className} ${disabledClass}`

  return (
    <a className={btnClassName} href={href} onClick={e => buttonClick(e)}>
      {children}
    </a>
  )
}

export default Button
