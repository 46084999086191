export class ChartBlockLayerComponent {
  _prepareBlockLayer () {
    this._addBarContainers();
    this._addBackgroundBars();
    this._addHighlightBars();
  }

  _addBarContainers () {
    this.barsLayer = this.chartLayer.append('g').attr('class', 'bars-layer');

    this.barContainers = this.barsLayer.selectAll('.bar')
      .data(this.dataModel.getBlockDomain())
      .enter()
      .append('g')
      .attr('class', 'bar-container');
  }

  _addBackgroundBars () {
    this.barContainers
      .append('rect')
      .attr('class', 'background-bar')
      .attr('x', d => this.helperScale(d.id))
      .attr('y', 0)
      .attr('width', this.helperScale.bandwidth())
      .attr('height', () => this.chartHeight + this.margin.bottom);
  }

  _addHighlightBars () {
    this.barContainers
      .append('rect')
      .attr('class', 'highlight-bar')
      .attr('x', d => this.helperScale(d.id))
      .attr('y', this.chartHeight + this.margin.bottom - 5)
      .attr('width', this.helperScale.bandwidth())
      .attr('height', 5);
  }
}
