import React from 'react';
import Dropdown from '../DropdownBtn';
import CopyLink from '../CopyLink';

import { SavedQueryDropdownProps } from './types';

const SavedQueryDropdown: React.FC<SavedQueryDropdownProps> = ({ title, urlToCopy, reportId }) => {
  return (
    <Dropdown
      title={title}
    >
      <Dropdown.Item>
        <CopyLink copyValue={urlToCopy}>
          Copy web link
        </CopyLink>
      </Dropdown.Item>

      <Dropdown.Item
        dropdownItemClassName="dropdown__item--delete"
      >
        <a href="#" data-toggle="modal" data-target={`#report-modal-${reportId}`}>Delete reports</a>
      </Dropdown.Item>
    </Dropdown>
  )
};

SavedQueryDropdown.displayName = 'SavedQueryDropdown';

export default React.memo(SavedQueryDropdown);
