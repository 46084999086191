import React from 'react'

import Box from '../Box'
import WebsitePerformanceRow from './WebsitePerfromanceRow'

import { WebsitePerfromanceProps } from './types'
import WebsitePerformanceHeaderCell from './WebsitePerformanceHeaderCell'

const WebsitePerformance: React.FC<WebsitePerfromanceProps> = ({ data, date, applicableData, currency, profileId, setSorting, sortParams }) => {
  const params = Object.entries({
    'overview_performance_form[date]': date,
    'overview_performance_form[applicable_data]': applicableData,
    'overview_performance_form[order]': `${sortParams.column}_${sortParams.order}`,
  }).map(([key, value]) => `${key}=${value}`).sort().join('&')

  const domainsPerformanceXlsxEndpoint = encodeURI(`/panel/${profileId}/domains_performance.xlsx?${params}`)

  return (
    <Box className='website-performance'>
      <header className='box__header'>
        <h3 className='box__title'>Website Performance</h3>
        <a className="button-primary button-primary--green website-performance__download-xlsx-btn"
          href={domainsPerformanceXlsxEndpoint}
        >Download table in xlsx</a>
      </header>
      <hr className='line-separator' />

      <div className='website-performance__data' id='website-performance-data'>
        <div className='website-performance__row website-performance__row--header'>
          <WebsitePerformanceHeaderCell column='site_name' label='Domain' setSorting={setSorting} sortParams={sortParams} />
          <WebsitePerformanceHeaderCell column='revenue' label='Revenue' setSorting={setSorting} sortParams={sortParams} />
          <WebsitePerformanceHeaderCell column='ad_impressions' label='Impressions' setSorting={setSorting} sortParams={sortParams} />
          <WebsitePerformanceHeaderCell column='ecpm' label='eCPM' setSorting={setSorting} sortParams={sortParams} />
        </div>
        {
          data.map((item, index) => <WebsitePerformanceRow key={index} item={item} currency={currency} />)
        }
      </div>
    </Box>
  )
}

export default WebsitePerformance
