import React from 'react'

import { UserListItemProps } from './types'
import Avatar from '../Avatar'

const UserListItem: React.FC<UserListItemProps> = ({ user, onRemoveUser }) => {
  return (
    <div className='user-item'>
      <Avatar />
      <p className='user-item__email'>{user.email}</p>
      {user.isNew && (
        <span className='fa fa-trash user-item__icon' onClick={() => onRemoveUser(user)}></span>
      )}
    </div>
  )
}

export default UserListItem
