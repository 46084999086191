import React, { useState, useEffect } from 'react'
import {
  ToastNotifierProps,
  NotificationType,
  NotifierIcons,
} from 'javascripts/components/ToastNotifier/types'
import IconSuccess from 'images/icon-success.svg'
import IconError from 'images/icon-error.svg'
import IconWarning from 'images/icon-warning.svg'
import IconInfo from 'images/icon-info.svg'
import IconExitGreen from 'images/icon-exit-green.svg'
import IconExitRed from 'images/icon-exit-red.svg'
import IconExitOrange from 'images/icon-exit-orange.svg'
import IconExitGray from 'images/icon-exit-gray.svg'

const DEFAULT_CLOSE_TIME = 5 // [s]
let toastNotifierTimer = null

const ToastNotifier: React.FC<ToastNotifierProps> = ({ config, onRenderFinished }) => {
  const [show, setShow] = useState<boolean>(true)
  const [shouldRender, setShouldRender] = useState<boolean>(true)

  const getIcons = (): NotifierIcons => {
    switch (config.type) {
      case NotificationType.SUCCESS:
        return { icon: IconSuccess, iconExit: IconExitGreen }
      case NotificationType.ERROR:
        return { icon: IconError, iconExit: IconExitRed }
      case NotificationType.WARNING:
        return { icon: IconWarning, iconExit: IconExitOrange }
      case NotificationType.INFO:
        return { icon: IconInfo, iconExit: IconExitGray }
    }
  }

  const { icon, iconExit } = getIcons()

  const onAnimationEnd = () => {
    if (!show) {
      if (onRenderFinished) onRenderFinished(true)
      setShouldRender(false)
    }
  }

  const setTimer = () => {
    toastNotifierTimer = setTimeout(() => {
      setShow(false)
    }, (config.time ?? DEFAULT_CLOSE_TIME) * 1000)
  }

  useEffect(() => {
    if (config.time !== 0) {
      clearTimeout(toastNotifierTimer)
      setTimer()
    }
  }, [config.message])

  const { type, message, embedded } = config

  return (
    shouldRender && (
      <div
        className={`toast-notifier ${show ? 'toast-notifier--fade-in' : 'toast-notifier--fade-out'}
        ${embedded && 'toast-notifier--embedded'}`}
        onAnimationEnd={onAnimationEnd}
      >
        <div className={`toast-notifier__icon-container toast-notifier__icon-container--${type}`}>
          <img className={`toast-notifier__icon toast-notifier__icon--${type}`} src={icon}></img>
        </div>
        <div className={`toast-notifier__message toast-notifier__message--${type}`}>{message}</div>
        <img
          className={`toast-notifier__icon-exit ${embedded &&
            'toast-notifier__icon-exit--embedded'}`}
          src={iconExit}
          onClick={() => setShow(false)}
        ></img>
      </div>
    )
  )
}

export default ToastNotifier
