import { SingleDatePicker } from '../singleDatePicker';

export class DashboardOverview {
  constructor() {
    this.datePicker = '#single_date_picker';
  }

  run () {
    this._initializeOnClickTabs()
    this._initializeSingleDatePicker()
  }

  _initializeOnClickTabs () {
    $('.general-details__tab-link').click(this._onClickTabsHandler)
  }

  _onClickTabsHandler () {
    Array.from(document.getElementsByClassName('general-details__tab-link')).map(function (element) {
      element.classList.remove('active')
      element.classList.remove('show')
    })
  }

  _initializeSingleDatePicker () {
    new SingleDatePicker(this.datePicker).create();
  }
}
