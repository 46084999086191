import React, { useContext } from 'react'
import { CurrentUser } from 'javascripts/components/CurrentUser/types'
import CurrentUserContext from 'javascripts/components/CurrentUser/CurrentUserContext'
import PersonImage from 'images/user.svg'
import ProfileForm from 'javascripts/components/Profile/ProfileForm'

const ProfileContainer: React.FC = () => {
  const currentUser = useContext<Partial<CurrentUser>>(CurrentUserContext)

  return (
    <div className='profile__container'>
      <div className='profile__header'>
        <img className='profile__header--img' src={PersonImage} alt='Person'></img>
        <div className='profile__header--name'>{currentUser.name}</div>
      </div>
      <ProfileForm></ProfileForm>
    </div>
  )
}

export default ProfileContainer
