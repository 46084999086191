import { SspConfiguration } from '../types'

import RubiconLogo from 'images/rubicon_logo.png'
import RubiconSmallLogo from 'images/rubicon_small_logo.png'

const rubiconConfig: SspConfiguration = {
  platform: 'rubicon',
  platformName: 'Rubicon',
  logo: RubiconLogo,
  smallLogo: RubiconSmallLogo,
}

export default rubiconConfig
