import { ResearchRunHandler } from './research_run_handler';
import { ResearchDisableableElementsToggler } from './research_disableable_elements_toggler';

export class ResearchReportsReloader {
  constructor () {
    this.researchReport = '.research-report';

    return this;
  }

  reload () {
    this.reports = this._getReports();

    this._disableFormElements();
    this._setOneTimeAjaxStopEvent();
    this._reloadNextReport();
  }

  _disableFormElements () {
    $(this.researchReport).addClass(ResearchReportsReloader.UNCLICKABLE_CLASS_NAME);
    ResearchDisableableElementsToggler.disable();
  }

  _setOneTimeAjaxStopEvent () {
    $(document).one('ajaxStop', () => this._enableFormElements());
  }

  _reloadNextReport () {
    if (this.reports.length > 0) {
      this.unclickableReports = '.' + ResearchReportsReloader.UNCLICKABLE_CLASS_NAME;
      const report = this.reports.shift();

      this._setOneTimeAjaxCompleteEvent();
      this._replaceReportBody(report);
      this._removeUnclickableClass($(this.unclickableReports).first());
    }
  }

  _removeUnclickableClass (element) {
    $(element).removeClass(ResearchReportsReloader.UNCLICKABLE_CLASS_NAME);
  }

  _enableFormElements () {
    this._removeUnclickableClass(this.researchReport);
    ResearchDisableableElementsToggler.enable();
  }

  _setOneTimeAjaxCompleteEvent () {
    $(document).one('ajaxComplete', () => this._reloadNextReport());
  }

  _replaceReportBody (report) {
    new ResearchRunHandler(report).replaceReportBody(true);
  }

  _getReports () {
    return $(this.researchReport)
      .map((_, report) => this._getReportContainerSelector(report))
      .toArray();
  }

  _getReportContainerSelector (selector) {
    return `[data-uuid="${$(selector).data('uuid')}"]`;
  }
}

ResearchReportsReloader.UNCLICKABLE_CLASS_NAME = 'unclickable';
