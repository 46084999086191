import React from 'react';
import { StepBar } from '../../components/registration/StepBar';

export const Success: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <div className="registration__header my-auto">
        <h2 className="registration__title">Welcome to Yieldbird Insights</h2>
      </div>
      <div className="multi-steps multi-steps--3">
        <StepBar step={3} />
        <div className="form__buttons form__buttons--right">
          <a className="button" href={'/users/sign_in'}>
            Get started
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};
