const input = (styles: object) => {
  return {
    ...styles,
    cursor: 'text',
    padding: '0 7px',
    width: '100%',
    fontSize: '14px',
    margin: '7px 2px',
  }
}

export default input
