import React from 'react'
import classNames from 'classnames'

interface Props {
  errors: string[]
  prefix?: string
  className?: string
}

export function errorClass(errors: string[] = []): string {
  return errors.length === 0 ? '' : 'has-error'
}

export const ErrorFieldBox: React.FunctionComponent<Props> = ({ errors, prefix, className }) => {
  const errorsClass = classNames('help-block', className)

  const errorList = (): JSX.Element[] =>
    errors.map((error: string, i: number) => (
      <span className={errorsClass} key={i}>
        {(prefix ?? '') + ' ' + error}
      </span>
    ))

  return <div>{errors && errorList()}</div>
}
