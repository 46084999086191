import * as moment from 'moment'

import { DatePicker } from './datePicker'

const singleDatePickerConfig = (): daterangepicker.Options => ({
  singleDatePicker: true,
  autoApply: true,
  autoUpdateInput: true,
  locale: {
    format: 'DD.MM.YYYY',
    firstDay: 1,
    daysOfWeek: moment.weekdaysShort(),
    monthNames: moment.months(),
  },
})

export class SingleDatePicker extends DatePicker {
  constructor(containerId: string) {
    super(containerId, singleDatePickerConfig)
  }
}
