window.AdxComparisonMethodHandler = require('javascripts/adx_performance/adx_comparison_method_handler').AdxComparisonMethodHandler
window.AdxGeneratedResearchReportsLoader = require('javascripts/research/adx_generated_research_reports_loader').AdxGeneratedResearchReportsLoader
window.AdxPerformance = require('javascripts/adx_performance').AdxPerformance
window.AdxTableOverview = require('javascripts/adx_performance/adx_table_overview').AdxTableOverview
window.AreaChart = require('javascripts/charts/area_chart').AreaChart
window.DailyUpliftOverview = require('javascripts/daily_uplift_overview').DailyUpliftOverview
window.Dashboard = require('javascripts/dashboard').Dashboard
window.DashboardOverview = require('javascripts/dashboard/dashboard_overview').DashboardOverview
window.DonutChart = require('javascripts/charts/donut_chart').DonutChart
window.MultilineChart = require('javascripts/charts/multiline_chart').MultilineChart
window.Profiles = require('javascripts/admin/profiles').Profiles
window.ProfilesTable = require('javascripts/admin/profiles_table').ProfilesTable
window.Research = require('javascripts/research/research').Research
window.ResearchChartContainer = require('javascripts/charts/research_chart/research_chart_container').ResearchChartContainer
window.ResearchDynamicFilter = require('javascripts/research/research_dynamic_filter').ResearchDynamicFilter
window.ResearchReport = require('javascripts/research/research_report').ResearchReport
window.ResearchSavedReportLoader = require('javascripts/research/research_saved_report_loader').ResearchSavedReportLoader
window.SortableTable = require('javascripts/sortable_table').SortableTable
window.Sidebar = require('javascripts/sidebar').Sidebar
window.ToastNotificationHelper = require('javascripts/utlis/toastNotifierHelper').ToastNotifierHelper
window.ToastNotificationType = require('javascripts/utlis/toastNotifierHelper').NotificationType

import 'javascripts/ajax'
import 'javascripts/custom_checkbox'
import 'javascripts/utlis/select2.dropdownPositionAdapter'
