import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';

import Box from '../Box';
import SspLogo from './SspLogo';
import { ConnectedSspProps } from './types';
import Button, { ButtonTheme } from 'javascripts/components/Button';
import { useToastNotifier, NotificationType } from 'javascripts/components/ToastNotifier';
import CurrentProfileContext, { CurrentProfile } from 'javascripts/components/CurrentProfile';
import { deleteSspConnection } from 'javascripts/services/SspService';

enum DeleteStatus {
  NotStarted,
  Waiting,
  Confirmation,
  Deleting,
  Deleted,
}

const ConnectedSsp: React.FC<ConnectedSspProps> = ({ ssp }) => {
  const currentProfileCtx = useContext<Partial<CurrentProfile>>(CurrentProfileContext);
  const [deleteStatus, setDeleteStatus] = useState(DeleteStatus.NotStarted);
  const { Notifier, createNotifier } = useToastNotifier();

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDeleteStatus((prevStatus) => {
        return [DeleteStatus.Deleting, DeleteStatus.Deleted].includes(prevStatus) ? prevStatus : DeleteStatus.NotStarted;
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const deleteButton = useMemo(() =>({
    [DeleteStatus.NotStarted]: (
      <Button theme={ButtonTheme.Red} className="connected-ssp__delete-btn" onClickHandler={() => {
        setDeleteStatus(DeleteStatus.Waiting);
        setTimeout(() => {
          setDeleteStatus((prevStatus) => prevStatus === DeleteStatus.Waiting ? DeleteStatus.Confirmation : prevStatus);
        }, 1000);
      }}>Delete</Button>
    ),
    [DeleteStatus.Waiting]: (
      <Button theme={ButtonTheme.RedOutline} className="connected-ssp__delete-waiting-btn">Are you sure?</Button>
    ),
    [DeleteStatus.Confirmation]: (
      <Button theme={ButtonTheme.RedOutline} className="connected-ssp__delete-confirmation-btn" onClickHandler={() => {
        setDeleteStatus(DeleteStatus.Deleting);
        deleteSspConnection(ssp.profile_id, ssp.id).then(
          () => {
            setDeleteStatus(DeleteStatus.Deleted);
            createNotifier('The SSP has been successfully deleted!', NotificationType.SUCCESS);
          },
          () => {
            createNotifier("We're sorry, but something went wrong", NotificationType.ERROR);
          },
        )
      }}>Are you sure?</Button>
    ),
    [DeleteStatus.Deleting]: (
      <Button theme={ButtonTheme.Red} className="connected-ssp__delete-btn" disabled={true}>Are you sure?</Button>
    ),
    [DeleteStatus.Deleted]: null,
  }[deleteStatus]), [deleteStatus]);

  return (
    <div ref={ref}>
      {deleteStatus !== DeleteStatus.Deleted && (
        <Box className="connected-ssp__container">
          <span className="connected-ssp__schedulable">
            <input type="checkbox" defaultChecked={ssp.schedulable} />
            <label></label>
          </span>
          <SspLogo small platformName={ssp.platform} className="ssp-logo--small" />
          <p className="connected-ssp__name">{ssp.name}</p>

          {deleteButton}
          <Button theme={ButtonTheme.Green} href={`/panel/${currentProfileCtx.id}/ssps/${ssp.id}/edit`} className="connected-ssp__edit-btn">
            Edit
          </Button>
        </Box>
      )}

      {Notifier}
    </div>
  );
};

export default ConnectedSsp;
