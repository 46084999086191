import React from 'react';

import { DropdownItemProps } from './types';

const DropdownItem: React.FC<DropdownItemProps> = ({ dropdownItemClassName, children }) => {
  return (
    <div className={`dropdown__item ${dropdownItemClassName}`}>
      { children }
    </div>
  )
};

DropdownItem.displayName = 'DropdownItem'

export default React.memo(DropdownItem);
