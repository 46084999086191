export class ChartDotsHandler {
  constructor (chart) {
    this.chart = chart;
  }

  show (data) {
    this.dots = [];
    this._getDotsData(data.id)
      .forEach((elem, dotIndex) => {
        this.dots.push(this.chart.chartLayer.selectAll('dot')
          .data([elem])
          .enter()
          .append('circle')
          .attr('class', this.chart.cssClassProvider.getClassFor('dot', elem.type))
          .attr('r', 4.5)
          .attr('cx', () => this.chart.x(data.id) + this.chart.x.bandwidth() / 2)
          .attr('cy', (d) => this._getDotHeight(d.value, data.id, dotIndex)));
      });
  }

  hide () {
    if (this.dots) {
      this.dots.forEach(d => d.remove());
    }
  }

  _getDotsData (periodId) {
    return this._findDataById(periodId).data.filter(this.chart.dataFilterCallback.bind(this.chart));
  }

  _getDotHeight (value, areaIndex, dotIndex) {
    return this.chart.y(this.chart.shape === 'line' ? value : this.chart.dataModel.getAreaHeightPerPeriod()[dotIndex][areaIndex + 1]);
  }

  _findDataById (id) {
    return this.chart.dataModel.getBlockDomain().find(elem => elem.id === id);
  }
}
