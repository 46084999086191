import React, { useState } from 'react'
import UserListItem from 'javascripts/components/UserSettings/UserListItem'
import Button, { ButtonTheme } from 'javascripts/components/Button'
import { UserListContainerProps, User } from 'javascripts/components/UserSettings/types'
import UserSettingsService from 'javascripts/services/UserSettingsService'

const UserListContainer: React.FC<UserListContainerProps> = ({ users }) => {
  const [userList] = useState(users)
  const userSettingsService = new UserSettingsService()

  const isOwner = async (user: User) => {
    const response = await userSettingsService.displayUserRole(user)
    return response.data.is_owner
  }

  return (
    <>
      <div className='user-list__container'>
        {userList.map(user => (
          <UserListItem key={user.id} user={user} isOwner={isOwner} />
        ))}
      </div>
      <footer className='user-list__footer'>
        <Button theme={ButtonTheme.GreenOutline}>Cancel</Button>
        <Button theme={ButtonTheme.Green}>Update Settings</Button>
      </footer>
    </>
  )
}

export default UserListContainer
