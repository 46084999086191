import React from 'react';

interface Props {
  className?: string
  reference?: React.RefObject<HTMLDivElement>
}

const Tile: React.FC<Props> = ({ className, children, reference }) => {

  const getClassName = (className: string | undefined | null) => className ? className : '';

  return (
    <div ref={reference} className={`tile ${getClassName(className)}`}>
      { children }
    </div>
  )
};

export default Tile;
