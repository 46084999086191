import { BaseChartTooltip } from './_base_chart_tooltip';

export class ResearchChartTooltip extends BaseChartTooltip {
  _getMessage(data) {
    if (this.chart.getShouldCompare()) {
      return this._getTable(data);
    } else {
      const records = this._getRecords(data.data);
      const rows = this._createTag('p', this.getTooltipRowsWithColors(records));
      return [this._getDateHeaderWith(data.label), rows].join('');
    }
  }

  _getAnchor(data) {
    return Math.max(...data.data.filter(this.chart.dataFilterCallback.bind(this.chart)).map(d => d.value));
  }

  _getTable(data) {
    const content = [this._getTableHeader(data), ...this._getTableBody(data.data)].join('');
    return this._createTag('table', content);
  }

  _getTableHeader(data) {
    const content = [
      this._createTag('th', ''),
      this._createTag('th', this._formatDateFrom(data.label)),
      this._createTag('th', this._formatDateFrom(data.previousLabel))
    ].join('');

    return this._createTag('tr', content);
  }

  _getTableBody(data) {
    return data
      .filter(this._filterByActiveCurrent.bind(this))
      .sort((a, b) => b.value - a.value)
      .map(elem => this._prepareRow(elem, data.find(e => e.previous && e.type === elem.type)));
  }

  _prepareRow(current, previous) {
    const content = [
      this._createTag('td', current.type, [this._getClassNameFor(current.type), 'no-wrap'].join(' ')),
      this._createTag('td', this._getPrettyValueWithUnit(current.value), 'no-wrap'),
      this._createTag('td', this._getPrettyValueWithUnit(previous.value), 'no-wrap')
    ].join('');

    return this._createTag('tr', content);
  }

  _getRecords(data) {
    return data
      .filter(this.chart.dataFilterCallback.bind(this.chart))
      .sort((a, b) => b.value - a.value);
  }

  _filterByActiveCurrent(elem) {
    return this.chart.isActive(elem.type) && !elem.previous;
  }

  _getClassNameFor(label) {
    return this.chart.cssClassProvider.getClassFor('tooltip-info', label);
  }
}
