import React from 'react'

import { errorClass, ErrorFieldBox } from 'javascripts/components/forms/ErrorFieldBox'
import Input from 'javascripts/components/forms/Input'
import { PublisherDomain } from './types'
import Button, { ButtonTheme } from '../Button'

interface Props {
  domain: PublisherDomain
  errors: {
    domainName: Array<string>
    adminCost: Array<string>
  }
  deleteDomain: Function
  updateDomain: Function
}

const PublisherDomainListItem: React.FC<Props> = ({ domain, errors = {}, deleteDomain, updateDomain }) => {
  return (
    <div className='publisher__form--domains'>
      <div className={`publisher__form-input--domain ${errorClass(errors.domainName)}`}>
        <Input
          type='text'
          id={`domain-name-${domain.uuid}`}
          name='domainName'
          value={domain.domainName}
          label='Domain Name'
          onChange={(value: string) => {
            const domainName = value ? value : undefined
            updateDomain({ ...domain, domainName })
          }}
        />
        <ErrorFieldBox errors={errors.domainName} />
      </div>

      <div className={`${errorClass(errors.adminCost)}`}>
        <div className='publisher__form-input--margin-container'>
          <Input
            type='number'
            id={`admin-cost-${domain.uuid}`}
            name='adminCost'
            value={domain.adminCost}
            label='Admin Cost'
            onChange={(value: string) => {
              const adminCost = value ? parseFloat(value) : undefined
              updateDomain({ ...domain, adminCost })
            }}
            disableScroll={true}
            inputClassName='publisher__form-input--margin'
          />
          <span className='publisher__form-input--margin-percent'>%</span>
        </div>

        <ErrorFieldBox errors={errors.adminCost} className='publisher-admin__cost--error' />
      </div>
      <Button
        theme={ButtonTheme.GreenOutline}
        className='publisher__form-button--delete'
        onClickHandler={() => deleteDomain(domain.uuid)}
      >
        Delete
      </Button>
    </div>
  )
}

export default PublisherDomainListItem
