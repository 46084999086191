interface SpinnerOptions {
  extraWide: boolean
  extraBottom: boolean
}

const spinnerOptionsDefaults: SpinnerOptions = {
  extraWide: false,
  extraBottom: false,
}

export class Spinner {
  private elements: JQuery<HTMLElement>
  private spinner: string

  constructor (elements: string, spinnerOptions: SpinnerOptions = spinnerOptionsDefaults) {
    this.elements = $(elements)
    this.spinner = `spinner ${spinnerOptions.extraWide ? 'spinner--extra-wide' : ''} ${
      spinnerOptions.extraBottom ? 'spinner--extra-bottom' : ''}`
  }

  show () {
    this.elements.css('position', 'relative').addClass(this.spinner)
  }

  hide () {
    this.elements.css('position', '').removeClass(this.spinner)
  }
}
