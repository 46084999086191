import { AdUnit, Website } from "javascripts/components/Publisher/types"
import { SelectOption } from "javascripts/components/forms/Select/types"

export const convertItemsToSelectOption = (items: AdUnit[] | Website[]): SelectOption[] =>
  items.map(item => ({ value: item.id.toString(), label: item.name }))

export const convertSelectOptionToItems = (selectedOptions: SelectOption[]): Array<AdUnit> | Array<Website> =>
  selectedOptions.map(selectedOption => ({
    id: selectedOption.value,
    name: selectedOption.label,
    adminCost: 0,
  }))

const filterAdUnitsByName = (adUnit: AdUnit, searchQuery: string) => adUnit.name.toLowerCase().includes(searchQuery.toLowerCase())

export const getAdUnits = (adUnits: Array<AdUnit>, searchQuery: string) =>
  searchQuery
    ? adUnits.filter(adUnit => filterAdUnitsByName(adUnit, searchQuery))
    : adUnits

export const bulkUpdateAdUnits = (adUnits: Array<AdUnit>, searchQuery: string, updateAdUnits: Function) => (adminCost: number) => {
  const results = adUnits.map(adUnit => {
    return filterAdUnitsByName(adUnit, searchQuery)
      ? {
        ...adUnit,
        adminCost
      }
      : adUnit
  })
  updateAdUnits(results)
}
