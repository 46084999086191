import { SspConfigurations } from '../types'

import adformConfig from './adformConfig'
import amazonConfig from './amazonConfig'
import appnexusConfig from './appnexusConfig'
import connectAdConfig from './connectAdConfig'
import criteoConfig from './criteoConfig'
import districtMConfig from './districtMConfig'
import indexExchangeConfig from './indexExchangeConfig'
import openXConfig from './openXConfig'
import pubmaticConfig from './pubmaticConfig'
import rubiconConfig from './rubiconConfig'
import smartAdServerConfig from './smartAdServerConfig'
import sovrnConfig from './sovrnConfig'

export const sspConfiguration: SspConfigurations = {
  adform: adformConfig,
  amazon: amazonConfig,
  appnexus: appnexusConfig,
  connect_ad: connectAdConfig,
  criteo: criteoConfig,
  district_m: districtMConfig,
  index_exchange: indexExchangeConfig,
  open_x: openXConfig,
  pubmatic: pubmaticConfig,
  rubicon: rubiconConfig,
  smart_ad_server: smartAdServerConfig,
  sovrn: sovrnConfig,
}
