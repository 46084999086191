import React, { useState } from 'react'
import Button, { ButtonTheme } from 'javascripts/components/Button'
import { TabViewProps, PublisherTabIndex, User } from 'javascripts/components/Publisher/types'
import { presenceValidation, emailFormatValidation } from 'javascripts/utlis/inputValidators'
import UsersForm from 'javascripts/components/Publisher/UsersForm'

const UsersContainer: React.FC<TabViewProps> = ({ onSetSelectedTab, publisher, updateUsers }) => {
  const [userEmail, setUserEmail] = useState('')
  const [errors, setErrors] = useState([])

  const addUser = () => {
    const isInputValid = validInput()
    if (isInputValid) createNewUser()
  }

  const validInput = (): boolean => {
    const err = []
    presenceValidation('email', userEmail, err)
    emailFormatValidation(userEmail, err)
    setErrors(err)

    return err.length === 0
  }

  const createNewUser = () => {
    const user = { email: userEmail, isNew: true }
    updateUsers([...publisher.users, user])
    setUserEmail('')
  }

  const removeUser = (user: User) => {
    const newUsers = publisher.users.filter(
      (u: User) => u.email !== user.email || u.isNew === false
    )
    updateUsers(newUsers)
  }

  const goToFinanceTab = () => onSetSelectedTab(PublisherTabIndex.FINANCES_TAB)

  return (
    <div className='tab-view'>
      <h3>Grant access for external users</h3>

      <UsersForm
        publisher={publisher}
        errors={errors}
        addUser={addUser}
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        onRemoveUser={removeUser}
        isNew={true}
      />

      <footer className='tab-view__footer'>
        <Button
          theme={ButtonTheme.GreenOutline}
          onClickHandler={() => onSetSelectedTab(PublisherTabIndex.INVENTORY_TAB)}
        >
          Back
        </Button>
        <Button theme={ButtonTheme.Green} onClickHandler={goToFinanceTab}>
          Next
        </Button>
      </footer>
    </div>
  )
}

export default UsersContainer
