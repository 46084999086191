import React from 'react'
import PublisherContainer from '../PublisherContainer'
import InventoryContainer from './InventoryContainer'
import UsersContainer from './UsersContainer'
import FinancesContainer from './FinancesContainer'
import { TabViewContainerProps, PublisherTabIndex } from 'javascripts/components/Publisher/types'

const TabViewContainer: React.FC<TabViewContainerProps> = ({
  selectedTab,
  onSetSelectedTab,
  publisher,
  publisherId,
  publisherCrmId,
  updatePublisherProperties,
  updateAdUnits,
  updateWebsites,
  updateUsers,
  onGetPublisher,
  updateDomains,
}) => {
  const renderTab = (selectedTab: number) => {
    switch (selectedTab) {
      case PublisherTabIndex.NEW_PUBLISHER_TAB:
        return (
          <PublisherContainer
            onSetSelectedTab={onSetSelectedTab}
            publisher={publisher}
            publisherId={publisherId}
            publisherCrmId={publisherCrmId}
            updatePublisherProperties={updatePublisherProperties}
            onGetPublisher={onGetPublisher}
            updateDomains={updateDomains}
          />
        )
      case PublisherTabIndex.INVENTORY_TAB:
        return (
          <InventoryContainer
            onSetSelectedTab={onSetSelectedTab}
            publisher={publisher}
            onGetPublisher={onGetPublisher}
          />
        )
      case PublisherTabIndex.USERS_TAB:
        return (
          <UsersContainer
            onSetSelectedTab={onSetSelectedTab}
            publisher={publisher}
            updateUsers={updateUsers}
            onGetPublisher={onGetPublisher}
          />
        )
      case PublisherTabIndex.FINANCES_TAB:
        return (
          <FinancesContainer
            onSetSelectedTab={onSetSelectedTab}
            publisher={publisher}
            updatePublisherProperties={updatePublisherProperties}
            updateAdUnits={updateAdUnits}
            updateWebsites={updateWebsites}
            onGetPublisher={onGetPublisher}
          />
        )
    }
  }

  return renderTab(selectedTab)
}

export default TabViewContainer
